import React from 'react'

const types = {
    blue: <div className="d-flex justify-content-center">
        <div className="spinner-border spinner-center text-primary text-center" role="status">
        </div>
    </div>,
    grey: <div className="spinner-border text-secondary" role="status">
    </div>,
    green: <div className="spinner-border text-success" role="status">
    </div>,
    danger:
        <div className="d-flex justify-content-center">
            <div className="spinner-border text-danger text-center" role="status">
            </div>
        </div>,
    warning: <div className="spinner-border text-warning" role="status">
    </div>,
    info: <div className="d-flex justify-content-center">
        <div className="spinner-border text-info text-center" role="status">
        </div>
    </div>,
    light: <div className="spinner-border text-light" role="status">
    </div>,
    dark: <div className="spinner-border text-dark" role="status">
    </div>,
}

export const Loader = ({color = 'info'}) => types[color]

export default React.memo(Loader)