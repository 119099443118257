export const
    CREATE_BANNER_FAILED = "CREATE_BANNER_FAILED",
    CREATE_BANNER_LOADING = "CREATE_BANNER_LOADING",
    CREATE_BANNER_SUCCESS = "CREATE_BANNER_SUCCESS",
    UPDATE_BANNER_FAILED = "UPDATE_BANNER_FAILED",
    UPDATE_BANNER_LOADING = "UPDATE_BANNER_LOADING",
    UPDATE_BANNER_SUCCESS = "UPDATE_BANNER_SUCCESS",
    DELETE_BANNER_FAILED = "DELETE_BANNER_FAILED",
    DELETE_BANNER_LOADING = "DELETE_BANNER_LOADING",
    DELETE_BANNER_SUCCESS = "DELETE_BANNER_SUCCESS",
    LIST_BANNER_FAILED = "LIST_BANNER_FAILED",
    LIST_BANNER_LOADING = "LIST_BANNER_LOADING",
    LIST_BANNER_SUCCESS = "LIST_BANNER_SUCCESS",
    SHOW_BANNER_LOADING = "SHOW_BANNER_LOADING",
    SHOW_BANNER_FAILED = "SHOW_BANNER_FAILED",
    SHOW_BANNER_SUCCESS = "SHOW_BANNER_SUCCESS"