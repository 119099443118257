import jwt_decode from "jwt-decode";
import dayjs from "dayjs";
import SsoService from "../services/SsoService";

function IsAuthenticated() {


    if (!SsoService.isLoggedIn()) {
        return false
    }
    const isTokenExpired = (expiredAt) => dayjs.unix(expiredAt).diff(dayjs()) < 1
    const accessToken = SsoService.getToken()

    if (accessToken) {
        let user = jwt_decode(accessToken)

        if (isTokenExpired(user?.exp)) {
            SsoService.updateToken(function (refreshed) {
                if (refreshed) {
                    console.log('IsAuthenticated Token was successfully refreshed', refreshed);
                } else {
                    console.log('IsAuthenticated Token is still valid');
                }
            }).then(IsAuthenticatedTokenResponse => console.log({IsAuthenticatedTokenResponse}))
        }

        return accessToken

    } else {
        return false
    }
}

export default IsAuthenticated;