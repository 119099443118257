export function handlePayloadErrors(e) {

    let message = e.response && e.response?.data?.data ?
        e.response.data.data
        : e.response && e.response?.data
            ? e.response?.data
            : e.response === undefined ? "@@INTERNET@" : "@@ERR@"

    if (message && typeof message === 'string' && message?.startsWith("timeout")) {
        return "@@INTERNET@"
    }

    if (message && typeof message === 'string' && message?.startsWith("<!")) {
        return "@@UNEXPECTED@"
    }

    return message
}
