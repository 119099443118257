import {
    LIST_NOTIFICATION_FAILED,
    LIST_NOTIFICATION_LOADING, LIST_NOTIFICATION_NOTIFIED,
    LIST_NOTIFICATION_SUCCESS, LIST_NOTIFICATIONS_FAILED, LIST_NOTIFICATIONS_LOADING, LIST_NOTIFICATIONS_SUCCESS
} from "../Constants/Notification";


export const getNotificationsReducer = (state = {}, action) => {
    switch (action.type) {
        case LIST_NOTIFICATIONS_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload,
            }
        case LIST_NOTIFICATIONS_LOADING :
            return {
                loading: true,
                errors: null,
                data: state.data,
            }
        case LIST_NOTIFICATIONS_FAILED :
            return {
                loading: false,
                data: state.data,
                errors: action.payload,
            }
        default :
            return state

    }
}


export const listNotificationReducer = (state = {data: {count: 0, data: []}}, action) => {
    switch (action.type) {
        case LIST_NOTIFICATION_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload,
                toast: action.toast
            }
        case LIST_NOTIFICATION_LOADING :
            return {
                loading: true,
                errors: null,
                data: state.data,
                toast: false
            }
        case LIST_NOTIFICATION_FAILED :
            return {
                loading: false,
                data: state.data,
                errors: action.payload,
                toast: false
            }

        case LIST_NOTIFICATION_NOTIFIED:
            return {
                ...state,
                toast: false
            }
        default :
            return state

    }
}






