import {
    PSPS_LOADING,
    PSPS_SUCCESS,
    PSPS_FAILED,
    CREATE_PSP_SUCCESS,
    CREATE_PSP_LOADING,
    CREATE_PSP_FAILED,
    UPDATE_PSP_SUCCESS,
    UPDATE_PSP_LOADING,
    UPDATE_PSP_FAILED, SHOW_PSP_SUCCESS, SHOW_PSP_FAILED, SHOW_PSP_LOADING
} from "../Constants/Psp";


const switcher = (type, action, state) => {
    switch (action.type) {
        case type[0] :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case type[1] :
            return {
                loading: true,
                errors: null,
                data: []
            }
        case type[2] :
            return {
                loading: false,
                data: [],
                errors: action?.payload
            }

        default :
            return state

    }
}
export const pspsReducer = (state = {data: []}, action) => {

    return switcher([
        PSPS_SUCCESS,
        PSPS_LOADING,
        PSPS_FAILED,
    ], action, state)
}

export const createPspReducer = (state = {data: null}, action) => {

    return switcher([
        CREATE_PSP_SUCCESS,
        CREATE_PSP_LOADING,
        CREATE_PSP_FAILED,
    ], action, state)
}
export const editPspReducer = (state = {data: null}, action) => {

    return switcher([
        UPDATE_PSP_SUCCESS,
        UPDATE_PSP_LOADING,
        UPDATE_PSP_FAILED,
    ], action, state)
}

export const showPspReducer = (state = {data: null}, action) => {

    return switcher([
        SHOW_PSP_SUCCESS,
        SHOW_PSP_LOADING,
        SHOW_PSP_FAILED,
    ], action, state)
}
