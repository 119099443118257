
import {LanguageHandler} from "../actions/AppActions";


const LanguageMiddleware = store => next =>  action => {
    if (action.type === 'DEFAULT' ) {
        store.dispatch(LanguageHandler())
    }
    return next(action);

}

export default LanguageMiddleware