import {
    USER_DETAILS_FAILED,
    USER_DETAILS_LOADING, USER_DETAILS_SUCCESS,
    USER_LIBERIAN_FAILED,
    USER_LIBERIAN_LOADING, USER_LIBERIAN_SUCCESS,
    USER_LOGIN_FAILED,
    USER_LOGIN_LOADING,
    USER_LOGIN_SUCCESS, USER_PROFILE_FAILED, USER_PROFILE_LOADING, USER_PROFILE_SUCCESS,
    USER_REFRESH,
    USERS_FAILED, USERS_LOADING, USERS_SUCCESS
} from "../Constants/User";


export const userLoginReducer = (state = {accessToken: null, refreshToken: null}, action) => {
    switch (action.type) {
        case USER_LOGIN_LOADING :
            return {
                loading: true,
                errors: null,
                data: state
            }
        case USER_LOGIN_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case USER_LOGIN_FAILED :
            return {
                loading: false,
                data: state,
                errors: action.payload
            }

        case USER_REFRESH :
            return {
                loading: false,
                data: {accessToken: null, refreshToken: null},
                errors: "" //"Unauthorized Session Expired."
            }

        case "FLUSH_USER" :
            return {
                loading: false,
                data: {accessToken: null, refreshToken: null},
                errors: null
            }

        default :
            return state

    }
}


export const liberianUsersReducer = (state = {}, action) => {

    switch (action.type) {
        case USER_LIBERIAN_LOADING :
            return {
                loading: true,
                errors: null,
                data: state
            }
        case USER_LIBERIAN_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case USER_LIBERIAN_FAILED :
            return {
                loading: false,
                data: state,
                errors: action.payload
            }

        default :
            return state

    }
}

export const usersReducer = (state = {}, action) => {

    switch (action.type) {
        case USERS_LOADING :
            return {
                loading: true,
                errors: null,
                data: state
            }
        case USERS_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case USERS_FAILED :
            return {
                loading: false,
                data: state,
                errors: action.payload
            }

        default :
            return state

    }
}



export const userProfileReducer = (state = {}, action) => {

    switch (action.type) {
        case USER_PROFILE_LOADING :
            return {
                loading: true,
                errors: null,
                data: state
            }
        case USER_PROFILE_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case USER_PROFILE_FAILED :
            return {
                loading: false,
                data: state,
                errors: action.payload
            }

        default :
            return state

    }
}


export const userDetailsReducer = (state = {}, action) => {

    switch (action.type) {
        case USER_DETAILS_LOADING :
            return {
                loading: true,
                errors: null,
                data: state
            }
        case USER_DETAILS_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case USER_DETAILS_FAILED :
            return {
                loading: false,
                data: state,
                errors: action.payload
            }

        default :
            return state

    }
}




