import {CREATE_EVENT_FAILED, CREATE_EVENT_LOADING, CREATE_EVENT_SUCCESS} from "../Constants/Event";
import {UPDATE_EVENT_FAILED, UPDATE_EVENT_LOADING, UPDATE_EVENT_SUCCESS} from "../Constants/Event";
import {DELETE_EVENT_FAILED, DELETE_EVENT_LOADING, DELETE_EVENT_SUCCESS} from "../Constants/Event";
import {LIST_EVENT_FAILED, LIST_EVENT_LOADING, LIST_EVENT_SUCCESS} from "../Constants/Event";
import {SHOW_EVENT_FAILED, SHOW_EVENT_LOADING, SHOW_EVENT_SUCCESS} from "../Constants/Event";
import {GLOBAL_REFRESH} from "../Constants/Refresh";
import {refresh} from "./index";


export const createEventReducer = (state = {}, action) => {
    switch (action.type) {
        case CREATE_EVENT_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case CREATE_EVENT_LOADING :
            return {
                loading: true,
                errors: null,
                data: null
            }
        case CREATE_EVENT_FAILED :
            return {
                loading: false,
                data: null,
                errors: action.payload
            }
        case GLOBAL_REFRESH :
            return refresh()
        default :
            return state

    }
}

export const updateEventReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_EVENT_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case UPDATE_EVENT_LOADING :
            return {
                loading: true,
                errors: null,
                data: action.payload
            }
        case UPDATE_EVENT_FAILED :
            return {
                loading: false,
                data: state,
                errors: action.payload
            }
        case GLOBAL_REFRESH :
            return refresh()

        default :
            return state

    }
}


export const showEventReducer = (state = {}, action) => {
    switch (action.type) {
        case SHOW_EVENT_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case SHOW_EVENT_LOADING :
            return {
                loading: true,
                errors: null,
                data: null
            }
        case SHOW_EVENT_FAILED :
            return {
                loading: false,
                data: null,
                errors: action.payload
            }

        default :
            return state

    }
}


export const deleteEventReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_EVENT_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case DELETE_EVENT_LOADING :
            return {
                loading: true,
                errors: null,
                data: action.payload
            }
        case DELETE_EVENT_FAILED :
            return {
                loading: false,
                data: state,
                errors: action.payload
            }

        default :
            return state

    }
}

export const listEventReducer = (state = {}, action) => {
    switch (action.type) {
        case LIST_EVENT_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case LIST_EVENT_LOADING :
            return {
                loading: true,
                errors: null,
                data: []
            }
        case LIST_EVENT_FAILED :
            return {
                loading: false,
                data: [],
                errors: action.payload
            }

        default :
            return state

    }
}






