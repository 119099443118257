import axios from 'axios';
import env from '#env'
import api from "./AxiosInstance";


/**
 * `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`, login
 * `https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`, register
 * @param email
 * @param password
 */
export function signUp(email, password) {
    //axios call
    const postData = {
        email,
        password,
    };
    return axios.post(
        env.BACKEND_URL + `/api/v1/register`,
        postData,
    );
}

export function login({email, id, accessToken}) {
    const postData = {
        email,
        id,
        accessToken
    };

    return axios.post(
        env.BACKEND_URL + `/api/v1/portal/login`,
        postData,
        {
            headers: {
                'Accept': 'application/json', 'Content-Type': 'application/json',
            }
        }
    );
}



export function update(data) {
    return api.post(`/api/v1/user/update`, data);
}

export function getUserDetails() {
    return api.get(`/api/v1/user/details/auth`);
}

