import {CREATE_CATEGORY_FAILED, CREATE_CATEGORY_LOADING, CREATE_CATEGORY_SUCCESS} from "../Constants/Category";
import {UPDATE_CATEGORY_FAILED, UPDATE_CATEGORY_LOADING, UPDATE_CATEGORY_SUCCESS} from "../Constants/Category";
import {DELETE_CATEGORY_FAILED, DELETE_CATEGORY_LOADING, DELETE_CATEGORY_SUCCESS} from "../Constants/Category";
import {LIST_CATEGORY_FAILED, LIST_CATEGORY_LOADING, LIST_CATEGORY_SUCCESS} from "../Constants/Category";
import {SHOW_CATEGORY_FAILED, SHOW_CATEGORY_LOADING, SHOW_CATEGORY_SUCCESS} from "../Constants/Category";
import {GLOBAL_REFRESH} from "../Constants/Refresh";
import {refresh} from "./index";


export const createCategoryReducer = (state = {}, action) => {
    switch (action.type) {
        case CREATE_CATEGORY_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case CREATE_CATEGORY_LOADING :
            return {
                loading: true,
                errors: null,
                data: null
            }
        case CREATE_CATEGORY_FAILED :
            return {
                loading: false,
                data: null,
                errors: action.payload
            }
        case GLOBAL_REFRESH :
            return refresh()
        default :
            return state

    }
}

export const updateCategoryReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_CATEGORY_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case UPDATE_CATEGORY_LOADING :
            return {
                loading: true,
                errors: null,
                data: action.payload
            }
        case UPDATE_CATEGORY_FAILED :
            return {
                loading: false,
                data: state,
                errors: action.payload
            }
        case GLOBAL_REFRESH :
            return refresh()

        default :
            return state

    }
}


export const showCategoryReducer = (state = {}, action) => {
    switch (action.type) {
        case SHOW_CATEGORY_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case SHOW_CATEGORY_LOADING :
            return {
                loading: true,
                errors: null,
                data: null
            }
        case SHOW_CATEGORY_FAILED :
            return {
                loading: false,
                data: null,
                errors: action.payload
            }

        default :
            return state

    }
}


export const deleteCategoryReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_CATEGORY_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case DELETE_CATEGORY_LOADING :
            return {
                loading: true,
                errors: null,
                data: action.payload
            }
        case DELETE_CATEGORY_FAILED :
            return {
                loading: false,
                data: state,
                errors: action.payload
            }

        default :
            return state

    }
}

export const listCategoryReducer = (state = {}, action) => {
    switch (action.type) {
        case LIST_CATEGORY_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case LIST_CATEGORY_LOADING :
            return {
                loading: true,
                errors: null,
                data: []
            }
        case LIST_CATEGORY_FAILED :
            return {
                loading: false,
                data: [],
                errors: action.payload
            }

        default :
            return state

    }
}






