import {can, isAdmin} from "../../../Role";
import {Link} from "react-router-dom";
import React, {useState} from "react";
import {dashboardMenu, subMenuOfSystemManagement, systemManagement} from "./MapNav";
import {capitalizeFirstLetter, tCreate, tList, getPathLocation} from "../../../Helper/functions";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import United from "../../../images/United.png";
import Arabic from "../../../images/ar.svg";
import {Switcher} from "#language";
import orders from './../../../icons/orders.png'
import funds from './../../../icons/funds.png'
import access from './../../../icons/access.png'
import library from './../../../icons/library.png'
import request from './../../../icons/request.png'
import systemImage from './../../../icons/system.png'
import dashboardImage from './../../../icons/dashboard.png'
import libSubscribeImage from './../../../icons/library_subscribe.png'
import paymentImage from './../../../icons/payment.png'
import feeImage from './../../../icons/fee.png'
import customerSupportImage from './../../../icons/support.png'
import payouts from './../../../icons/payouts.png'
import {
    dashboard,
    courses,
    user_requests,
    instructor,
    accessManagement,
    app,
    email,
    shop,
    charts,
    bootstrap,
    plugins,
    redux,
    widget,
    forms,
    table,
    pages,
    error,
    orderManagement,
    fundManagement,
    management, librarySubscribedManagement, pspManagement, feeManagement, payoutsManagement
} from './NavSectionWrapper'


const system = (t, i18n, path) => Object.keys(systemManagement).map((section, i) => {

    return can(section) && <li key={section + i + "systemManagement"}
                               className={
                                   subMenuOfSystemManagement[section].includes(path)
                                       ? "mm-active"
                                       : ""
                               }>
        <Link className="has-arrow" to="#">
                                                <span>
                                                       {capitalizeFirstLetter(t(section.toLowerCase()))}
                                                </span>
        </Link>
        <ul>
            {systemManagement[section].map((side, id) => {

                if ((side.name === 'log' && !isAdmin())) return null


                return can(section) && side.name !== 'edit' && side.name !== 'trans' &&
                    <li key={`${side.path}__${section}_${side.name}_${id}_${i}`}
                        className={`${
                            (path === side.path.replace("/", "")) ? "mm-active" : ""}`}
                    >
                        <Link to={side.path}>
                            {
                                side.name === 'create'
                                    ? tCreate({t, i18n}, section)
                                    : side.name === 'list'
                                        ? tList({t, i18n}, side.plural)
                                        : t(side.name)
                            }
                        </Link>
                    </li>
            })}
        </ul>
    </li>
})


const Dashboard = (path, t) => dashboardMenu.map((item, i) =>
    can(item.path) ? <li key={"dashboardMenu" + i + item.path + i}>
        <Link
            className={`${path === item.path.replace("/", "") ? "mm-active" : ""}`}
            to={item.path}> {t(item.name)}
        </Link>
    </li> : null
)

const AppNavigator = () => {
    const {t, i18n} = useTranslation()
    const path = getPathLocation()
    const lang = localStorage.getItem("lang") ? localStorage.getItem("lang") : localStorage.setItem("lang", "en")
    const [rightSelect, setRightSelect] = useState(lang);
    let nav = [
        {
            data: can('dashboard') &&
                <li key={"sec_nav_item_1"} className={`${dashboard.includes(path) ? "mm-active" : ""}`}>
                    <Link className="has-arrow" to="#">
                        <img width="23" height="23" className={" me-3"} src={dashboardImage} alt=""/>
                        <span className="nav-text" style={{fontSize: 12}}>{t('dashboard_admin')}</span>
                    </Link>
                    <ul>
                        {
                            Dashboard(path, t)
                        }

                    </ul>
                </li>,
            name: t('dashboard_admin')

        },


        {
            data: can('management') &&
                <li key={"sec_nav_item_2"} className={`${management.includes(path) ? "mm-active" : ""}`}>
                    <Link className="has-arrow" to="#">
                        <img width="23" height="23" className={" me-3"} src={systemImage} alt=""/>
                        <span className="nav-text " style={{fontSize: 12}}>{t('system_management')}</span>
                    </Link>
                    <ul>
                        {
                            system(t, i18n, path)
                        }
                    </ul>
                </li>,
            name: t('system_management')
        },
        {
            data: can('subscribed_library') &&
                <li key={"sec_nav_item_324"}
                    className={`${librarySubscribedManagement.includes(path) ? "mm-active" : ""}`}>
                    <Link className="has-arrow" to="#">
                        <img width="23" height="23" className={" me-3"} src={libSubscribeImage} alt=""/>
                        <span className="nav-text " style={{fontSize: 12}}>{t('subscribed_library_management')}</span>
                    </Link>
                    <ul>
                        <li><Link className={`${path === "subscribed_libraries-list" ? "mm-active" : ""}`}
                                  to="/subscribed_libraries-list">{tList({t, i18n}, 'my_subscribed_libraries')}</Link>
                        </li>
                    </ul>
                </li>,
            name: t('subscribed_library_management')
        },
        {
            data: can('user_requests') &&
                <li key={"sec_nav_item_3"} className={`${user_requests.includes(path) ? "mm-active" : ""}`}>
                    <Link className="has-arrow" to="#">
                        <img width="23" height="23" className={" me-3"} src={request} alt=""/>

                        <span className="nav-text" style={{fontSize: 12}}>{t("user_requests")}</span>
                    </Link>
                    <ul>
                        <li><Link className={`${path === "user-requests" ? "mm-active" : ""}`}
                                  to="/user_requests-list">{tList({t, i18n}, 'user_requests')}</Link></li>
                    </ul>
                </li>,
            name: t('user_requests')
        },

        {
            data: can("instructor") &&
                <li key={"sec_nav_item_5"} className={`${instructor.includes(path) ? "mm-active" : ""}`}>
                    <Link className="has-arrow" to="#">
                        <img width="23" height="23" className={" me-3"} src={library} alt=""/>
                        <span
                            className="nav-text" style={{fontSize: 12}}>{t('library_management')}</span></Link>
                    <ul>

                        <li><Link className={`${path === "library-profile" ? "mm-active" : ""}`}
                                  to="/library-profile">{t("my_library")}</Link></li>

                        <li><Link className={`${path === "library-transactions" ? "mm-active" : ""}`}
                                  to="/library-transactions">{t("transactions")}</Link></li>

                        <li><Link className={`${path === "library-schedule" ? "mm-active" : ""}`}
                                  to="/library-schedule">{t("schedule")}</Link></li>

                        {/*<li><Link className={`${path === "instructor-courses" ? "mm-active" : ""}`}*/}
                        {/*          to="/library-courses">{t("courses")}</Link></li>*/}
                        {/*<li><Link className={`${path === "instructor-students" ? "mm-active" : ""}`}*/}
                        {/*          to="/instructor-students">Students</Link></li>*/}
                        {/*<li><Link className={`${path === "instructor-resources" ? "mm-active" : ""}`}*/}
                        {/*          to="/library-resources">{t("resources")}</Link></li>*/}
                        {/*<li><Link className={`${path === "instructor-liveclass" ? "mm-active" : ""}`}*/}
                        {/*          to="/instructor-liveclass">Live Class</Link></li>*/}
                    </ul>
                </li>,
            name: t('instructor')
        },

        {
            data: can("access_management") &&
                <li key={"access_management_item_5"}
                    className={`${accessManagement.includes(path) ? "mm-active" : ""}`}>
                    <Link className="has-arrow" to="#">
                        <img width="23" height="23" className={" me-3"} src={access} alt=""/>
                        <span
                            className="nav-text" style={{fontSize: 12}}>{t('access_management')}</span></Link>
                    <ul>

                        <li><Link className={`${path === "blocks-list" ? "mm-active" : ""}`}
                                  to="/blocks-list">{tList({t, i18n}, 'blocks')}</Link></li>
                    </ul>
                </li>,
            name: t('access_management')
        },

        {
            data: can("orders_management") &&
                <li key={"order_management_item_5"} className={`${orderManagement.includes(path) ? "mm-active" : ""}`}>
                    <Link className="has-arrow" to="#">
                        <img width="23" height="23" className={" me-3"} src={orders} alt=""/>

                        <span
                            className="nav-text" style={{fontSize: 12}}>{t('orders_management')}</span></Link>
                    <ul>


                        <li><Link className={`${path === "orders-list" ? "mm-active" : ""}`}
                                  to="/orders-list">{isAdmin() ? tList({t, i18n}, 'orders') : t("my_orders")}</Link>
                        </li>

                        {can("generate-payment-link")
                            ? <li><Link className={`${path === "generate-payment-link" ? "mm-active" : ""}`}
                                        to="/generate-payment-link">{t("generate_payment_link")}</Link>
                            </li>
                            : null
                        }

                    </ul>
                </li>,
            name: t('orders_management')
        },
        {
            data: can("funds_management") &&
                <li key={"funds_management_item_5"} className={`${fundManagement.includes(path) ? "mm-active" : ""}`}>
                    <Link className="has-arrow" to="#">
                        <img width="23" height="23" className={" me-3"} src={funds} alt=""/>
                        <span
                            className="nav-text" style={{fontSize: 12}}>{t('funds_management')}</span></Link>
                    <ul>

                        <li><Link className={`${path === "temporaryfunds-list" ? "mm-active" : ""}`}
                                  to="/temporaryfunds-list" style={{fontSize: 12}}>{tList({
                            t,
                            i18n
                        }, 'temporaryFunds')}</Link></li>

                        <li><Link className={`${path === "transactions-list" ? "mm-active" : ""}`}
                                  to="/transactions-list">{tList({t, i18n}, 'transactions')}</Link></li>
                    </ul>
                </li>,
            name: t('funds_management')
        },
        {
            data: can("payouts_management") &&
                <li key={"payouts_management_item_5"} className={`${payoutsManagement.includes(path) ? "mm-active" : ""}`}>
                    <Link className="has-arrow" to="#">
                        <img width="23" height="23" className={" me-3"} src={payouts} alt=""/>
                        <span
                            className="nav-text" style={{fontSize: 12}}>{t('payouts_management')}</span></Link>
                    <ul>

                        <li><Link className={`${path === "payouts-list" ? "mm-active" : ""}`}
                                  to="/payouts-list" style={{fontSize: 12}}>{tList({
                            t,
                            i18n
                        }, 'payouts')}</Link></li>

                    </ul>
                </li>,
            name: t('payouts_management')
        },
        {
            data: can('psp_management') &&
                <li key={"sec_nav_item_psp"} className={`${pspManagement.includes(path) ? "mm-active" : ""}`}>
                    <Link className="has-arrow" to="#">
                        <img width="23" height="23" className={" me-3"} src={paymentImage} alt=""/>

                        <span className="nav-text" style={{fontSize: 12}}>{t("psp_management")}</span>
                    </Link>

                    <ul>
                        <li><Link className={`${path === "psps-list" ? "mm-active" : ""}`}
                                  to="/psps-list">{tList({t, i18n}, 'psp')}</Link></li>

                        <li><Link className={`${path === "create-psp" ? "mm-active" : ""}`}
                                  to="/create-psp">{tCreate({t, i18n}, 'psp')}</Link></li>
                    </ul>
                </li>,
            name: t('psp_management')
        },
        {
            data: can('fee_management') &&
                <li key={"sec_nav_item_fee"} className={`${feeManagement.includes(path) ? "mm-active" : ""}`}>
                    <Link className="has-arrow" to="#">
                        <img width="23" height="23" className={" me-3"} src={feeImage} alt=""/>

                        <span className="nav-text" style={{fontSize: 12}}>{t("fee_management")}</span>
                    </Link>

                    <ul>
                        <li><Link className={`${path === "fees-list" ? "mm-active" : ""}`}
                                  to="/fees-list">{tList({t, i18n}, 'fee')}</Link></li>

                        <li><Link className={`${path === "create-fee" ? "mm-active" : ""}`}
                                  to="/create-fee">{tCreate({t, i18n}, 'fee')}</Link></li>
                    </ul>
                </li>,
            name: t('fee_management')
        },
        {
            data: can('customer_support') &&
                <li key={"sec_nav_item_cs"} className={`${['customer-support'].includes(path) ? "mm-active" : ""}`}>
                    <Link to="/customer_support">
                        <img width="23" height="23" className={" me-3"} src={customerSupportImage} alt=""/>

                        <span className="nav-text" style={{fontSize: 12}}>{t("customer_support")}</span>
                    </Link>
                </li>,
            name: t('customer_support')
        },

        {
            data: can('courses') &&
                <li key={"sec_nav_item_4"} className={`${courses.includes(path) ? "mm-active" : ""}`}>
                    <Link className="has-arrow" to="#">
                        <i className="bi bi-book"></i>
                        <span className="nav-text">{t('courses')}</span>
                    </Link>
                    <ul>
                        <li><Link className={`${path === "courses" ? "mm-active" : ""}`}
                                  to="/courses">Courses</Link></li>
                        <li><Link className={`${path === "course-details-1" ? "mm-active" : ""}`}
                                  to="/course-details-1">Course Details 1</Link></li>
                        <li><Link className={`${path === "course-details-2" ? "mm-active" : ""}`}
                                  to="/course-details-2">Course Details 2</Link></li>
                    </ul>
                </li>,
            name: t('courses')
        },
        {
            data: can("app") && <li key={"sec_nav_item_6"} className={`${app.includes(path) ? "mm-active" : ""}`}>
                <Link className="has-arrow ai-icon" to="#">
                    <i className="bi bi-info-circle"></i>
                    <span className="nav-text">{t('apps')}</span>
                </Link>
                <ul>
                    <li><Link className={`${path === "app-profile" ? "mm-active" : ""}`}
                              to="/app-profile">Profile</Link></li>
                    <li><Link className={`${path === "post-details" ? "mm-active" : ""}`} to="/post-details">Post
                        Details</Link></li>
                    <li className={`${email.includes(path) ? "mm-active" : ""}`}><Link className="has-arrow"
                                                                                       to="#">Email</Link>
                        <ul className={`${email.includes(path) ? "mm-show" : ""}`}>
                            <li><Link className={`${path === "email-compose" ? "mm-active" : ""}`}
                                      to="/email-compose">Compose</Link></li>
                            <li><Link className={`${path === "email-inbox" ? "mm-active" : ""}`}
                                      to="/email-inbox">Inbox</Link></li>
                            <li><Link className={`${path === "email-read" ? "mm-active" : ""}`}
                                      to="/email-read">Read</Link></li>
                        </ul>
                    </li>
                    <li><Link className={`${path === "app-calender" ? "mm-active" : ""}`}
                              to="/app-calender">Calendar</Link></li>
                    <li className={`${shop.includes(path) ? "mm-active" : ""}`}><Link className="has-arrow"
                                                                                      to="#">Shop</Link>
                        <ul className={`${shop.includes(path) ? "mm-show" : ""}`}>
                            <li><Link className={`${path === "ecom-product-grid" ? "mm-active" : ""}`}
                                      to="/ecom-product-grid">Product Grid</Link></li>
                            <li><Link className={`${path === "ecom-product-list" ? "mm-active" : ""}`}
                                      to="/ecom-product-list">Product List</Link></li>
                            <li><Link className={`${path === "ecom-product-detail" ? "mm-active" : ""}`}
                                      to="/ecom-product-detail">Product Details</Link></li>
                            <li><Link className={`${path === "ecom-product-order" ? "mm-active" : ""}`}
                                      to="/ecom-product-order">Order</Link></li>
                            <li><Link className={`${path === "ecom-checkout" ? "mm-active" : ""}`}
                                      to="/ecom-checkout">Checkout</Link></li>
                            <li><Link className={`${path === "ecom-invoice" ? "mm-active" : ""}`}
                                      to="/ecom-invoice">Invoice</Link></li>
                            <li><Link className={`${path === "ecom-customers" ? "mm-active" : ""}`}
                                      to="/ecom-customers">Customers</Link></li>
                        </ul>
                    </li>
                </ul>
            </li>,
            name: t('apps')
        },
        {
            data: can("charts") &&
                <li key={"sec_nav_item_7"} className={`${charts.includes(path) ? "mm-active" : ""}`}>
                    <Link className="has-arrow ai-icon" to="#">
                        <i className="bi bi-pie-chart"></i>
                        <span className="nav-text">{t('charts')}</span>
                    </Link>
                    <ul>
                        <li><Link className={`${path === "chart-rechart" ? "mm-active" : ""}`}
                                  to="/chart-rechart">RechartJs</Link></li>
                        <li><Link className={`${path === "chart-chartjs" ? "mm-active" : ""}`}
                                  to="/chart-chartjs">Chartjs</Link></li>
                        <li><Link className={`${path === "chart-chartist" ? "mm-active" : ""}`}
                                  to="/chart-chartist">Chartist</Link></li>
                        <li><Link className={`${path === "chart-sparkline" ? "mm-active" : ""}`}
                                  to="/chart-sparkline">Sparkline</Link></li>
                        <li><Link className={`${path === "chart-apexchart" ? "mm-active" : ""}`}
                                  to="/chart-apexchart">Apexchart</Link></li>
                    </ul>
                </li>,
            name: t('charts')
        },
        {
            data: can("bootstrap") &&
                <li key={"sec_nav_item_8"} className={`${bootstrap.includes(path) ? "mm-active" : ""}`}>
                    <Link className="has-arrow ai-icon" to="#">
                        <i className="bi bi-star"></i>
                        <span className="nav-text">{t("bootstrap")}</span>
                    </Link>
                    <ul>
                        <li><Link className={`${path === "ui-accordion" ? "mm-active" : ""}`}
                                  to="/ui-accordion">Accordion</Link></li>
                        <li><Link className={`${path === "ui-alert" ? "mm-active" : ""}`}
                                  to="/ui-alert"> Alert</Link></li>
                        <li><Link className={`${path === "ui-badge" ? "mm-active" : ""}`}
                                  to="/ui-badge">Badge</Link></li>
                        <li><Link className={`${path === "ui-button" ? "mm-active" : ""}`}
                                  to="/ui-button">Button</Link></li>
                        <li><Link className={`${path === "ui-modal" ? "mm-active" : ""}`}
                                  to="/ui-modal">Modal</Link></li>
                        <li><Link className={`${path === "ui-button-group" ? "mm-active" : ""}`}
                                  to="/ui-button-group">Button Group</Link></li>
                        <li><Link className={`${path === "ui-list-group" ? "mm-active" : ""}`} to="/ui-list-group">List
                            Group</Link></li>
                        <li><Link className={`${path === "ui-card" ? "mm-active" : ""}`} to="/ui-card">Cards</Link>
                        </li>
                        <li><Link className={`${path === "ui-carousel" ? "mm-active" : ""}`}
                                  to="/ui-carousel">Carousel</Link></li>
                        <li><Link className={`${path === "ui-dropdown" ? "mm-active" : ""}`}
                                  to="/ui-dropdown">Dropdown</Link></li>
                        <li><Link className={`${path === "ui-popover" ? "mm-active" : ""}`}
                                  to="/ui-popover">Popover</Link></li>
                        <li><Link className={`${path === "ui-progressbar" ? "mm-active" : ""}`}
                                  to="/ui-progressbar">Progressbar</Link></li>
                        <li><Link className={`${path === "ui-tab" ? "mm-active" : ""}`} to="/ui-tab">Tab</Link></li>
                        <li><Link className={`${path === "ui-typography" ? "mm-active" : ""}`}
                                  to="/ui-typography">Typography</Link></li>
                        <li><Link className={`${path === "ui-pagination" ? "mm-active" : ""}`}
                                  to="/ui-pagination">Pagination</Link></li>
                        <li><Link className={`${path === "ui-grid" ? "mm-active" : ""}`} to="/ui-grid">Grid</Link>
                        </li>
                    </ul>
                </li>,
            name: t('bootstrap')
        },
        {
            data: can("plugins") &&
                <li key={"sec_nav_item_9"} className={`${plugins.includes(path) ? "mm-active" : ""}`}>
                    <Link className="has-arrow ai-icon" to="#">
                        <i className="bi bi-heart"></i><span className="nav-text">{t('plugins')}</span>
                    </Link>
                    <ul>
                        <li><Link className={`${path === "uc-select2" ? "mm-active" : ""}`} to="/uc-select2">Select
                            2</Link></li>
                        <li><Link className={`${path === "uc-noui-slider" ? "mm-active" : ""}`}
                                  to="/uc-noui-slider">Noui Slider</Link></li>
                        <li><Link className={`${path === "uc-sweetalert" ? "mm-active" : ""}`} to="/uc-sweetalert">Sweet
                            Alert</Link></li>
                        <li><Link className={`${path === "uc-toastr" ? "mm-active" : ""}`}
                                  to="/uc-toastr">Toastr</Link></li>
                        <li><Link className={`${path === "map-jqvmap" ? "mm-active" : ""}`} to="/map-jqvmap">Jqv
                            Map</Link></li>
                        <li><Link className={`${path === "uc-lightgallery" ? "mm-active" : ""}`}
                                  to="/uc-lightgallery">Light Gallery</Link></li>
                    </ul>
                </li>,
            name: t('plugins')
        },
        {
            data: can("redux") && <li key={"sec_nav_item_0"} className={`${redux.includes(path) ? "mm-active" : ""}`}>
                <Link className="has-arrow ai-icon" to="#">
                    <i className="bi bi-archive"></i><span className="nav-text">{t('redux')}</span>
                </Link>
                <ul>
                    <li><Link className={`${path === "todo" ? "mm-active" : ""}`} to="/todo">Todo</Link></li>
                </ul>
            </li>,
            name: t('redux')
        },
        {
            data: can("widget") &&
                <li key={"sec_nav_item_10"} className={`${widget.includes(path) ? "mm-active" : ""}`}>
                    <Link to="widget-basic" className="ai-icon">
                        <i className="bi bi-gear-wide"></i>
                        <span className="nav-text">{t('widget')}</span>
                    </Link>
                </li>,
            name: t('widget')
        },
        {
            data: can("forms") && <li key={"sec_nav_item_11"} className={`${forms.includes(path) ? "mm-active" : ""}`}>
                <Link className="has-arrow ai-icon" to="#">
                    <i className="bi bi-file-earmark-check"></i>
                    <span className="nav-text forms">{t('forms')}</span>
                </Link>
                <ul>
                    <li><Link className={`${path === "form-element" ? "mm-active" : ""}`} to="/form-element">Form
                        Elements</Link></li>
                    <li><Link className={`${path === "form-wizard" ? "mm-active" : ""}`}
                              to="/form-wizard"> Wizard</Link></li>
                    <li>
                        <Link className={`${path === "form-editor-summernote" ? "mm-active" : ""}`}
                              to="/form-editor-summernote">
                            Summernote
                        </Link>
                    </li>
                    <li><Link className={`${path === "form-pickers" ? "mm-active" : ""}`}
                              to="/form-pickers">Pickers</Link></li>
                    <li>
                        <Link className={`${path === "form-validation-jquery" ? "mm-active" : ""}`}
                              to="/form-validation-jquery">
                            Form Validate
                        </Link>
                    </li>
                </ul>
            </li>,
            name: t('forms')
        },
        {
            data: can("table") && <li key={"sec_nav_item_12"} className={`${table.includes(path) ? "mm-active" : ""}`}>
                <Link className="has-arrow ai-icon" to="#"><i
                    className="bi bi-file-earmark-spreadsheet"></i><span
                    className="nav-text">{t('table')}</span></Link>
                <ul>
                    <li>
                        <Link className={`${path === "table-filtering" ? "mm-active" : ""}`}
                              to="/table-filtering">
                            Table Filtering
                        </Link>
                    </li>
                    <li>
                        <Link className={`${path === "table-sorting" ? "mm-active" : ""}`} to="/table-sorting">
                            Table Sorting
                        </Link>
                    </li>
                    <li>
                        <Link className={`${path === "table-bootstrap-basic" ? "mm-active" : ""}`}
                              to="/table-bootstrap-basic">
                            Bootstrap
                        </Link>
                    </li>
                    <li>
                        <Link className={`${path === "table-datatable-basic" ? "mm-active" : ""}`}
                              to="/table-datatable-basic">
                            Datatable
                        </Link>
                    </li>
                </ul>
            </li>,
            name: t("table")
        },
        {
            data: can("pages") && <li key={"sec_nav_item_13"} className={`${pages.includes(path) ? "mm-active" : ""}`}>
                <Link className="has-arrow ai-icon" to="#">
                    <i className="bi bi-file-earmark-break"></i>
                    <span className="nav-text">{t('pages')}</span>
                </Link>
                <ul>
                    <li className={`${error.includes(path) ? "mm-active" : ""}`}>
                        <Link className="has-arrow" to="#">Error</Link>
                        <ul>
                            <li><Link className={`${path === "page-error-400" ? "mm-active" : ""}`}
                                      to="/page-error-400">Error 400</Link></li>
                            <li><Link className={`${path === "page-error-403" ? "mm-active" : ""}`}
                                      to="/page-error-403">Error 403</Link></li>
                            <li><Link className={`${path === "page-error-404" ? "mm-active" : ""}`}
                                      to="/page-error-404">Error 404</Link></li>
                            <li><Link className={`${path === "page-error-500" ? "mm-active" : ""}`}
                                      to="/page-error-500">Error 500</Link></li>
                            <li><Link className={`${path === "page-error-503" ? "mm-active" : ""}`}
                                      to="/page-error-503">Error 503</Link></li>
                        </ul>
                    </li>
                    <li><Link className={`${path === "page-lock-screen" ? "mm-active" : ""}`}
                              to="/page-lock-screen">Lock Screen</Link></li>
                </ul>
            </li>,
            name: t("pages")
        },
        {
            data: <div key={"lang_nav"} className={"mx-4 mt-2 d-lg-none d-md-none  "}>
                <hr/>
                <br/>
                <img src={lang === 'en' ? United : Arabic} width={28} height={28} alt={""}/> {t('language')}
                <Switcher setRightSelect={setRightSelect} rightSelect={rightSelect}/>
            </div>,
            name: t('language')
        }

    ]

    const {appMenu: {scope}} = useSelector(s => s)


    if (scope) {
        nav = nav.filter(n => {
            const name = n.name?.toLowerCase()
            if (name.startsWith(scope)) return n
        })
    }

    return nav.map(n => n.data)
}


export default AppNavigator