export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}


export function tCreate({t, i18n}, toBeTranslate) {

    if (i18n.language === 'en') return t('create') + " " + t('new') + " " + t(toBeTranslate)

    return t('create') + " " + t(toBeTranslate) + " " + t('new')

}

export function tAdd({t, i18n}, toBeTranslate) {

    if (i18n.language === 'en') return t('add') + " " + t('new') + " " + t(toBeTranslate)

    return t('add') + " " + t(toBeTranslate) + " " + t('new')

}


export function tList({t, i18n}, toBeTranslate) {

    if (i18n.language === 'en') return t(toBeTranslate) + " " + t('list')

    return t('list') + " " + t(toBeTranslate)

}


export function tShow({t, i18n}, toBeTranslate) {

    if (i18n.language === 'en') return t(toBeTranslate) + " " + t('show')

    return t('show') + " " + t(toBeTranslate)

}

export function tLog({t, i18n}, toBeTranslate, state) {

    if (i18n.language === 'en') return t(state) + " " + t(toBeTranslate) + " " + t('list')

    return t('list') + " " + t(toBeTranslate) + " " + t(state)

}


export function tEdit({t, i18n}, toBeTranslate) {

    if (i18n.language === 'en') return t('edit') + " " + t(toBeTranslate)

    return t('edit') + " " + t(toBeTranslate)

}

export function translate({t, i18n}, toBeTranslate) {

    if (i18n.language === 'en') return t('translate') + " " + t(toBeTranslate)

    return t('translate') + " " + t(toBeTranslate)

}

export function randomIntFromInterval(min, max) { // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min)
}

export function traverse(obj, flattenedData, oldName = '') {

    const entries = Object.entries(obj)
    //iterate through the object. If we are encountering another object - send it again in recursion. If it's a value - add it to the flattened object
    for (const [key, value] of entries) {
        typeof value === 'object' && !Array.isArray(value)
            ? traverse(value, key + '-')
            : (flattenedData[oldName + key] = value)
    }


    return flattenedData

}

export function getStringBetween(str, char1, char2) {

    return str.slice(
        char1.length + 1,
        str.lastIndexOf(char2),
    );
}

export function getPathLocation() {
    let path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1];
    return path
}

export function flattenData(data, setData) {
    const flattenedData = {}
    const flatten = (obj, oldName = '') => {
        try {
            //we are counting on the fact that we will get an object at first - you can add a condition to check it here
            const entries = Object.entries(obj)
            //iterate through the object. If we are encountering another object - send it again in recursion. If it's a value - add it to the flattened object

            for (const [key, value] of entries) {

                if (key === "__v") {
                    continue;
                } else if (value === null) {
                    flattenedData[oldName + key] = "N/A"
                } else if (typeof value === 'boolean') {
                    flattenedData[key] = value ? 1 : 0
                } else if (!Array.isArray(value)) {
                    typeof value === 'object'
                        ? flatten(value, key + '-')
                        : (flattenedData[oldName + key] = value || "N/A")
                } else if (Array.isArray(value)) {
                    flattenedData[oldName + key] = value.length ? value.toString() : "N/A"
                }
            }
        } catch (e) {
            console.log("Flatten Error: ", e.message)
        }
    }

    flatten(data)
    setData(flattenedData)
}


export function getSymbol(country, library) {
    return ((library?.currency !== "N/A") ? library?.currency : country.currency_symbol)


}


export function uniqueArray(originalArray) {
    const uniqueArray = [];
    const filtered = []
    for (const item of originalArray) {
        if (!uniqueArray.includes(item?.value)) {
            uniqueArray.push(item.value);
            filtered.push(item)
        }
    }

    return filtered;
}