import AppService from "../../services/AppService";

export const init = {
    sideBarStyle: {value: "full", label: "Full",},
    sideBarPosition: {value: "fixed", label: "Fixed",},
    headerPosition: {value: "fixed", label: "Fixed",},
    sidebarLayout: {value: "vertical", label: "Vertical",},
    direction: {value: "ltr", label: "LTR"},
    primaryColor: "color_1",
    secondaryColor: "color_1",
    navigationHeader: "color_1",
    headerColor: "color_1",
    sidebarColor: "color_1",
    iconHover: false,
    menuToggle: false,
    background: {value: "light", label: "Light"},
    containerPosition_: {value: "wide-boxed", label: "Wide Boxed",},
    windowWidth: 0,
    windowHeight: 0,
}

export let app = AppService.hasAppSetting() ? AppService.getAppSetting() : init

export const appReducer = (state = app, action) => {
    switch (action.type) {
        case "DEFAULT" :
            return action.payload

        default :
            return state

    }
}

export const languageReducer = (state = {ar: {}, en: {}}, action) => {
    switch (action.type) {

        case "LANGUAGES":
            return {
                ...state,
                ...action.payload
            }
        case "LANGUAGES_FAILED":
            return {
                errors: action.payload
            }
        default :
            return state

    }
}

export const appMenuReducer = (state = {scope: null }, action) => {
    switch (action.type) {

        case "APP_MENU":
            return {scope : action.payload}
        default :
            return state

    }
}