export const
    CREATE_COMMENT_FAILED = "CREATE_COMMENT_FAILED",
    CREATE_COMMENT_LOADING = "CREATE_COMMENT_LOADING",
    CREATE_COMMENT_SUCCESS = "CREATE_COMMENT_SUCCESS",
    UPDATE_COMMENT_FAILED = "UPDATE_COMMENT_FAILED",
    UPDATE_COMMENT_LOADING = "UPDATE_COMMENT_LOADING",
    UPDATE_COMMENT_SUCCESS = "UPDATE_COMMENT_SUCCESS",
    DELETE_COMMENT_FAILED = "DELETE_COMMENT_FAILED",
    DELETE_COMMENT_LOADING = "DELETE_COMMENT_LOADING",
    DELETE_COMMENT_SUCCESS = "DELETE_COMMENT_SUCCESS",
    LIST_COMMENT_FAILED = "LIST_COMMENT_FAILED",
    LIST_COMMENT_LOADING = "LIST_COMMENT_LOADING",
    LIST_COMMENT_SUCCESS = "LIST_COMMENT_SUCCESS",
    SHOW_COMMENT_LOADING = "SHOW_COMMENT_LOADING",
    SHOW_COMMENT_FAILED = "SHOW_COMMENT_FAILED",
    SHOW_COMMENT_SUCCESS = "SHOW_COMMENT_SUCCESS"