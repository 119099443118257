import {CREATE_SITEMAP_FAILED, CREATE_SITEMAP_LOADING, CREATE_SITEMAP_SUCCESS} from "../Constants/SiteMap";
import {UPDATE_SITEMAP_FAILED, UPDATE_SITEMAP_LOADING, UPDATE_SITEMAP_SUCCESS} from "../Constants/SiteMap";
import {DELETE_SITEMAP_FAILED, DELETE_SITEMAP_LOADING, DELETE_SITEMAP_SUCCESS} from "../Constants/SiteMap";
import {LIST_SITEMAP_FAILED, LIST_SITEMAP_LOADING, LIST_SITEMAP_SUCCESS} from "../Constants/SiteMap";
import {SHOW_SITEMAP_FAILED, SHOW_SITEMAP_LOADING, SHOW_SITEMAP_SUCCESS} from "../Constants/SiteMap";
import {GLOBAL_REFRESH} from "../Constants/Refresh";
import {refresh} from "./index";


export const createSiteMapReducer = (state = {}, action) => {
    switch (action.type) {
        case CREATE_SITEMAP_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case CREATE_SITEMAP_LOADING :
            return {
                loading: true,
                errors: null,
                data: null
            }
        case CREATE_SITEMAP_FAILED :
            return {
                loading: false,
                data: null,
                errors: action.payload
            }
        case GLOBAL_REFRESH :
            return refresh()
        default :
            return state

    }
}

export const updateSiteMapReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_SITEMAP_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case UPDATE_SITEMAP_LOADING :
            return {
                loading: true,
                errors: null,
                data: action.payload
            }
        case UPDATE_SITEMAP_FAILED :
            return {
                loading: false,
                data: state,
                errors: action.payload
            }
        case GLOBAL_REFRESH :
            return refresh()

        default :
            return state

    }
}


export const showSiteMapReducer = (state = {}, action) => {
    switch (action.type) {
        case SHOW_SITEMAP_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case SHOW_SITEMAP_LOADING :
            return {
                loading: true,
                errors: null,
                data: null
            }
        case SHOW_SITEMAP_FAILED :
            return {
                loading: false,
                data: null,
                errors: action.payload
            }

        default :
            return state

    }
}


export const deleteSiteMapReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_SITEMAP_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case DELETE_SITEMAP_LOADING :
            return {
                loading: true,
                errors: null,
                data: action.payload
            }
        case DELETE_SITEMAP_FAILED :
            return {
                loading: false,
                data: state,
                errors: action.payload
            }

        default :
            return state

    }
}

export const listSiteMapReducer = (state = {}, action) => {
    switch (action.type) {
        case LIST_SITEMAP_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case LIST_SITEMAP_LOADING :
            return {
                loading: true,
                errors: null,
                data: []
            }
        case LIST_SITEMAP_FAILED :
            return {
                loading: false,
                data: [],
                errors: action.payload
            }

        default :
            return state

    }
}
