import Metismenu from "metismenujs";
import React, {Component, useEffect, useState} from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {useScrollPosition} from "@n8tb1t/use-scroll-position";
import {useSelector} from "react-redux";


class MM extends Component {

    constructor(props) {
        super(props);
        this.checkIfSelectedMenuActivated = this.checkIfSelectedMenuActivated.bind()
    }

    checkIfSelectedMenuActivated = () => {

        const navWrapper = document.querySelector('.metismenu')

        const liEl = navWrapper?.querySelector('li .mm-active')

        if (!liEl) return;

        const ul = liEl.querySelector('ul .mm-collapse')


        if (ul && ul?.classList && !ul?.classList?.contains('mm-show')) {
            ul.classList.add('mm-show')
        }

        if (ul) {
            const activatedLI = ul?.querySelector('li .mm-active')

            if (activatedLI) {
                const aTag = activatedLI?.getElementsByTagName('a')

                if (aTag[0]) {
                    const aTagElement = aTag[0]

                    if (aTagElement.style) {
                        aTagElement.style.color = '#0086ff'
                    }

                }
            }
        }

    }


    componentDidMount() {
        this.$el = this.el;
        this.mm = new Metismenu(this.$el);

        this.checkIfSelectedMenuActivated()
    }

    componentWillUnmount() {
    }

    render() {
        return (
            <div className="mm-wrapper">
                <ul className="metismenu" ref={(el) => (this.el = el)}>
                    {this.props.children}
                </ul>
            </div>
        );
    }
}


const SideBar = ({Navigator}) => {

    //For scroll
    const [hideOnScroll, setHideOnScroll] = useState(true)
    const btn = document.querySelector(".nav-control");
    const aaa = document.querySelector("#main-wrapper");
    const arrowButton = document.querySelector('.hamburger')
    const sideBarList = document.getElementById('sidebarList')


    useScrollPosition(
        ({prevPos, currPos}) => {
            const isShow = currPos.y > prevPos.y
            if (isShow !== hideOnScroll) setHideOnScroll(isShow)
        },
        [hideOnScroll]
    )

    useEffect(() => {
        btn?.addEventListener("click", toggleFunc);
    }, [btn]);

    const {
        app: {
            iconHover,
            sideBarPosition,
            headerPosition,
            sidebarLayout
        }
    } = useSelector(s => s)

    function toggleFunc() {
        return aaa?.classList?.toggle("menu-toggle");
    }

    const handleWindowNavResizeWindow = () => {
        window.addEventListener('click', function (e) {
            if (!sideBarList?.contains(e.target)) {
                window.addEventListener('click', function (e) {
                    if (arrowButton?.contains(e.target)) {
                    } else {
                        if (
                            aaa?.classList?.contains("menu-toggle") &&
                            window.innerWidth < 500 &&
                            !e?.target?.classList?.contains('has-arrow')
                        ) {
                            aaa.classList.remove("menu-toggle")
                        }
                    }
                })
            }
        });
    }


    window.addEventListener("resize", handleWindowNavResizeWindow);


    return (
        <div
            id={"sidebarList"}
            className={`dlabnav ${iconHover} ${
                sideBarPosition.value === "fixed" &&
                sidebarLayout.value === "horizontal" &&
                headerPosition.value === "static"
                    ? hideOnScroll > 120
                        ? "fixed"
                        : ""
                    : ""
            }`}
        >
            <PerfectScrollbar className="dlabnav-scroll">
                <MM className="metismenu" id="menu">
                    <Navigator/>
                </MM>

            </PerfectScrollbar>
        </div>
    );
};

export default React.memo(SideBar);
