export default {
    NODE_ENV: "production",
    BACKEND_URL: "https://server.mktabati.com",
    FRONTEND_URL: "https://mktabati.com",
    PORTAL_URL: "https://portal.mktabati.com",
    SOCKET_PORT: "5000",
    EXCEPT_END_POINTS: "/api/v1/login,/api/v1/register,/api/v1/refresh",
    s3: "https://learningms.s3.me-south-1.amazonaws.com/",
    SOCKET_URL: "https://socket.mktabati.com",
    SOCKET_TOKEN:"ey9if90er32kf0pisu745uf8n345yb3t5gedcioket",
    AUTH_URL: "https://accounts.mktabati.com",
    AUTH_CLIENT_ID: "UserServiceMktabatiApi",
    AUTH_CLIENT_SECRET: "SeBDJDfbBfcOvBWqumMhsGH77zkvSkJR",
    AUTH_REALM: "UserService",
}