import {Activated, featured, free, paid, trending} from "./State";
import {t} from 'i18next'
import React from "react";

const types = (message) => {

    // todo change bg color for each types
    return {
        blue:
            <span className="badge bg-info spinner-center text-primary text-center" role="status">
                <span className="text-white">  {message}  </span>
            </span>
        ,
        grey: <span className="badge bg-grey " role="status">
            <span className="text-white">  {message}  </span>
        </span>,
        green: <span className="badge bg-success " role="status">
            <span className="text-white">  {message}  </span>
        </span>,
        danger:
            <span className="badge bg-danger text-danger text-center" role="status">
                <span className="text-white">  {message}  </span>
            </span>
        ,
        warning: <span className="badge bg-warning" role="status">
            <span className="text-white">  {message}  </span>
        </span>,
        info: <span className="badge bg-info " role="status">
            <span className="text-white">  {message}  </span>
        </span>,
        light: <span className="badge bg-light " role="status">
            <span className="text-white">  {message}  </span>
        </span>,
        dark: <span className="badge bg-dark " role="status">
            <span className="text-white">  {message}  </span>
        </span>,
    }
}

const Badge = ({message = "", state = null, className = null, onClick = null}) => {

    if (message && state && className && onClick) {
        return <div onClick={onClick} className={className}>
            {types(t(message))[state]}
        </div>
    }
    if (message && state && className) {
        return <div className={className}>
            {types(t(message))[state]}
        </div>
    }
    if (message && state) {
        return types(t(message))[state]
    }

    if (state !== null) return parseInt(state) === Activated
        ? types(t('activated'))['info']
        : parseInt(state) === featured
            ? types(t('featured'))['info']
            : parseInt(state) === free
                ? types(t('free'))['light']
                : parseInt(state) === paid
                    ? types(t('paid'))['green']
                    : parseInt(state) === trending
                        ? types(t('trending'))['warning']
                        : types(t('disabled'))['danger']


    return types(t(message))['green']

}

export default React.memo(Badge)