import {dezThemeSet} from "../../context/ThemeDemo";
import AppService, {resetCache} from "../../services/AppService";
import axios from "axios";
import env from "../../env";
import {errorFormat} from "#validator";
import {handlePayloadErrors} from "../../Util/ErrorsHandler";

const body = document.querySelector("body");


const changePrimaryColor = (app, name) => {
    app.primaryColor = name
    body.setAttribute("data-primary", name);
    return app

};
const changeSecondaryColor = (app, name) => {
    app.secondaryColor = name
    body.setAttribute("data-secondary", name);
    return app

};
const changeNavigationHeader = (app, name) => {
    app.navigationHeader = name
    body.setAttribute("data-nav-headerbg", name);
    return app

};
const changeHeaderColor = (app, name) => {
    app.headerColor = name
    body.setAttribute("data-headerbg", name);
    return app

};
const changeSidebarColor = (app, name) => {
    app.sidebarColor = name
    body.setAttribute("data-sibebarbg", name);
    return app

};
const changeSideBarPosition = (app, name) => {
    app.sideBarPosition = name;
    body.setAttribute("data-sidebar-position", name);
    return app

};
const changeDirectionLayout = (app, name) => {
    app.direction = name
    body.setAttribute("direction", name);
    let html = document.querySelector("html");
    html.setAttribute("dir", name);
    html.className = name;
    return app

};


const changeSideBarLayout = (app, name) => {
    if (name.value === "horizontal") {
        if (app.sideBarStyle.value === "overlay") {
            app.sidebarLayout = name;
            body.setAttribute("data-layout", name.value);
            app.sideBarStyle = {value: "full", label: "Full"}
            body.setAttribute("data-sidebar-style", "full");
        } else {
            app.sidebarLayout = name;
            body.setAttribute("data-layout", name.value);
        }
    } else {
        app.sidebarLayout = name
        body.setAttribute("data-layout", name.value);
    }
    return app

};
const changeSideBarStyle = (app, name) => {
    if (app.sidebarLayout.value === "horizontal") {
        if (name.value === "overlay") {
            alert("Sorry! Overlay is not possible in Horizontal layout.");
        } else {
            app.sideBarStyle = name
            app.iconHover = name.value === "icon-hover" ? "_i-hover" : ""
            body.setAttribute("data-sidebar-style", name.value);
        }
    } else {
        app.sideBarStyle(app, name);
        app.iconHover = name.value === "icon-hover" ? "_i-hover" : ""
        body.setAttribute("data-sidebar-style", name.value);
    }
    return app

};

const changeHeaderPosition = (app, name) => {
    app.headerPosition = name
    body.setAttribute("data-header-position", name.value);
    return app

};

const openMenuToggle = (app, value = null) => {

    app.menuToggle = value;
    return app

};

const changeBackground = (app, name) => {
    body.setAttribute("data-theme-version", name.value);
    app.background = name

    return app
};

const changeContainerPosition = (app, name) => {
    app.containerPosition = name
    body.setAttribute("data-container", name.value);
    name.value === "boxed" &&
    changeSideBarStyle({value: "overlay", label: "Overlay"});
    return app

};

const setDemoTheme = (app, theme, direction) => {

    let setAttr = {};


    let themeSettings = dezThemeSet[theme];

    body.setAttribute("data-typography", themeSettings.typography);

    setAttr.value = themeSettings.version;
    changeBackground(app, setAttr);

    setAttr.value = themeSettings.layout;
    changeSideBarLayout(app, setAttr);

    //setAttr.value = themeSettings.primary;
    changePrimaryColor(app, themeSettings.primary);
    changeSecondaryColor(app, themeSettings.secondary);

//setAttr.value = themeSettings.navheaderBg;
    changeNavigationHeader(app, themeSettings.navheaderBg);

//setAttr.value = themeSettings.headerBg;
    changeHeaderColor(app, themeSettings.headerBg);

    setAttr.value = themeSettings.sidebarStyle;
    changeSideBarStyle(app, setAttr);

    //setAttr.value = themeSettings.sidebarBg;
    changeSidebarColor(app, themeSettings.sidebarBg);

    setAttr.value = themeSettings.sidebarPosition;
    changeSideBarPosition(app, setAttr);

    setAttr.value = themeSettings.headerPosition;
    changeHeaderPosition(app, setAttr);

    setAttr.value = themeSettings.containerLayout;
    changeContainerPosition(app, setAttr);

    //setAttr.value = themeSettings.direction;
    setAttr.value = direction;
    changeDirectionLayout(app, setAttr);

    return app

};

const changeWidth = (app, value) => {
    app.windowWidth = value


    return app
}

const changeHeight = (app, value) => {
    app.windowHeight = value
    return app
}


const functionMap = {
    changePrimaryColor: changePrimaryColor,
    changeSecondaryColor: changeSecondaryColor,
    changeNavigationHeader: changeNavigationHeader,
    changeHeaderColor: changeHeaderColor,
    changeSidebarColor: changeSidebarColor,
    changeSideBarPosition: changeSideBarPosition,
    changeDirectionLayout: changeDirectionLayout,
    changeSideBarLayout: changeSideBarLayout,
    changeSideBarStyle: changeSideBarStyle,
    changeHeaderPosition: changeHeaderPosition,
    openMenuToggle: openMenuToggle,
    changeBackground: changeBackground,
    changeContainerPosition: changeContainerPosition,
    setDemoTheme: setDemoTheme,
    changeWidth: changeWidth,
    changeHeight: changeHeight
}


export const call = {
    changePrimaryColor: "changePrimaryColor",
    changeSecondaryColor: "changeSecondaryColor",
    changeNavigationHeader: "changeNavigationHeader",
    changeHeaderColor: "changeHeaderColor",
    changeSidebarColor: "changeSidebarColor",
    changeSideBarPosition: "changeSideBarPosition",
    changeDirectionLayout: "changeDirectionLayout",
    changeSideBarLayout: "changeSideBarLayout",
    changeSideBarStyle: "changeSideBarStyle",
    changeHeaderPosition: "changeHeaderPosition",
    openMenuToggle: "openMenuToggle",
    changeBackground: "changeBackground",
    changeContainerPosition: "changeContainerPosition",
    setDemoTheme: "setDemoTheme",
    changeWidth: "changeWidth",
    changeHeight: "changeHeight",
}

export const AppHandler = (functionsName, dir = "ltr") => (dispatch, getState) => {
    let {app} = getState()

    try {

        Object.entries(functionsName).forEach(([functionName, valueOrName]) => {
            app = functionMap["setDemoTheme"](app, 5, dir)
            if (functionName in functionMap) {
                app = functionMap[functionName](app, valueOrName)
            }
        })

        AppService.setAppSetting(app)

        dispatch({
            type: "DEFAULT", payload: AppService.getAppSetting()
        })

    } catch (e) {
        dispatch({
            type: "DEFAULT", payload: app
        })
    }
}


export const LanguageHandler = () => async (dispatch) => {

    if (AppService.canRefreshLanguage()) {
        const {data: en} = await axios.get(env.BACKEND_URL + '/public/locales/en.json').then(r => r).catch(e => console.log("[LANGUAGE ERROR] : " + e.message))
        const {data: ar} = await axios.get(env.BACKEND_URL + '/public/locales/ar.json').then(r => r).catch(e => console.log("[LANGUAGE ERROR] : " + e.message))
        AppService.setLanguage({ar, en})
        AppService.setRefreshedLanguage()
        dispatch({
            type: "LANGUAGES", payload: {ar, en}
        })
    }


}


export const updateLanguagesHandler = (postData, setData) => async (dispatch, getState) => {

    try {

        const {data} = await AppService.updateLanguage(postData)
        const {lang} = getState()

        lang[postData.language] = data

        setData(lang)
        AppService.setLanguage(lang)
        dispatch({
            type: "LANGUAGES", payload: lang
        })

    } catch (e) {
        errorFormat(e)
        dispatch({
            type: "LANGUAGES_FAILED", payload: handlePayloadErrors(e)
        })
    }
}

export const deleteLanguagesHandler = (postData, setData) => async (dispatch, getState) => {

    try {

        const {data} = await AppService.deleteLanguage(postData)
        const {lang} = getState()

        lang[postData.language] = data

        setData(lang)
        AppService.setLanguage(lang)
        dispatch({
            type: "LANGUAGES", payload: lang
        })

    } catch (e) {
        errorFormat(e)
        dispatch({
            type: "LANGUAGES_FAILED", payload: handlePayloadErrors(e)
        })
    }
}


export const setMenuValueHandler = (value) => dispatch => {
    dispatch(
        {
            type: "APP_MENU", payload: value
        }
    )
}

export const resetCacheHandler = () => async dispatch => {
    await resetCache()
}