export const
    CREATE_COURSE_FAILED = "CREATE_COURSE_FAILED",
    CREATE_COURSE_LOADING = "CREATE_COURSE_LOADING",
    CREATE_COURSE_SUCCESS = "CREATE_COURSE_SUCCESS",
    UPDATE_COURSE_FAILED = "UPDATE_COURSE_FAILED",
    UPDATE_COURSE_LOADING = "UPDATE_COURSE_LOADING",
    UPDATE_COURSE_SUCCESS = "UPDATE_COURSE_SUCCESS",
    DELETE_COURSE_FAILED = "DELETE_COURSE_FAILED",
    DELETE_COURSE_LOADING = "DELETE_COURSE_LOADING",
    DELETE_COURSE_SUCCESS = "DELETE_COURSE_SUCCESS",
    LIST_COURSE_FAILED = "LIST_COURSE_FAILED",
    LIST_COURSE_LOADING = "LIST_COURSE_LOADING",
    LIST_COURSE_SUCCESS = "LIST_COURSE_SUCCESS",
    SHOW_COURSE_LOADING = "SHOW_COURSE_LOADING",
    SHOW_COURSE_FAILED = "SHOW_COURSE_FAILED",
    SHOW_COURSE_SUCCESS = "SHOW_COURSE_SUCCESS"