import {CREATE_HERO_FAILED, CREATE_HERO_LOADING, CREATE_HERO_SUCCESS} from "../Constants/Hero";
import {UPDATE_HERO_FAILED, UPDATE_HERO_LOADING, UPDATE_HERO_SUCCESS} from "../Constants/Hero";
import {DELETE_HERO_FAILED, DELETE_HERO_LOADING, DELETE_HERO_SUCCESS} from "../Constants/Hero";
import {LIST_HERO_FAILED, LIST_HERO_LOADING, LIST_HERO_SUCCESS} from "../Constants/Hero";
import {SHOW_HERO_FAILED, SHOW_HERO_LOADING, SHOW_HERO_SUCCESS} from "../Constants/Hero";
import {GLOBAL_REFRESH} from "../Constants/Refresh";
import {refresh} from "./index";


export const createHeroReducer = (state = {}, action) => {
    switch (action.type) {
        case CREATE_HERO_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case CREATE_HERO_LOADING :
            return {
                loading: true,
                errors: null,
                data: null
            }
        case CREATE_HERO_FAILED :
            return {
                loading: false,
                data: null,
                errors: action.payload
            }
        case GLOBAL_REFRESH :
            return refresh()
        default :
            return state

    }
}

export const updateHeroReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_HERO_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case UPDATE_HERO_LOADING :
            return {
                loading: true,
                errors: null,
                data: action.payload
            }
        case UPDATE_HERO_FAILED :
            return {
                loading: false,
                data: state,
                errors: action.payload
            }
        case GLOBAL_REFRESH :
            return refresh()

        default :
            return state

    }
}


export const showHeroReducer = (state = {}, action) => {
    switch (action.type) {
        case SHOW_HERO_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case SHOW_HERO_LOADING :
            return {
                loading: true,
                errors: null,
                data: null
            }
        case SHOW_HERO_FAILED :
            return {
                loading: false,
                data: null,
                errors: action.payload
            }

        default :
            return state

    }
}


export const deleteHeroReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_HERO_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case DELETE_HERO_LOADING :
            return {
                loading: true,
                errors: null,
                data: action.payload
            }
        case DELETE_HERO_FAILED :
            return {
                loading: false,
                data: state,
                errors: action.payload
            }

        default :
            return state

    }
}

export const listHeroReducer = (state = {}, action) => {
    switch (action.type) {
        case LIST_HERO_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case LIST_HERO_LOADING :
            return {
                loading: true,
                errors: null,
                data: []
            }
        case LIST_HERO_FAILED :
            return {
                loading: false,
                data: [],
                errors: action.payload
            }

        default :
            return state

    }
}






