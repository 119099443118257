export const
    USER_LOGIN_LOADING = 'USER_LOGIN_LOADING',
    USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS',
    USER_LOGIN_FAILED = 'USER_LOGIN_FAILED',

    USER_REFRESH = 'USER_REFRESH',

    USER_LIBERIAN_LOADING = 'USER_LIBERIAN_LOADING',
    USER_LIBERIAN_SUCCESS = 'USER_LIBERIAN_SUCCESS',
    USER_LIBERIAN_FAILED = 'USER_LIBERIAN_FAILED',

    USER_PROFILE_LOADING = "USER_PROFILE_LOADING",
    USER_PROFILE_FAILED = "USER_PROFILE_FAILED",
    USER_PROFILE_SUCCESS = "USER_PROFILE_SUCCESS",

    USERS_LOADING = "USERS_LOADING",
    USERS_SUCCESS = "USERS_SUCCESS",
    USERS_FAILED = "USERS_FAILED",


    USER_DETAILS_LOADING = "USER_DETAILS_LOADING",
    USER_DETAILS_FAILED = "USER_DETAILS_FAILED",
    USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS"

