import {AUDITS_FAILED, AUDITS_LOADING, AUDITS_SUCCESS} from "../Constants/Audit";

export const AuditsReducer = (state = {}, action) => {

    switch (action.type) {
        case AUDITS_LOADING :
            return {
                loading: true,
                errors: null,
                data: state
            }
        case AUDITS_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case AUDITS_FAILED :
            return {
                loading: false,
                data: state,
                errors: action.payload
            }

        default :
            return state

    }
}

