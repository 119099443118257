import {applyMiddleware, combineReducers, compose, legacy_createStore as createStore} from 'redux';
import thunk from 'redux-thunk';

import {
    createLibraryReducer,
    deleteLibraryReducer,
    listLibraryReducer,
    myLibraryReducer,
    showLibraryReducer,
    updateLibraryReducer
} from "./reducers/LibraryReducer";
import {
    liberianUsersReducer, userDetailsReducer, userLoginReducer, userProfileReducer, usersReducer
} from "./reducers/UserReducer";
import {
    createRouterMiddleware, createRouterReducer
} from "@lagunovsky/redux-react-router";
import {
    createColorReducer, deleteColorReducer, listColorReducer, showColorReducer, updateColorReducer
} from "./reducers/ColorReducer";
import {
    createCategoryReducer, deleteCategoryReducer, listCategoryReducer, showCategoryReducer, updateCategoryReducer
} from "./reducers/CategoryReducer";
import {
    createBlogReducer, deleteBlogReducer, listBlogReducer, showBlogReducer, updateBlogReducer
} from "./reducers/BlogReducer";
import {
    createEventReducer, deleteEventReducer, listEventReducer, showEventReducer, updateEventReducer
} from "./reducers/EventReducer";
import {
    createHeroReducer, deleteHeroReducer, listHeroReducer, showHeroReducer, updateHeroReducer
} from "./reducers/HeroReducer";
import {
    createBannerReducer, deleteBannerReducer, listBannerReducer, showBannerReducer, updateBannerReducer
} from "./reducers/BannerReducer";
import {
    createMenuReducer, deleteMenuReducer, listMenuReducer, showMenuReducer, updateMenuReducer
} from "./reducers/MenuReducer";
import {showTranslationReducer, updateTranslationReducer} from "./reducers/TranslationReducer";
import {
    createCourseReducer, deleteCourseReducer, listCourseReducer, showCourseReducer, updateCourseReducer
} from "./reducers/CourseReducer";

import {
    createFaqReducer, deleteFaqReducer, listFaqReducer, showFaqReducer, updateFaqReducer
} from "./reducers/FaqReducer";
import {
    createCommentReducer, deleteCommentReducer, listCommentReducer, showCommentReducer, updateCommentReducer
} from "./reducers/CommentReducer";

import {
    createSiteMapReducer, deleteSiteMapReducer, listSiteMapReducer, showSiteMapReducer, updateSiteMapReducer
} from "./reducers/SiteMapReducer";
import {clearLogReducer, deleteLogReducer, listLogReducer} from "./reducers/LogReducer";
import LanguageMiddleware from "./middleware/LanguageMiddleware";
import {getNotificationsReducer, listNotificationReducer} from "./reducers/NotificationReducer";
import {listRequestReducer} from "./reducers/RequestReducer";
import {myTransactionsReducer, transactionsReducer, transactionsStatisticsReducer} from "./reducers/TransactionReducer";
import {dashboardLibraryReducer} from "./reducers/DashboardReducer";
import {AuditsReducer} from "./reducers/AuditReducer";
import {
    BlockReducer, createBlockReducer, deleteBlockReducer, emailBlockReducer, showBlockReducer, updateBlockReducer
} from "./reducers/BlockReducer";
import {orderAttachmentReducer, ordersReducer} from "./reducers/OrderReducer";
import {TemporaryFundsReducer} from "./reducers/TemporaryFundReducer";
import {createContactReducer, deleteContactReducer, listContactReducer} from "./reducers/ContactReducer";

import SsoService from "../services/SsoService";
import {app as appSetting, appMenuReducer, appReducer, languageReducer} from "./reducers/AppReducer";
import AppService from "../services/AppService";
import {deleteSubscriberReducer, listSubscriberReducer} from "./reducers/SubscriberReducer";
import {deleteProcessReducer, listProcessReducer} from "./reducers/ProcessReducer";
import {deleteIpReducer, listIpReducer} from "./reducers/IPReducer";
import {getCountriesReducer, getDefaultCountryReducer} from "./reducers/CountryReducer";
import {getSubscribedLibrariesReducer} from "./reducers/SubscribedLibrariesReducer";
import {paymentLinkReducer} from "./reducers/PaymentLinkReducer";
import {createPspReducer, editPspReducer, pspsReducer, showPspReducer} from "./reducers/PspReducer";
import {createFeeReducer, deleteFeeReducer, editFeeReducer, feesReducer, showFeeReducer} from "./reducers/FeeReducer";

export const store = (history) => {

    const routerMiddleware = createRouterMiddleware(history)

    const middlewares = [LanguageMiddleware, routerMiddleware, thunk]

    const middleware = applyMiddleware(...middlewares);

    const composeEnhancers = window?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    let user = {accessToken: SsoService.getToken() || null, refreshToken: SsoService.getRefreshToken() || null}

    let app = AppService.createAppSettingIfNotExist(appSetting)

    const reducers = combineReducers({
        router: createRouterReducer(history),

        //library
        createLibrary: createLibraryReducer,
        updateLibrary: updateLibraryReducer,
        deleteLibrary: deleteLibraryReducer,
        listLibrary: listLibraryReducer,
        showLibrary: showLibraryReducer,
        myLibrary: myLibraryReducer,

        //user
        userLogin: userLoginReducer,
        liberianUsers: liberianUsersReducer,
        userProfile: userProfileReducer,
        users: usersReducer,
        getUserDetails: userDetailsReducer,

        // colors
        createColor: createColorReducer,
        updateColor: updateColorReducer,
        showColor: showColorReducer,
        deleteColor: deleteColorReducer,
        listColor: listColorReducer,

        //category
        createCategory: createCategoryReducer,
        updateCategory: updateCategoryReducer,
        showCategory: showCategoryReducer,
        deleteCategory: deleteCategoryReducer,
        listCategory: listCategoryReducer,

        // blog

        createBlog: createBlogReducer,
        updateBlog: updateBlogReducer,
        showBlog: showBlogReducer,
        deleteBlog: deleteBlogReducer,
        listBlog: listBlogReducer,

        //event
        createEvent: createEventReducer,
        updateEvent: updateEventReducer,
        showEvent: showEventReducer,
        deleteEvent: deleteEventReducer,
        listEvent: listEventReducer,

        //hero
        createHero: createHeroReducer,
        updateHero: updateHeroReducer,
        showHero: showHeroReducer,
        deleteHero: deleteHeroReducer,
        listHero: listHeroReducer,

        //banner
        createBanner: createBannerReducer,
        updateBanner: updateBannerReducer,
        showBanner: showBannerReducer,
        deleteBanner: deleteBannerReducer,
        listBanner: listBannerReducer,

        //menu
        createMenu: createMenuReducer,
        updateMenu: updateMenuReducer,
        showMenu: showMenuReducer,
        deleteMenu: deleteMenuReducer,
        listMenu: listMenuReducer,

        // translation
        updateTranslation: updateTranslationReducer,
        showTranslation: showTranslationReducer,

        // course
        createCourse: createCourseReducer,
        updateCourse: updateCourseReducer,
        showCourse: showCourseReducer,
        deleteCourse: deleteCourseReducer,
        listCourse: listCourseReducer,

        //faq
        createFaq: createFaqReducer,
        updateFaq: updateFaqReducer,
        showFaq: showFaqReducer,
        deleteFaq: deleteFaqReducer,
        listFaq: listFaqReducer,

        //comments
        createComment: createCommentReducer,
        updateComment: updateCommentReducer,
        showComment: showCommentReducer,
        deleteComment: deleteCommentReducer,
        listComment: listCommentReducer,

        //sitemap
        createSiteMap: createSiteMapReducer,
        updateSiteMap: updateSiteMapReducer,
        showSiteMap: showSiteMapReducer,
        deleteSiteMap: deleteSiteMapReducer,
        listSiteMap: listSiteMapReducer,

        // logs
        clearLog: clearLogReducer,
        deleteLog: deleteLogReducer,
        listLog: listLogReducer,

        //transactions
        myTransactions: myTransactionsReducer,
        transactions: transactionsReducer,
        transactionsStatistics: transactionsStatisticsReducer,

        // user request
        listRequest: listRequestReducer,

        // dashboard
        dashboardLibrary: dashboardLibraryReducer,


        // app
        app: appReducer,
        lang: languageReducer,
        notifications: listNotificationReducer,
        appMenu: appMenuReducer,

        audits: AuditsReducer, // blocks

        blocks: BlockReducer,
        createBlock: createBlockReducer,
        updateBlock: updateBlockReducer,
        showBlock: showBlockReducer,
        emailBlock: emailBlockReducer,
        deleteBlock: deleteBlockReducer,

        // contact
        deleteContact: deleteContactReducer,
        listContact: listContactReducer,
        createContact: createContactReducer,


        // subscribers
        deleteSubscriber: deleteSubscriberReducer,
        listSubscriber: listSubscriberReducer,

        // process
        deleteProcess: deleteProcessReducer,
        listProcess: listProcessReducer,

        // ips
        deleteIp: deleteIpReducer,
        listIp: listIpReducer,

        // orders
        orders: ordersReducer,
        orderAttachment: orderAttachmentReducer,

        //TemporaryFunds
        temporaryFunds: TemporaryFundsReducer,

        // country
        country: getDefaultCountryReducer,
        countries: getCountriesReducer,

        // subscribed libraries
        subscribedLibraries: getSubscribedLibrariesReducer,

        // payment links
        paymentLink: paymentLinkReducer,
        // notifications
        allNotifications: getNotificationsReducer,


        // psp
        psps: pspsReducer,
        createPsp: createPspReducer,
        editPsp: editPspReducer,
        showPsp: showPspReducer,

        // fee

        //sitemap
        createFee: createFeeReducer,
        updateFee: editFeeReducer,
        showFee: showFeeReducer,
        deleteFee: deleteFeeReducer,
        listFee: feesReducer,

    });


    const initialState = {
        userLogin: {
            data: {
                accessToken: user?.accessToken || null, refreshToken: user?.refreshToken || null
            }
        },
        app: app,
        lang: AppService.getLanguage() || {ar: {}, en: {}},
        notifications: {data: {count: 0, data: []}}
    }

    return createStore(reducers, initialState, composeEnhancers(middleware));
}




