import React from "react";
import {useTranslation} from "react-i18next";
import {isAdmin} from "../../../Role";
import UseAppDirection from "../../../Hooks/UseAppDirection";

export const Activated = 1, Disabled = 2, featured = 20, free = 30, paid = 40, trending = 50


export const mapStateToStyle = {
    1: "success", //  completed
    2: "warning", //  pending
    3: "danger", //  rejected
    4: "danger", //  expired
    5: "danger", //  refund
    6: "danger", //  expenses
    10: "danger", // failed
    11: 'success'
}

export const transactionStatus = {
    completed: 1, // or complected or paid
    pending: 2, // or under_processing
    rejected: 3,
    expired: 4,
    refund: 5,
    expenses: 6,
    failed: 10, // failed or internal error
    payout: 11,
}


export const payoutStatus = {
    ready_to_payout: 1,
    pending: 2,
    rejected: 3,
    failed: 10,
    settled: 100,
}
export const generalConfig = (inputs) => [{
    name: "state", holder: "activated", value: Activated, checked: parseInt(inputs?.state) === Activated
}, {
    name: "state", holder: "disabled", value: Disabled, checked: parseInt(inputs?.state) === Disabled
}]

export const booleanConfig = (inputs, name = 'isDefault') => [{
    name, holder: "true", value: "true", checked: (inputs?.isDefault) === "true"
}, {
    name, holder: "false", value: "false", checked: (inputs?.isDefault) === "false"
}
]


export const stateToTranslatedString = {
    [Activated]: "activated",
    [Disabled]: "disabled",
    [featured]: "featured",
    [free]: "free",
    [paid]: "paid",
    [trending]: "trending",
}

export const transactionStatusToTranslatedString = {
    [transactionStatus.completed]: "completed",
    [transactionStatus.rejected]: "rejected",
    [transactionStatus.expired]: "expired",
    [transactionStatus.failed]: "failed",
    [transactionStatus.pending]: "pending",
    [transactionStatus.refund]: "refund",
    [transactionStatus.expenses]: "expenses",
    [transactionStatus.payout]: "payout",
}


export const payoutStatusToTranslatedString = {
    [payoutStatus.ready_to_payout]: "ready_to_payout",
    [payoutStatus.rejected]: "rejected",
    [payoutStatus.failed]: "failed",
    [payoutStatus.pending]: "pending",
    [payoutStatus.settled]: "settled",
}


export const courseConfig = (inputs) => [

    {
        name: "state", holder: "featured", value: featured, checked: parseInt(inputs?.state) === featured
    },
    {
        name: "state", holder: "paid", value: paid, checked: parseInt(inputs?.state) === paid
    },
    {
        name: "state", holder: "trending", value: trending, checked: parseInt(inputs?.state) === trending
    },
    {
        name: "state", holder: "free", value: free, checked: parseInt(inputs?.state) === free
    },
    {
        name: "state", holder: "disabled", value: Disabled, checked: parseInt(inputs?.state) === Disabled
    },
]

const State = ({label, config, onChange}) => {

    const {t} = useTranslation();

    const lang = UseAppDirection()


    if (isAdmin()) {
        return <fieldset className="form-group inputDir">
            <div className=" mb-1">
                <label className="col-form-label col-2 pt-0">
                    {t(label)?.toUpperCase()} :
                </label>

                <div className="col-2 offset-2" style={lang === 'ar' ? {marginRight: "16.66667%"} : null}>
                    <span id={config[0].name.toString()}></span>
                    {config.map((input, i) => <div key={i + "__state__" + input.name.toString()} className="form-check">
                        <input
                            onChange={onChange}
                            className="form-check-input"
                            type="radio"
                            name={input.name}
                            value={input.value}
                            checked={input.checked}
                        />
                        <label className="form-check-label">
                            {t(input.holder)?.toUpperCase()}
                        </label>
                    </div>)}

                </div>
            </div>
        </fieldset>
    } else {
        return null
    }


}

export default React.memo(State)