import {TemporaryFund_FAILED, TemporaryFund_LOADING, TemporaryFund_SUCCESS} from "../Constants/TemporaryFund";


export const TemporaryFundsReducer = (state = {data: []}, action) => {
    switch (action.type) {
        case TemporaryFund_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case TemporaryFund_LOADING :
            return {
                loading: true,
                errors: null,
                data: state?.data?.length ? state.data : []
            }
        case TemporaryFund_FAILED :
            return {
                loading: false,
                data: state?.data?.length ? state.data : [],
                errors: action.payload
            }

        default :
            return state

    }
}






