import {LIST_REQUEST_FAILED, LIST_REQUEST_LOADING, LIST_REQUEST_SUCCESS} from "../Constants/UserRequest";


export const listRequestReducer = (state = {data: []}, action) => {
    switch (action.type) {
        case LIST_REQUEST_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case LIST_REQUEST_LOADING :
            return {
                loading: true,
                errors: null,
                data: state?.data?.length ? state.data : []
            }
        case LIST_REQUEST_FAILED :
            return {
                loading: false,
                data: state?.data?.length ? state.data : [],
                errors: action.payload
            }

        default :
            return state

    }
}






