export const
    CREATE_CATEGORY_FAILED = "CREATE_CATEGORY_FAILED",
    CREATE_CATEGORY_LOADING = "CREATE_CATEGORY_LOADING",
    CREATE_CATEGORY_SUCCESS = "CREATE_CATEGORY_SUCCESS",
    UPDATE_CATEGORY_FAILED = "UPDATE_CATEGORY_FAILED",
    UPDATE_CATEGORY_LOADING = "UPDATE_CATEGORY_LOADING",
    UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS",
    DELETE_CATEGORY_FAILED = "DELETE_CATEGORY_FAILED",
    DELETE_CATEGORY_LOADING = "DELETE_CATEGORY_LOADING",
    DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS",
    LIST_CATEGORY_FAILED = "LIST_CATEGORY_FAILED",
    LIST_CATEGORY_LOADING = "LIST_CATEGORY_LOADING",
    LIST_CATEGORY_SUCCESS = "LIST_CATEGORY_SUCCESS",
    SHOW_CATEGORY_LOADING = "SHOW_CATEGORY_LOADING",
    SHOW_CATEGORY_FAILED = "SHOW_CATEGORY_FAILED",
    SHOW_CATEGORY_SUCCESS = "SHOW_CATEGORY_SUCCESS"