import {CREATE_COURSE_FAILED, CREATE_COURSE_LOADING, CREATE_COURSE_SUCCESS} from "../Constants/Course";
import {UPDATE_COURSE_FAILED, UPDATE_COURSE_LOADING, UPDATE_COURSE_SUCCESS} from "../Constants/Course";
import {DELETE_COURSE_FAILED, DELETE_COURSE_LOADING, DELETE_COURSE_SUCCESS} from "../Constants/Course";
import {LIST_COURSE_FAILED, LIST_COURSE_LOADING, LIST_COURSE_SUCCESS} from "../Constants/Course";
import {SHOW_COURSE_FAILED, SHOW_COURSE_LOADING, SHOW_COURSE_SUCCESS} from "../Constants/Course";
import {GLOBAL_REFRESH} from "../Constants/Refresh";
import {refresh} from "./index";


export const createCourseReducer = (state = {}, action) => {
    switch (action.type) {
        case CREATE_COURSE_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case CREATE_COURSE_LOADING :
            return {
                loading: true,
                errors: null,
                data: null
            }
        case CREATE_COURSE_FAILED :
            return {
                loading: false,
                data: null,
                errors: action.payload
            }
        case GLOBAL_REFRESH :
            return refresh()
        default :
            return state

    }
}

export const updateCourseReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_COURSE_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case UPDATE_COURSE_LOADING :
            return {
                loading: true,
                errors: null,
                data: action.payload
            }
        case UPDATE_COURSE_FAILED :
            return {
                loading: false,
                data: state,
                errors: action.payload
            }
        case GLOBAL_REFRESH :
            return refresh()

        default :
            return state

    }
}


export const showCourseReducer = (state = {}, action) => {
    switch (action.type) {
        case SHOW_COURSE_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case SHOW_COURSE_LOADING :
            return {
                loading: true,
                errors: null,
                data: null
            }
        case SHOW_COURSE_FAILED :
            return {
                loading: false,
                data: null,
                errors: action.payload
            }

        default :
            return state

    }
}


export const deleteCourseReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_COURSE_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case DELETE_COURSE_LOADING :
            return {
                loading: true,
                errors: null,
                data: action.payload
            }
        case DELETE_COURSE_FAILED :
            return {
                loading: false,
                data: state,
                errors: action.payload
            }

        default :
            return state

    }
}

export const listCourseReducer = (state = {}, action) => {
    switch (action.type) {
        case LIST_COURSE_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case LIST_COURSE_LOADING :
            return {
                loading: true,
                errors: null,
                data: []
            }
        case LIST_COURSE_FAILED :
            return {
                loading: false,
                data: [],
                errors: action.payload
            }

        default :
            return state

    }
}






