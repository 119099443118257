import {CREATE_COMMENT_FAILED, CREATE_COMMENT_LOADING, CREATE_COMMENT_SUCCESS} from "../Constants/Comment";
import {UPDATE_COMMENT_FAILED, UPDATE_COMMENT_LOADING, UPDATE_COMMENT_SUCCESS} from "../Constants/Comment";
import {DELETE_COMMENT_FAILED, DELETE_COMMENT_LOADING, DELETE_COMMENT_SUCCESS} from "../Constants/Comment";
import {LIST_COMMENT_FAILED, LIST_COMMENT_LOADING, LIST_COMMENT_SUCCESS} from "../Constants/Comment";
import {SHOW_COMMENT_FAILED, SHOW_COMMENT_LOADING, SHOW_COMMENT_SUCCESS} from "../Constants/Comment";
import {GLOBAL_REFRESH} from "../Constants/Refresh";
import {refresh} from "./index";


export const createCommentReducer = (state = {}, action) => {
    switch (action.type) {
        case CREATE_COMMENT_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case CREATE_COMMENT_LOADING :
            return {
                loading: true,
                errors: null,
                data: null
            }
        case CREATE_COMMENT_FAILED :
            return {
                loading: false,
                data: null,
                errors: action.payload
            }
        case GLOBAL_REFRESH :
            return refresh()
        default :
            return state

    }
}

export const updateCommentReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_COMMENT_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case UPDATE_COMMENT_LOADING :
            return {
                loading: true,
                errors: null,
                data: action.payload
            }
        case UPDATE_COMMENT_FAILED :
            return {
                loading: false,
                data: state,
                errors: action.payload
            }
        case GLOBAL_REFRESH :
            return refresh()

        default :
            return state

    }
}


export const showCommentReducer = (state = {}, action) => {
    switch (action.type) {
        case SHOW_COMMENT_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case SHOW_COMMENT_LOADING :
            return {
                loading: true,
                errors: null,
                data: null
            }
        case SHOW_COMMENT_FAILED :
            return {
                loading: false,
                data: null,
                errors: action.payload
            }

        default :
            return state

    }
}


export const deleteCommentReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_COMMENT_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case DELETE_COMMENT_LOADING :
            return {
                loading: true,
                errors: null,
                data: action.payload
            }
        case DELETE_COMMENT_FAILED :
            return {
                loading: false,
                data: state,
                errors: action.payload
            }

        default :
            return state

    }
}

export const listCommentReducer = (state = {}, action) => {
    switch (action.type) {
        case LIST_COMMENT_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case LIST_COMMENT_LOADING :
            return {
                loading: true,
                errors: null,
                data: []
            }
        case LIST_COMMENT_FAILED :
            return {
                loading: false,
                data: [],
                errors: action.payload
            }

        default :
            return state

    }
}






