export const
    CREATE_FAQ_FAILED = "CREATE_FAQ_FAILED",
    CREATE_FAQ_LOADING = "CREATE_FAQ_LOADING",
    CREATE_FAQ_SUCCESS = "CREATE_FAQ_SUCCESS",
    UPDATE_FAQ_FAILED = "UPDATE_FAQ_FAILED",
    UPDATE_FAQ_LOADING = "UPDATE_FAQ_LOADING",
    UPDATE_FAQ_SUCCESS = "UPDATE_FAQ_SUCCESS",
    DELETE_FAQ_FAILED = "DELETE_FAQ_FAILED",
    DELETE_FAQ_LOADING = "DELETE_FAQ_LOADING",
    DELETE_FAQ_SUCCESS = "DELETE_FAQ_SUCCESS",
    LIST_FAQ_FAILED = "LIST_FAQ_FAILED",
    LIST_FAQ_LOADING = "LIST_FAQ_LOADING",
    LIST_FAQ_SUCCESS = "LIST_FAQ_SUCCESS",
    SHOW_FAQ_LOADING = "SHOW_FAQ_LOADING",
    SHOW_FAQ_FAILED = "SHOW_FAQ_FAILED",
    SHOW_FAQ_SUCCESS = "SHOW_FAQ_SUCCESS"
