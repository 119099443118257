export const
    CREATE_SITEMAP_FAILED = "CREATE_SITEMAP_FAILED",
    CREATE_SITEMAP_LOADING = "CREATE_SITEMAP_LOADING",
    CREATE_SITEMAP_SUCCESS = "CREATE_SITEMAP_SUCCESS",
    UPDATE_SITEMAP_FAILED = "UPDATE_SITEMAP_FAILED",
    UPDATE_SITEMAP_LOADING = "UPDATE_SITEMAP_LOADING",
    UPDATE_SITEMAP_SUCCESS = "UPDATE_SITEMAP_SUCCESS",
    DELETE_SITEMAP_FAILED = "DELETE_SITEMAP_FAILED",
    DELETE_SITEMAP_LOADING = "DELETE_SITEMAP_LOADING",
    DELETE_SITEMAP_SUCCESS = "DELETE_SITEMAP_SUCCESS",
    LIST_SITEMAP_FAILED = "LIST_SITEMAP_FAILED",
    LIST_SITEMAP_LOADING = "LIST_SITEMAP_LOADING",
    LIST_SITEMAP_SUCCESS = "LIST_SITEMAP_SUCCESS",
    SHOW_SITEMAP_LOADING = "SHOW_SITEMAP_LOADING",
    SHOW_SITEMAP_FAILED = "SHOW_SITEMAP_FAILED",
    SHOW_SITEMAP_SUCCESS = "SHOW_SITEMAP_SUCCESS"
