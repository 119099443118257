const GeneralFilter = (keyName, rows, id, filterValues) => {

    console.log({
        keyName, rows, id, filterValues
    })
    return rows?.filter(row => {
        const name = row?.values?.[id[0]][keyName]?.toString()?.toLowerCase()
        return name?.startsWith(filterValues?.toString()?.toLowerCase()) || false
    })
}

export default GeneralFilter