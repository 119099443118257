export const CREATE_COLOR_SUCCESS = 'CREATE_COLOR_SUCCESS';
export const CREATE_COLOR_LOADING = 'CREATE_COLOR_LOADING';
export const CREATE_COLOR_FAILED = 'CREATE_COLOR_FAILED';


export const UPDATE_COLOR_SUCCESS = 'UPDATE_COLOR_SUCCESS';
export const UPDATE_COLOR_LOADING = 'UPDATE_COLOR_LOADING';
export const UPDATE_COLOR_FAILED = 'UPDATE_COLOR_FAILED';


export const DELETE_COLOR_SUCCESS = 'DELETE_COLOR_SUCCESS';
export const DELETE_COLOR_LOADING = 'DELETE_COLOR_LOADING';
export const DELETE_COLOR_FAILED = 'DELETE_COLOR_FAILED';


export const LIST_COLOR_SUCCESS = 'LIST_COLOR_SUCCESS';
export const LIST_COLOR_LOADING = 'LIST_COLOR_LOADING';
export const LIST_COLOR_FAILED = 'LIST_COLOR_FAILED';


export const SHOW_COLOR_LOADING = 'SHOW_COLOR_LOADING';
export const SHOW_COLOR_FAILED = 'SHOW_COLOR_FAILED';
export const SHOW_COLOR_SUCCESS = 'SHOW_COLOR_SUCCESS';