import {UPDATE_TRANSLATION_FAILED, UPDATE_TRANSLATION_LOADING, UPDATE_TRANSLATION_SUCCESS} from "../Constants/Translation";
import {SHOW_TRANSLATION_FAILED, SHOW_TRANSLATION_LOADING, SHOW_TRANSLATION_SUCCESS} from "../Constants/Translation";
import {GLOBAL_REFRESH} from "../Constants/Refresh";
import {refresh} from "./index";




export const updateTranslationReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_TRANSLATION_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case UPDATE_TRANSLATION_LOADING :
            return {
                loading: true,
                errors: null,
                data: action.payload
            }
        case UPDATE_TRANSLATION_FAILED :
            return {
                loading: false,
                data: state,
                errors: action.payload
            }
        case GLOBAL_REFRESH :
            return refresh()

        default :
            return state

    }
}


export const showTranslationReducer = (state = {}, action) => {
    switch (action.type) {
        case SHOW_TRANSLATION_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case SHOW_TRANSLATION_LOADING :
            return {
                loading: true,
                errors: null,
                data: null
            }
        case SHOW_TRANSLATION_FAILED :
            return {
                loading: false,
                data: null,
                errors: action.payload
            }

        default :
            return state

    }
}







