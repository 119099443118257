export const
    APPROVE_REQUEST_FAILED = "APPROVE_REQUEST_FAILED",
    APPROVE_REQUEST_LOADING = "APPROVE_REQUEST_LOADING",
    APPROVE_REQUEST_SUCCESS = "APPROVE_REQUEST_SUCCESS",
    REJECT_REQUEST_FAILED = "REJECT_REQUEST_FAILED",
    REJECT_REQUEST_LOADING = "REJECT_REQUEST_LOADING",
    REJECT_REQUEST_SUCCESS = "REJECT_REQUEST_SUCCESS",
    REVOKE_REQUEST_FAILED = "REVOKE_REQUEST_FAILED",
    REVOKE_REQUEST_LOADING = "REVOKE_REQUEST_LOADING",
    REVOKE_REQUEST_SUCCESS = "REVOKE_REQUEST_SUCCESS",
    LIST_REQUEST_FAILED = "LIST_REQUEST_FAILED",
    LIST_REQUEST_LOADING = "LIST_REQUEST_LOADING",
    LIST_REQUEST_SUCCESS = "LIST_REQUEST_SUCCESS"
