import SsoService from "../services/SsoService";

export const ADMIN = 'admin'
export const LIBERIAN = "liberian"
export const USER = "user"


export const isAdmin = () => {
    const role = SsoService.getLoggedInUserRole()
    return role === ADMIN
}
export const isLiberian = () => {
    const role = SsoService.getLoggedInUserRole()
    return role === LIBERIAN
}
export const isUser = () => {
    const role = SsoService.getLoggedInUserRole()
    return role === USER
}

let allowLibrarian = {
    "management": "management",
    "dashboard": "dashboard",
    "orders_management": "orders_management",
    "/dashboard": "/dashboard",
    "showTotalFunds": "showTotalFunds",
    "course": "course",
    "/profile": "/profile",
    '/activity': "/activity",
    '/': '/',
    '': '',
    "notificationList": "notificationList",
    "/courses-list": "/courses-list",
    "/course-create": "/course-create",
    "/course-edit": "/course-edit",
    "/library-transactions": "/library-transactions",
    "/library-schedule": "/library-schedule",
    "instructor": "instructor",
    "/my-transactions": "/my-transactions",
    "/library-profile": "/library-profile",
    "subscribed_library": "subscribed_library",
    "customer_support": "customer_support",
    "generate-payment-link": "generate-payment-link"

}

let denyLiberian = {
    "libraries-list": "libraries-list",
    "library-create": "library-create",
    "library-edit": "library-edit",
    "task": "task",
    "app-profile": "app-profile",
    "post-details": "post-details",
    "app-calender": "app-calender",
    "email-compose": "email-compose",
    "email-inbox": "email-inbox",
    "email-read": "email-read",
    "ecom-product-grid": "ecom-product-grid",
    "ecom-product-list": "ecom-product-list",
    "ecom-product-order": "ecom-product-order",
    "ecom-checkout": "ecom-checkout",
    "ecom-invoice": "ecom-invoice",
    "ecom-customers": "ecom-customers",
    "ecom-product-detail": "ecom-product-detail",
    "chart-rechart": "chart-rechart",
    "chart-flot": "chart-flot",
    "chart-chartjs": "chart-chartjs",
    "chart-chartist": "chart-chartist",
    "chart-sparkline": "chart-sparkline",
    "chart-apexchart": "chart-apexchart",
    "ui-accordion": "ui-accordion",
    "ui-badge": "ui-badge",
    "ui-alert": "ui-alert",
    "ui-button": "ui-button",
    "ui-modal": "ui-modal",
    "ui-button-group": "ui-button-group",
    "ui-list-group": "ui-list-group",
    "ui-card": "ui-card",
    "ui-carousel": "ui-carousel",
    "ui-dropdown": "ui-dropdown",
    "ui-popover": "ui-popover",
    "ui-progressbar": "ui-progressbar",
    "ui-tab": "ui-tab",
    "ui-typography": "ui-typography",
    "ui-pagination": "ui-pagination",
    "ui-grid": "ui-grid",
    "uc-select2": "uc-select2",
    "uc-nestable": "uc-nestable",
    "uc-sweetalert": "uc-sweetalert",
    "uc-toastr": "uc-toastr",
    "uc-noui-slider": "uc-noui-slider",
    "map-jqvmap": "map-jqvmap",
    "uc-lightgallery": "uc-lightgallery",
    "redux-form": "redux-form",
    "redux-wizard": "redux-wizard",
    "todo": "todo",
    "form-element": "form-element",
    "form-wizard": "form-wizard",
    "form-editor-summernote": "form-editor-summernote",
    "form-pickers": "form-pickers",
    "form-validation-jquery": "form-validation-jquery",
    "page-register": "page-register",
    "page-login": "page-login",
    "page-lock-screen": "page-lock-screen",
    "user": "user",
    "users": "users",
    "users-list": "users-list",
    "logs-list": "logs-list",
    "user_requests-list": "user_requests-list",
    "languages-list": "languages-list",
    "sitemaps-list": "sitemaps-list",
    'schedule': 'schedule',
    'instructors': 'instructors',
    'message': 'message',
    "events-list": "events-list",
    "event-create": "event-create",
    "event-edit": "event-edit",
    "blogs-list": "blogs-list",
    "contacts-list": "contacts-list",
    "blog-create": "blog-create",
    "blog-edit": "blog-edit",
    "crud-logs": "crud-logs",
    "access_management": "access_management",
    "ips-list": "ips-list",
    "subscribers-list": "subscribers-list",
    "processes-list": "processes-list",
    "funds_management": "funds_management",
    "temporaryfunds-list": "temporaryfunds-list",
    "blocks-list": "blocks-list",
    "Languages-list": "Languages-list",
    "permission-create": "permission-create",
    "role-create": "role-create",
    "psps-list": "psps-list",
    "create-psp": "create-psp",
    "edit-psp": "edit-psp",
    "fees-list": "fees-list",
    "create-fee": "create-fee",
    "edit-fee": "edit-fee",
    "psp_management": "psp_management",
    "fee_management": "fee_management",

}

let allowUser = {
    "/profile": "/profile",
    "/activity": "/activity",
    '/': '/',
    '': '',
    "notificationList": "notificationList",
    "subscribed_library": "subscribed_library",
    "customer_support": "customer_support",
    "orders_management": "orders_management",
    "dashboard": "dashboard",

}


let appendDeniedToUser = {
    "event": "event",
    "course": "course",
    "courses-list": "courses-list",
    "course-create": "course-create",
    "course-edit": "course-edit",
    "management": "management",
    'generate-payment-link': "generate-payment-link"
}

let denyUser = {...denyLiberian, ...appendDeniedToUser}

export const deny = (path) => {

    if (path === '') return false

    if (isLiberian()) return denyLiberian[path]

    if (isUser()) return denyUser[path]

    return !isAdmin()
}

export const can = (section) => {

    if (isAdmin()) return true

    if (isLiberian()) {
        return allowLibrarian[section]
    }

    if (isUser()) {
        return allowUser[section]
    }

    return false

}
