import React from "react";
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import ScrollToTop from "./layouts/ScrollToTop";
import {useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {getPathLocation} from "../Helper/functions";


const Markup = ({children}) => {

    const {app: {menuToggle}} = useSelector(s => s)
    const location = useLocation()

    let path = getPathLocation()


    let pagePath = path.split("-").includes("page");

    let expect = [
        "login",
        "page-register",
        "page-forgot-password",
    ]

    if (expect.includes(path) || expect.includes(location.pathname)) {
        return children
    }


    return (
        <>
            <div
                id={`${!pagePath ? "main-wrapper" : ""}`}
                className={`${!pagePath ? "show" : "mh100vh"}  ${
                    menuToggle ? "menu-toggle" : ""
                }`}>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    draggable
                    theme="colored"
                />

                {!pagePath && <Nav/>}

                <div className={`${!pagePath ? "content-body" : ""}`} style={{minHeight: window.screen.height - 45}}>
                    <div className={`${!pagePath ? "container-fluid" : ""}`}>
                        {children}
                    </div>
                </div>
                {!pagePath && <Footer/>}
            </div>
            <ScrollToTop/>
        </>
    );
};

export default Markup;
