import React from "react";
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import ReactDOM from "react-dom/client";
import App from "./App";
import {Provider} from 'react-redux';
import {store} from './store/store';
import reportWebVitals from "./reportWebVitals";
import SimpleReactLightbox from "simple-react-lightbox";
import {ReduxRouter} from "@lagunovsky/redux-react-router";
import {createBrowserHistory} from "history";
import {Interceptor} from "./Hooks/UseInterceptor";
import {HelmetProvider} from "react-helmet-async";
import SsoService from "./services/SsoService";


const history = createBrowserHistory()

const redux = store(history)


export const Main = () => {


    return (
        // <React.StrictMode>
        <SimpleReactLightbox>
            <HelmetProvider>
                <Provider store={redux}>
                    <ReduxRouter
                        store={redux}
                        children={<App/>}
                        history={history}/>

                </Provider>
            </HelmetProvider>
        </SimpleReactLightbox>
        // </React.StrictMode>
    )
}

const root = () => {
    Interceptor(history)
    ReactDOM.createRoot(document.getElementById("root")).render(<Main/>)
};
SsoService.initKeycloak(root)

reportWebVitals();


