import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Dropdown} from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import LogoutPage from './Logout';
import United from "../../../images/United.png";
import Arabic from "../../../images/ar.svg";
import {Switcher} from "#language";
import userImage from './../../../images/user.png';
import env from "../../../env";
import {useTranslation} from "react-i18next";
import dayjs from "dayjs";
import {randomIntFromInterval} from "../../../Helper/functions";
import {can} from "../../../Role";
import {useDispatch, useSelector} from "react-redux";
import {resetCacheHandler} from "../../../store/actions/AppActions";


const Notification = React.memo(({t, ext, notify, classChange}) => {


        return (
            <>
                <li>
                    <div className="timeline-panel">
                        <div className={`media me-2 ${classChange}`}>{ext}</div>
                        <div className="media-body">
                            <Link to={"/activity"}>
                                <h6 className="mb-1">{t(notify.message)}</h6>
                            </Link>
                            <small className="d-block">{notify?.createdAt && dayjs(notify?.createdAt).toString()}</small>
                        </div>
                    </div>
                </li>
            </>
        )
    }
)

const RightSideBar = ({onNote, rightSelect, setRightSelect, lang, listOfNotifications, count}) => {

    const {t} = useTranslation()
    const [ext, setExt] = useState(null)
    const {
        myLibrary: {data: library},
        getUserDetails: {data: user},
    } = useSelector(s => s)


    useEffect(() => {
        if (user && user.first_name && !user.isBlocked) {
            setExt(user?.first_name[0]?.toUpperCase() + user?.last_name[0]?.toUpperCase())
        }
    }, [user])

    const styles = {
        1: "info",
        2: "success",
        3: "danger",
        4: "info",
    }

    const dispatch = useDispatch()

    const resetCache = () => {
        dispatch(resetCacheHandler())
    }
    return (
        <>

            <div className="dlab-side-menu">
                <div className="search-coundry d-flex align-items-center">
                    {
                        can('showTotalFunds') && library?.totalFunds && <div className={"m-2"}>

                            <strong className={"text-white "}>
                                {
                                    t('total_earnings') + " " + ((library?.currency !== "N/A") ? library?.currency : null)
                                }
                            </strong> <span
                            className={"text-white"}>  {":" + parseFloat(library.totalFunds).toFixed(2)}</span>
                        </div>
                    }

                    <img src={lang === 'en' ? United : Arabic} width={28} height={28} alt="" className='mx-2'/>
                    <Switcher setRightSelect={setRightSelect} rightSelect={rightSelect}/>
                </div>
                <div className="sidebar-social-link ">
                    <ul className="">


                        {can('settingList') && <Dropdown as="li" className="nav-item dropdown notification_dropdown ">
                            <Dropdown.Toggle variant="" as="a"
                                             className="nav-link text-white  ai-icon i-false c-pointer"
                                             role="button" data-toggle="">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M20.4023 13.4798C20.7599 13.6577 21.0359 13.9387 21.23 14.2197C21.6082 14.8003 21.5775 15.5121 21.2096 16.1395L20.4942 17.2634C20.1161 17.8627 19.411 18.2373 18.6854 18.2373C18.3277 18.2373 17.9291 18.1437 17.6021 17.9564C17.3364 17.7972 17.0298 17.741 16.7028 17.741C15.691 17.741 14.8428 18.5183 14.8121 19.4455C14.8121 20.5225 13.8719 21.3653 12.6967 21.3653H11.3068C10.1214 21.3653 9.18116 20.5225 9.18116 19.4455C9.16072 18.5183 8.3125 17.741 7.30076 17.741C6.96351 17.741 6.65693 17.7972 6.40144 17.9564C6.07441 18.1437 5.66563 18.2373 5.31816 18.2373C4.58235 18.2373 3.8772 17.8627 3.49908 17.2634L2.79393 16.1395C2.4158 15.5308 2.39536 14.8003 2.77349 14.2197C2.937 13.9387 3.24359 13.6577 3.59106 13.4798C3.8772 13.3487 4.06116 13.1333 4.23489 12.8804C4.74587 12.075 4.43928 11.0167 3.57062 10.5391C2.55888 10.0053 2.23185 8.81591 2.81437 7.88875L3.49908 6.78366C4.09181 5.8565 5.35904 5.52871 6.381 6.0719C7.2701 6.52143 8.42491 6.22174 8.94611 5.4257C9.10962 5.16347 9.2016 4.88251 9.18116 4.60156C9.16072 4.23631 9.27314 3.8898 9.46731 3.60884C9.84543 3.0282 10.5301 2.65359 11.2762 2.63486H12.7171C13.4734 2.63486 14.1581 3.0282 14.5362 3.60884C14.7202 3.8898 14.8428 4.23631 14.8121 4.60156C14.7917 4.88251 14.8837 5.16347 15.0472 5.4257C15.5684 6.22174 16.7232 6.52143 17.6225 6.0719C18.6343 5.52871 19.9117 5.8565 20.4942 6.78366L21.1789 7.88875C21.7717 8.81591 21.4447 10.0053 20.4227 10.5391C19.554 11.0167 19.2474 12.075 19.7686 12.8804C19.9322 13.1333 20.1161 13.3487 20.4023 13.4798ZM9.10962 12.0095C9.10962 13.4798 10.4075 14.6505 12.012 14.6505C13.6165 14.6505 14.8837 13.4798 14.8837 12.0095C14.8837 10.5391 13.6165 9.3591 12.012 9.3591C10.4075 9.3591 9.10962 10.5391 9.10962 12.0095Z"
                                          fill="#130F26"/>
                                </svg>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className=" dropdown-menu dropdown-menu-end">
                                <PerfectScrollbar
                                    className="widget-timeline dlab-scroll style-1 ps p-3 ps--active-y height370"
                                    id="DZ_W_TimeLine02">
                                    <h4 className="text-center border-bottom pb-2">{t('notifications')}</h4>
                                    <ul className="timeline">
                                        <li>
                                            <div className="timeline-badge primary"/>
                                            <Link className="timeline-panel c-pointer text-muted"
                                                  to="#">
                                                <span>10 minutes ago</span>
                                                <h6 className="mb-0"> Youtube, a video-sharing website,
                                                    goes live{" "} <strong
                                                        className="text-primary">$500</strong>.</h6>
                                            </Link>
                                        </li>
                                        <li>
                                            <div className="timeline-badge info"></div>
                                            <Link className="timeline-panel c-pointer text-muted"
                                                  to="#">
                                                <span>20 minutes ago</span>
                                                <h6 className="mb-0">
                                                    New order placed{" "}
                                                    <strong className="text-info">#XF-2356.</strong>
                                                </h6>
                                                <p className="mb-0"> Quisque a consequat ante Sit amet
                                                    magna at volutapt...</p>
                                            </Link>
                                        </li>
                                        <li>
                                            <div className="timeline-badge danger"></div>
                                            <Link className="timeline-panel c-pointer text-muted"
                                                  to="#">
                                                <span>30 minutes ago</span>
                                                <h6 className="mb-0">
                                                    john just buy your product{" "}
                                                    <strong className="text-warning">Sell $250</strong>
                                                </h6>
                                            </Link>
                                        </li>
                                        <li>
                                            <div className="timeline-badge success"></div>
                                            <Link className="timeline-panel c-pointer text-muted"
                                                  to="#">
                                                <span>15 minutes ago</span>
                                                <h6 className="mb-0">
                                                    StumbleUpon is acquired by eBay.{" "}
                                                </h6>
                                            </Link>
                                        </li>
                                        <li>
                                            <div className="timeline-badge warning"></div>
                                            <Link className="timeline-panel c-pointer text-muted"
                                                  to="#">
                                                <span>20 minutes ago</span>
                                                <h6 className="mb-0">
                                                    Mashable, a news website and blog, goes live.
                                                </h6>
                                            </Link>
                                        </li>
                                        <li>
                                            <div className="timeline-badge dark"></div>
                                            <Link className="timeline-panel c-pointer text-muted"
                                                  to="#">
                                                <span>20 minutes ago</span>
                                                <h6 className="mb-0">Mashable, a news website and blog,
                                                    goes live.</h6>
                                            </Link>
                                        </li>
                                        <li>
                                            <div className="timeline-badge primary"/>
                                            <Link className="timeline-panel c-pointer text-muted"
                                                  to="#">
                                                <span>10 minutes ago</span>
                                                <h6 className="mb-0"> Youtube, a video-sharing website,
                                                    goes live{" "} <strong
                                                        className="text-primary">$500</strong>.</h6>
                                            </Link>
                                        </li>
                                        <li>
                                            <div className="timeline-badge info"></div>
                                            <Link className="timeline-panel c-pointer text-muted"
                                                  to="#">
                                                <span>20 minutes ago</span>
                                                <h6 className="mb-0">
                                                    New order placed{" "}
                                                    <strong className="text-info">#XF-2356.</strong>
                                                </h6>
                                                <p className="mb-0"> Quisque a consequat ante Sit amet
                                                    magna at volutapt...</p>
                                            </Link>
                                        </li>
                                        <li>
                                            <div className="timeline-badge danger"></div>
                                            <Link className="timeline-panel c-pointer text-muted"
                                                  to="#">
                                                <span>30 minutes ago</span>
                                                <h6 className="mb-0">
                                                    john just buy your product{" "}
                                                    <strong className="text-warning">Sell $250</strong>
                                                </h6>
                                            </Link>
                                        </li>
                                        <li>
                                            <div className="timeline-badge success"></div>
                                            <Link className="timeline-panel c-pointer text-muted"
                                                  to="#">
                                                <span>15 minutes ago</span>
                                                <h6 className="mb-0">
                                                    StumbleUpon is acquired by eBay.{" "}
                                                </h6>
                                            </Link>
                                        </li>
                                    </ul>
                                    <div className="ps__rail-x" style={{left: 0, bottom: 0}}>
                                        <div className="ps__thumb-x" tabIndex={0}
                                             style={{left: 0, width: 0}}/>
                                    </div>
                                    <div className="ps__rail-y" style={{top: 0, right: 0}}>
                                        <div className="ps__thumb-y" tabIndex={0}
                                             style={{top: 0, height: 0}}/>
                                    </div>
                                </PerfectScrollbar>
                            </Dropdown.Menu>
                        </Dropdown>}
                        {
                            can("chat") ||
                            can("notes") ||
                            can("alerts") ?
                                <Dropdown as="li" className="nav-item dropdown notification_dropdown ">
                                    <Dropdown.Toggle variant="" as="a"
                                                     className="nav-link  text-white   i-false c-pointer"
                                                     onClick={() => onNote()}>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M16.9394 3.57129C18.2804 3.57129 19.5704 4.06765 20.5194 4.95828C21.4694 5.84704 22.0004 7.04579 22.0004 8.30073V15.6993C22.0004 18.3122 19.7304 20.4287 16.9394 20.4287H7.0604C4.2694 20.4287 2.0004 18.3122 2.0004 15.6993V8.30073C2.0004 5.68783 4.2594 3.57129 7.0604 3.57129H16.9394ZM18.5304 9.69615L18.6104 9.62123C18.8494 9.34964 18.8494 8.9563 18.5994 8.68471C18.4604 8.54517 18.2694 8.45994 18.0704 8.44121C17.8604 8.43091 17.6604 8.4974 17.5094 8.62852L13.0004 12C12.4204 12.4505 11.5894 12.4505 11.0004 12L6.5004 8.62852C6.1894 8.41312 5.7594 8.44121 5.5004 8.69407C5.2304 8.94693 5.2004 9.34964 5.4294 9.6306L5.5604 9.75234L10.1104 13.077C10.6704 13.4891 11.3494 13.7138 12.0604 13.7138C12.7694 13.7138 13.4604 13.4891 14.0194 13.077L18.5304 9.69615Z"
                                                  fill="#130F26"/>
                                        </svg>
                                    </Dropdown.Toggle>
                                </Dropdown> : null
                        }

                        {
                            can("notificationList") &&
                            <Dropdown as="li" className="nav-item dropdown notification_dropdown">
                                <Dropdown.Toggle className="nav-link text-white  i-false c-pointer" variant="" as="a"
                                                 style={{zIndex: 10}}>
                                <span className={"badge badge-danger badge-sm"}
                                      style={{
                                          position: 'absolute',
                                          top: -11,
                                          left: -8
                                      }}>{count}</span>
                                    <svg width="24" height="23" viewBox="0 0 24 23" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M18.7071 8.56414C18.7071 9.74035 19.039 10.4336 19.7695 11.2325C20.3231 11.8211 20.5 12.5766 20.5 13.3963C20.5 14.215 20.2128 14.9923 19.6373 15.6233C18.884 16.3798 17.8215 16.8627 16.7372 16.9466C15.1659 17.0721 13.5937 17.1777 12.0005 17.1777C10.4063 17.1777 8.83505 17.1145 7.26375 16.9466C6.17846 16.8627 5.11602 16.3798 4.36367 15.6233C3.78822 14.9923 3.5 14.215 3.5 13.3963C3.5 12.5766 3.6779 11.8211 4.23049 11.2325C4.98384 10.4336 5.29392 9.74035 5.29392 8.56414V8.16515C5.29392 6.58996 5.71333 5.55995 6.577 4.55164C7.86106 3.08114 9.91935 2.19922 11.9558 2.19922H12.0452C14.1254 2.19922 16.2502 3.12359 17.5125 4.65728C18.3314 5.64484 18.7071 6.63146 18.7071 8.16515V8.56414ZM9.07367 19.1136C9.07367 18.642 9.53582 18.426 9.96318 18.3336C10.4631 18.2345 13.5093 18.2345 14.0092 18.3336C14.4366 18.426 14.8987 18.642 14.8987 19.1136C14.8738 19.5626 14.5926 19.9606 14.204 20.2134C13.7001 20.5813 13.1088 20.8143 12.4906 20.8982C12.1487 20.9397 11.8128 20.9407 11.4828 20.8982C10.8636 20.8143 10.2723 20.5813 9.76938 20.2125C9.37978 19.9606 9.09852 19.5626 9.07367 19.1136Z"
                                              fill="#130F26"/>
                                    </svg>

                                </Dropdown.Toggle>
                                <Dropdown.Menu align="right"
                                               className="mt-2 dropdown-menu dropdown-menu-end">
                                    <PerfectScrollbar className="widget-media dlab-scroll p-3 height380">
                                        <ul className="timeline">
                                            {
                                                listOfNotifications.length > 0 ?
                                                    listOfNotifications.map((n, i) => <Notification
                                                        t={t}
                                                        notify={n}
                                                        key={"listOfNotifications_" + i}
                                                        ext={ext}
                                                        classChange={'media-' + styles[randomIntFromInterval(1, 4)]}/>)

                                                    : <div
                                                        className={"mt-4 pt-4 text-center"}>{t("no_notifications")}</div>
                                            }


                                        </ul>
                                        <div className="ps__rail-x" style={{left: 0, bottom: 0}}>
                                            <div className="ps__thumb-x" tabIndex={0}
                                                 style={{left: 0, width: 0}}/>
                                        </div>
                                        <div className="ps__rail-y" style={{top: 0, right: 0}}>
                                            <div className="ps__thumb-y" tabIndex={0}
                                                 style={{top: 0, height: 0}}/>
                                        </div>
                                    </PerfectScrollbar>
                                    <Link className="all-notification" to="/activity">
                                        {t('see_all_notifications')} <i className="ti-arrow-right"/>
                                    </Link>
                                </Dropdown.Menu>
                            </Dropdown>
                        }
                    </ul>
                </div>
                <ul>
                    <Dropdown as="li" className="nav-item dropdown header-profile">
                        <Dropdown.Toggle variant="" as="a" className="nav-link text-white  i-false c-pointer">
                            {
                                user && user.photo ? <img src={env.s3 + user.photo} width={35} alt=""/>
                                    : <img src={userImage} width={35} alt="" style={
                                        {
                                            backgroundColor: "#2F5A78"
                                        }}/>
                            }

                        </Dropdown.Toggle>
                        <Dropdown.Menu align="right" className="dropdown-menu dropdown-menu-end">
                            {
                                can('/profile') && <Link to="/profile" className="dropdown-item ai-icon">
                                    <svg id="icon-user1" xmlns="http://www.w3.org/2000/svg"
                                         className="text-primary me-1" width={18} height={18}
                                         viewBox="0 0 24 24" fill="none"
                                         stroke="currentColor" strokeWidth={2} strokeLinecap="round"
                                         strokeLinejoin="round"
                                    >
                                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"/>
                                        <circle cx={12} cy={7} r={4}/>
                                    </svg>
                                    <span className="ms-2">{t('profile')} </span>
                                </Link>
                            }
                            {
                                can("/email-inbox") && <Link to="/email-inbox" className="dropdown-item ai-icon">
                                    <svg
                                        id="icon-inbox" xmlns="http://www.w3.org/2000/svg"
                                        className="text-success me-1" width={18}
                                        height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round" strokeLinejoin="round"
                                    >
                                        <path
                                            d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"/>
                                        <polyline points="22,6 12,13 2,6"/>
                                    </svg>
                                    <span className="ms-2">{t('inbox')} </span>
                                </Link>
                            }
                            {
                                can("/reset_cache") &&
                                <Link to="#" className="dropdown-item ai-icon" onClick={resetCache}>
                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20"
                                         viewBox="0 0 64 64">
                                        <path fill="#ed7899"
                                              d="M48.154,36.359c-2.436,3.846-7.692,6.538-12.051,6.538c0,0,0,0-0.128,0l0,0	c-6.026,0-10.897-4.872-10.897-10.897c3.59-2.179,2.692-8.205,6.41-9.872c1.41-0.641,2.949-1.026,4.487-1.026l0,0c0,0,0,0,0.128,0	c4.359,0,9.487,2.564,11.923,6.282l8.718-9.487C52.128,11.359,44.564,7,36.103,7c-13.846,0-25,11.154-25,25s11.154,25,25,25	c8.846,0,16.667-4.615,21.026-11.667L48.154,36.359z"></path>
                                        <path fill="#f283a5"
                                              d="M57.13,45.33C52.77,52.38,44.95,57,36.1,57c-13.84,0-25-11.15-25-25c0-13.85,11.16-25,25-25	c8.46,0,16.03,4.36,20.64,10.9l-0.33,0.358c-1.555,1.691-4.242,1.756-5.81,0.078c-3.763-4.026-9.176-6.493-15.165-6.328	c-10.301,0.284-18.87,8.628-19.407,18.919C15.426,42.465,24.594,52,36,52c6.006,0,11.395-2.647,15.056-6.839	c1.498-1.716,4.167-1.739,5.777-0.128L57.13,45.33z"></path>
                                        <path fill="#8d6c9f"
                                              d="M41.764,15.188c-0.1,0-0.202-0.015-0.302-0.047c-0.527-0.166-0.819-0.728-0.652-1.255l0.603-1.907 c0.167-0.526,0.73-0.82,1.255-0.651c0.527,0.166,0.819,0.728,0.652,1.255l-0.603,1.907 C42.582,14.915,42.188,15.188,41.764,15.188z"></path>
                                        <path fill="#8d6c9f"
                                              d="M45.86,16.96c-0.168,0-0.339-0.043-0.496-0.133c-0.479-0.274-0.645-0.886-0.371-1.364l0.995-1.735 c0.275-0.479,0.886-0.645,1.365-0.37c0.479,0.274,0.645,0.886,0.371,1.364l-0.995,1.735C46.543,16.779,46.207,16.96,45.86,16.96z"></path>
                                        <path fill="#8d6c9f"
                                              d="M49.442,19.604c-0.243,0-0.486-0.088-0.678-0.266c-0.406-0.375-0.431-1.008-0.056-1.413 l1.357-1.469c0.376-0.404,1.007-0.43,1.413-0.056c0.406,0.375,0.431,1.008,0.056,1.413l-1.357,1.469 C49.98,19.495,49.712,19.604,49.442,19.604z"></path>
                                        <path fill="#8d6c9f"
                                              d="M36.103,58c-14.336,0-26-11.663-26-26s11.664-26,26-26c8.433,0,16.454,4.232,21.458,11.32 c0.271,0.385,0.238,0.907-0.081,1.254l-8.718,9.487c-0.208,0.227-0.508,0.341-0.818,0.32c-0.307-0.025-0.586-0.191-0.755-0.449 c-2.178-3.323-6.944-5.83-11.087-5.83h-0.128c-1.317,0-2.726,0.323-4.073,0.936c-1.517,0.681-2.1,2.364-2.775,4.313 c-0.632,1.826-1.342,3.875-3.036,5.166c0.27,5.218,4.6,9.38,9.884,9.38h0.128c4.196,0,9.013-2.611,11.207-6.073 c0.163-0.257,0.433-0.425,0.734-0.459c0.303-0.03,0.603,0.072,0.817,0.287l8.975,8.974c0.328,0.328,0.388,0.838,0.144,1.232 C53.279,53.461,45.101,58,36.103,58z M36.103,8c-13.234,0-24,10.767-24,24s10.766,24,24,24c8.035,0,15.359-3.921,19.75-10.529 l-7.57-7.569c-2.76,3.481-7.771,5.996-12.179,5.996h-0.128c-6.56,0-11.897-5.337-11.897-11.897c0-0.35,0.182-0.674,0.481-0.854 c1.44-0.875,2.042-2.61,2.678-4.448c0.758-2.188,1.541-4.45,3.842-5.481c1.601-0.728,3.296-1.113,4.896-1.113h0.128 c4.403,0,9.246,2.348,12.007,5.713l7.343-7.991C50.8,11.657,43.626,8,36.103,8z"></path>
                                        <path fill="#7bbeeb"
                                              d="M37.897,11.359c0-1.026-0.769-1.795-1.795-1.795c-0.769,0-1.41,0.513-1.667,1.282l-8.974,19.231 l2.564,5.128c0,0,9.487-22.564,9.615-23.077l0,0C37.769,11.872,37.897,11.615,37.897,11.359z"></path>
                                        <path fill="#8d6c9f"
                                              d="M28.026,36.205c-0.378,0-0.725-0.213-0.895-0.553l-2.564-5.128c-0.136-0.273-0.141-0.594-0.012-0.87 l8.953-19.185c0.412-1.159,1.424-1.905,2.595-1.905c1.567,0,2.795,1.228,2.795,2.795c0,0.479-0.189,0.87-0.348,1.188 c-0.657,1.743-6.225,15.014-9.602,23.045c-0.151,0.36-0.499,0.599-0.889,0.611C28.048,36.205,28.037,36.205,28.026,36.205z M26.572,30.063l1.375,2.749c3.668-8.731,8.513-20.309,8.738-20.977c0.015-0.043,0.041-0.113,0.062-0.154 c0.056-0.112,0.141-0.282,0.152-0.345c-0.001-0.452-0.321-0.771-0.796-0.771c-0.325,0-0.593,0.224-0.718,0.598 c-0.012,0.036-0.026,0.072-0.042,0.106L26.572,30.063z"></path>
                                        <path fill="#85cbf8"
                                              d="M17.385,31.359l10.256,6.41l6.584,1.139l1.183-7.218c0.385-0.641-4.946-1.998-5.587-2.254	l-7.564-2.949c-0.513-0.256-1.154,0-1.538,0.385L17.385,31.359z"></path>
                                        <path fill="#7bbeeb"
                                              d="M33.633,38.435l-4-0.8c-1.105-0.221-1.809-1.311-1.557-2.409l0.745-3.245	c0.268-1.168,1.497-1.84,2.625-1.435l2.573,0.923c0.602,0.241,0.963,0.722,0.842,1.324L33.633,38.435z"></path>
                                        <path fill="#f9dd8f"
                                              d="M27.641,37.769l-10.256-6.41c0,0-7.051,10.256-15.385,10.256c0,0,0.256,4.359,5.385,8.846	c1.154-0.128,2.564-0.385,3.59-1.154c0,0-0.769,1.41-1.795,2.564c1.282,0.897,3.123,2.361,4.359,3.128h10.256	c0,0,7.436-4.282,10.256-15.949L27.641,37.769z"></path>
                                        <path fill="#8d6c9f"
                                              d="M48.848,49.522c-0.298,0-0.593-0.133-0.79-0.386l-1.229-1.579 c-0.339-0.437-0.261-1.064,0.175-1.403c0.436-0.341,1.065-0.261,1.403,0.175l1.229,1.579c0.339,0.437,0.261,1.064-0.175,1.403 C49.279,49.454,49.063,49.522,48.848,49.522z"></path>
                                        <path fill="#8d6c9f"
                                              d="M44.371,52.279c-0.38,0-0.744-0.218-0.911-0.587l-0.827-1.82 c-0.228-0.503-0.006-1.096,0.497-1.323c0.503-0.231,1.096-0.006,1.324,0.497l0.827,1.82c0.228,0.503,0.006,1.096-0.497,1.323 C44.65,52.251,44.509,52.279,44.371,52.279z"></path>
                                        <path fill="#8d6c9f"
                                              d="M39.276,53.756c-0.49,0-0.919-0.361-0.989-0.861l-0.278-1.98 c-0.077-0.547,0.304-1.053,0.851-1.129c0.548-0.08,1.053,0.305,1.129,0.852l0.278,1.98c0.077,0.547-0.304,1.053-0.851,1.129 C39.37,53.753,39.323,53.756,39.276,53.756z"></path>
                                        <path fill="#8d6c9f"
                                              d="M34.014,53.811c-0.035,0-0.071-0.002-0.106-0.006c-0.549-0.058-0.947-0.551-0.889-1.1l0.211-1.989 c0.059-0.55,0.56-0.944,1.1-0.889c0.549,0.058,0.947,0.551,0.889,1.1l-0.211,1.989C34.953,53.43,34.519,53.811,34.014,53.811z"></path>
                                        <path fill="#8d6c9f"
                                              d="M18.211,26.423c-0.12,0-0.241-0.021-0.359-0.066l-1.867-0.719 c-0.515-0.199-0.772-0.777-0.574-1.293c0.198-0.516,0.778-0.771,1.292-0.574l1.867,0.719c0.515,0.199,0.772,0.777,0.574,1.293 C18.991,26.18,18.612,26.423,18.211,26.423z"></path>
                                        <path fill="#8d6c9f"
                                              d="M20.486,22.178c-0.197,0-0.397-0.059-0.571-0.18l-1.641-1.145 c-0.453-0.316-0.564-0.939-0.248-1.393c0.316-0.451,0.94-0.563,1.393-0.248l1.641,1.145c0.453,0.316,0.564,0.939,0.248,1.393 C21.113,22.028,20.802,22.178,20.486,22.178z"></path>
                                        <path fill="#8d6c9f"
                                              d="M23.686,18.573c-0.277,0-0.553-0.114-0.751-0.339l-1.322-1.501 c-0.365-0.415-0.325-1.047,0.09-1.412c0.414-0.365,1.046-0.325,1.411,0.09l1.322,1.501c0.365,0.415,0.325,1.047-0.09,1.411 C24.156,18.491,23.92,18.573,23.686,18.573z"></path>
                                        <path fill="#8d6c9f"
                                              d="M27.663,15.915c-0.371,0-0.728-0.207-0.9-0.563l-0.872-1.8c-0.241-0.497-0.033-1.096,0.464-1.337 c0.498-0.237,1.095-0.033,1.336,0.464l0.872,1.8c0.241,0.497,0.033,1.096-0.464,1.337C27.958,15.883,27.81,15.915,27.663,15.915z"></path>
                                        <g>
                                            <path fill="#8d6c9f"
                                                  d="M55.182,29.226c-0.365,0-0.716-0.199-0.893-0.547c-0.25-0.492-0.054-1.095,0.438-1.345 l1.783-0.906c0.493-0.247,1.094-0.054,1.345,0.438c0.25,0.492,0.054,1.095-0.438,1.345l-1.783,0.906 C55.489,29.19,55.334,29.226,55.182,29.226z"></path>
                                            <path fill="#8d6c9f"
                                                  d="M58.043,32.99c-0.007,0-0.014,0-0.021,0l-2-0.041c-0.552-0.012-0.991-0.469-0.979-1.021 c0.012-0.552,0.452-1.004,1.021-0.979l2,0.041c0.552,0.012,0.991,0.469,0.979,1.021C59.032,32.556,58.586,32.99,58.043,32.99z"></path>
                                            <path fill="#8d6c9f"
                                                  d="M56.871,37.598c-0.149,0-0.301-0.033-0.444-0.104l-1.792-0.89 c-0.495-0.245-0.696-0.846-0.451-1.34c0.245-0.496,0.844-0.698,1.34-0.451l1.792,0.89c0.495,0.245,0.696,0.846,0.451,1.34 C57.593,37.395,57.239,37.598,56.871,37.598z"></path>
                                        </g>
                                        <path fill="#efc88e"
                                              d="M26.244,40.189c-2.407,3.867-5.594,7.663-9.258,11.417C15.75,52.871,16.645,55,18.414,55h5.381	c0,0,7.436-4.282,10.256-15.949l-3.599-0.72C28.798,38.001,27.136,38.756,26.244,40.189z"></path>
                                        <g>
                                            <path fill="#8d6c9f"
                                                  d="M34.843,40.229l-7.577-1.515l-11.33-7.081l4.075-5.468c0.734-0.736,1.864-0.987,2.693-0.572 l6.863,2.671c0.456,0.127,1.634,0.523,5.198,1.767c1.174,0.468,1.766,1.463,1.548,2.55L34.843,40.229z M28.016,36.825l5.244,1.048 l1.092-5.677c0.022-0.112-0.075-0.208-0.286-0.293c-1.828-0.637-3.887-1.346-4.711-1.616l-0.042,0.024l-7.419-2.893 c-0.158-0.032-0.309,0.022-0.426,0.122l-2.633,3.545L28.016,36.825z"></path>
                                        </g>
                                        <g>
                                            <path fill="#8d6c9f"
                                                  d="M23.795,56H13.539c-0.145,0-0.288-0.031-0.419-0.092c-1.222-0.564-2.289-1.44-3.23-2.213 c-0.445-0.364-0.871-0.716-1.283-1.004c-0.233-0.164-0.386-0.42-0.419-0.703c-0.027-0.227,0.025-0.454,0.145-0.646 c-0.29,0.048-0.57,0.083-0.835,0.113c-0.278,0.028-0.558-0.057-0.77-0.241c-5.381-4.709-5.713-9.346-5.724-9.541 c-0.017-0.274,0.082-0.544,0.271-0.745c0.189-0.2,0.452-0.313,0.728-0.313c7.718,0,14.493-9.725,14.561-9.822 c0.303-0.44,0.9-0.566,1.354-0.281l10.102,6.313l6.231,1.245c0.267,0.053,0.5,0.213,0.647,0.442s0.193,0.509,0.129,0.773 c-2.888,11.946-10.411,16.396-10.729,16.58C24.142,55.954,23.97,56,23.795,56z M13.767,54h9.737 c1.156-0.771,6.758-4.904,9.314-14.176l-5.373-1.074c-0.119-0.023-0.232-0.068-0.334-0.133l-9.469-5.918 c-1.784,2.34-7.513,9.127-14.444,9.853c0.382,1.397,1.47,4.081,4.514,6.861c0.866-0.121,1.93-0.356,2.662-0.905 c0.376-0.283,0.899-0.264,1.254,0.044c0.356,0.308,0.449,0.822,0.224,1.235c-0.026,0.048-0.518,0.942-1.246,1.913 c0.181,0.146,0.364,0.296,0.551,0.449C11.99,52.833,12.85,53.538,13.767,54z"></path>
                                        </g>
                                        <g>
                                            <path fill="#8d6c9f"
                                                  d="M17.186,52.451c-0.294,0-0.585-0.129-0.782-0.377c-0.344-0.432-0.273-1.061,0.158-1.405 c0.542-0.432,1.135-0.964,1.763-1.583c0.394-0.387,1.027-0.383,1.414,0.012c0.388,0.394,0.383,1.026-0.011,1.414 c-0.678,0.667-1.324,1.246-1.919,1.722C17.625,52.38,17.405,52.451,17.186,52.451z M21.729,47.849 c-0.227,0-0.454-0.076-0.641-0.233c-0.424-0.354-0.479-0.984-0.125-1.408c1.653-1.975,3.111-4.049,3.903-5.549 c0.258-0.489,0.861-0.675,1.351-0.418c0.488,0.258,0.675,0.863,0.417,1.352c-0.866,1.641-2.374,3.791-4.137,5.898 C22.299,47.727,22.015,47.849,21.729,47.849z"></path>
                                        </g>
                                        <g>
                                            <path fill="#8d6c9f"
                                                  d="M6.45,35.656c-0.513,0-0.949-0.392-0.995-0.911C5.374,33.839,5.333,32.916,5.333,32 c0-8.862,3.833-17.289,10.518-23.119c0.416-0.362,1.048-0.319,1.411,0.097c0.363,0.416,0.32,1.048-0.096,1.411 C10.917,15.839,7.333,23.716,7.333,32c0,0.856,0.038,1.721,0.113,2.568c0.049,0.55-0.357,1.035-0.908,1.084 C6.509,35.655,6.479,35.656,6.45,35.656z M19.69,8.216c-0.325,0-0.644-0.158-0.836-0.449c-0.304-0.462-0.177-1.082,0.285-1.386 c0.857-0.564,1.754-1.094,2.668-1.572c0.49-0.258,1.093-0.066,1.35,0.422c0.256,0.489,0.067,1.094-0.422,1.35 c-0.854,0.447-1.694,0.942-2.496,1.471C20.07,8.162,19.879,8.216,19.69,8.216z"></path>
                                        </g>
                                    </svg>
                                    <span className="ms-2">{t('reset_cache')} </span>
                                </Link>
                            }
                            <LogoutPage/>
                        </Dropdown.Menu>
                    </Dropdown>

                </ul>

            </div>
        </>
    )
}
export default React.memo(RightSideBar);