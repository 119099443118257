import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import AppService from "../../services/AppService";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router";
import {getUserHandler} from "../../store/actions/UserAction";
import {Loader} from "../components/Crud/Loader";
import IsAuthenticated from "../../Middlewares/IsAuthenticated";
import _kc from "../../Util/keycloack";

const LockScreen = () => {
    const redirect = useNavigate()
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const isAuthenticated = IsAuthenticated();
    const lang = AppService.getLocalLanguage()
    const {getUserDetails: {data: userDetails, loading}} = useSelector(s => s)


    useEffect(() => {
        if (!isAuthenticated) {
            AppService.removeRefreshLanguage()
            _kc.init({onLoad: 'login-required'}).then(auth => console.log(auth))
                .catch(e => console.log(e))
        }
    }, [isAuthenticated])

    useEffect(() => {
        dispatch(getUserHandler())
    }, [dispatch]);

    const content = lang === "ar" ?
        (
            <div>
                نأسف لإبلاغك أنه تم قفل حسابك بسبب انتهاك
                الشروط والأحكام الخاصة بنا. قام فريقنا بمراجعة حسابك ووجد
                أنك انتهكت اتفاقية المستخدم الخاصة بنا بشكل كبير. <br/>
                <br/>
                نتيجة لذلك ، كان علينا اتخاذ التدابير اللازمة لحماية
                سلامة منصتنا والتأكد من أن مجتمعنا آمن ومأمون.
                تم قفل حسابك مؤقتًا ، ولن تتمكن من الوصول إليه
                أي ميزات أو خدمات حتى يتم حل المشكلة. <br/>
                <br/>
                نحن نتعامل مع تنفيذ البنود والشروط الخاصة بنا على محمل الجد ، ونحن
                نتوقع من جميع مستخدمينا الامتثال لإرشاداتنا. نحن نفهم أن هذا
                قد يكون موقفًا محبطًا لك ، ولكن يجب علينا الالتزام بمعايير
                منصتنا لصالح جميع المستخدمين. <br/>
                <br/>

                إذا كنت تعتقد أن هذا الإجراء قد تم اتخاذه عن طريق الخطأ ، أو إذا كان لديك أي إجراء
                أسئلة حول انتهاك الشروط والأحكام الخاصة بنا ، من فضلك لا تفعل ذلك
                تتردد في الاتصال بفريق الدعم لدينا. سنكون سعداء لتزويدك
                مزيد من المعلومات والمساعدة. <br/>
                <br/>

                شكرا لتفهمك في هذا الأمر. <br/>
                <br/>

                مع خالص التقدير ، <br/>

            </div>
        ) : (
            <div>
                We regret to inform you that your account has been locked due to a violation
                of our terms and conditions. Our team has reviewed your account and found
                that you have breached our user agreement in a significant way.<br/>
                <br/>
                As a result, we have had to take the necessary measures to protect the
                integrity of our platform and ensure that our community is safe and secure.
                Your account has been temporarily locked, and you will not be able to access
                any features or services until the issue has been resolved.<br/>
                <br/>

                We take the enforcement of our terms and conditions very seriously, and we
                expect all our users to comply with our guidelines. We understand that this
                may be a frustrating situation for you, but we must uphold the standards of
                our platform for the benefit of all users.<br/>
                <br/>

                If you believe that this action has been taken in error, or if you have any
                questions about the violation of our terms and conditions, please do not
                hesitate to contact our support team. We will be happy to provide you with
                further information and assistance.<br/>
                <br/>

                Thank you for your understanding in this matter.<br/>
                <br/>

                Sincerely, <br/>
            </div>
        )

    if (loading) return <Loader/>

    if (userDetails && !userDetails.isBlocked) {
        return setTimeout(() => redirect('/'), 1000)
    }

    return (
        <div className="authincation " style={{
            backgroundColor: "rgba(76,143,215,0.82)"
        }}>
            <div className="row justify-content-center  align-items-center ">
                <div className="col-1"></div>
                <div className="col-5 mt-4 ">
                    <div className="authincation-content mt-4 shadow" dir={lang === "ar" ? "rtl" : 'ltr'}>
                        <div className="row no-gutters">
                            <div className="col-12">
                                <div className="auth-form">
                                    <h4 className="text-center mb-4 ">{t("Account Locked")}</h4>
                                    <h6>{t('dear')} {t("the_user")},</h6>
                                    <br/>
                                    <br/>
                                    {content}
                                    <h6 className={"text-sm mt-2"}>{t("Mktabati Team.")}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LockScreen;
