import {CREATE_BANNER_FAILED, CREATE_BANNER_LOADING, CREATE_BANNER_SUCCESS} from "../Constants/Banner";
import {UPDATE_BANNER_FAILED, UPDATE_BANNER_LOADING, UPDATE_BANNER_SUCCESS} from "../Constants/Banner";
import {DELETE_BANNER_FAILED, DELETE_BANNER_LOADING, DELETE_BANNER_SUCCESS} from "../Constants/Banner";
import {LIST_BANNER_FAILED, LIST_BANNER_LOADING, LIST_BANNER_SUCCESS} from "../Constants/Banner";
import {SHOW_BANNER_FAILED, SHOW_BANNER_LOADING, SHOW_BANNER_SUCCESS} from "../Constants/Banner";
import {GLOBAL_REFRESH} from "../Constants/Refresh";
import {refresh} from "./index";


export const createBannerReducer = (state = {}, action) => {
    switch (action.type) {
        case CREATE_BANNER_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case CREATE_BANNER_LOADING :
            return {
                loading: true,
                errors: null,
                data: null
            }
        case CREATE_BANNER_FAILED :
            return {
                loading: false,
                data: null,
                errors: action.payload
            }
        case GLOBAL_REFRESH :
            return refresh()
        default :
            return state

    }
}

export const updateBannerReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_BANNER_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case UPDATE_BANNER_LOADING :
            return {
                loading: true,
                errors: null,
                data: action.payload
            }
        case UPDATE_BANNER_FAILED :
            return {
                loading: false,
                data: state,
                errors: action.payload
            }
        case GLOBAL_REFRESH :
            return refresh()

        default :
            return state

    }
}


export const showBannerReducer = (state = {}, action) => {
    switch (action.type) {
        case SHOW_BANNER_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case SHOW_BANNER_LOADING :
            return {
                loading: true,
                errors: null,
                data: null
            }
        case SHOW_BANNER_FAILED :
            return {
                loading: false,
                data: null,
                errors: action.payload
            }

        default :
            return state

    }
}


export const deleteBannerReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_BANNER_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case DELETE_BANNER_LOADING :
            return {
                loading: true,
                errors: null,
                data: action.payload
            }
        case DELETE_BANNER_FAILED :
            return {
                loading: false,
                data: state,
                errors: action.payload
            }

        default :
            return state

    }
}

export const listBannerReducer = (state = {}, action) => {
    switch (action.type) {
        case LIST_BANNER_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case LIST_BANNER_LOADING :
            return {
                loading: true,
                errors: null,
                data: []
            }
        case LIST_BANNER_FAILED :
            return {
                loading: false,
                data: [],
                errors: action.payload
            }

        default :
            return state

    }
}






