export const
    CREATE_MENU_FAILED = "CREATE_MENU_FAILED",
    CREATE_MENU_LOADING = "CREATE_MENU_LOADING",
    CREATE_MENU_SUCCESS = "CREATE_MENU_SUCCESS",
    UPDATE_MENU_FAILED = "UPDATE_MENU_FAILED",
    UPDATE_MENU_LOADING = "UPDATE_MENU_LOADING",
    UPDATE_MENU_SUCCESS = "UPDATE_MENU_SUCCESS",
    DELETE_MENU_FAILED = "DELETE_MENU_FAILED",
    DELETE_MENU_LOADING = "DELETE_MENU_LOADING",
    DELETE_MENU_SUCCESS = "DELETE_MENU_SUCCESS",
    LIST_MENU_FAILED = "LIST_MENU_FAILED",
    LIST_MENU_LOADING = "LIST_MENU_LOADING",
    LIST_MENU_SUCCESS = "LIST_MENU_SUCCESS",
    SHOW_MENU_LOADING = "SHOW_MENU_LOADING",
    SHOW_MENU_FAILED = "SHOW_MENU_FAILED",
    SHOW_MENU_SUCCESS = "SHOW_MENU_SUCCESS"