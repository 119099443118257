import i18next from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import AppService from "./AppService";

const {ar: AR, en: EN} = AppService.getLanguage() || {ar: {} , en: {}}
export const en = EN
export const ar = AR
const lang = localStorage.getItem("lang") ? localStorage.getItem("lang") : localStorage.setItem("lang", "en")

// "Inline" English and Arabic translations.
// We can localize to any language and any number of languages.
const resources = {
    en: {
        translation: en,
    },
    ar: {
        translation: ar,
    },
};



i18next
    .use(initReactI18next)
    .use(LanguageDetector)

    .init({
        resources,
        // backend: backendOptions,
        lng: lang || 'en',
        // ns: ['en', 'ar'],
        // defaultNS: lang,
        interpolation: {
            escapeValue: false,
            // value == today from {{today, date}} == new Date()
            // format == date from {{today, date}} == "date"
            // lng == active language
            format: function (value, format, lng) {
                if (format === "date") {
                    return new Intl.DateTimeFormat(lng).format(value);
                }
                return value;
            },
        },
        supportedLngs: ["en", "ar", "dev"],
        fallbackLng: "en",
        debug: false, // process.env.NODE_ENV !== 'production'

    }).then(r => r).catch(e => console.warn("[TRANSLATION ERRORS]: " + e.message));


export default i18next;