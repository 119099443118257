import {
    LIST_COUNTRIES_FAILED,
    LIST_COUNTRIES_LOADING, LIST_COUNTRIES_SUCCESS,
    LIST_COUNTRY_FAILED, LIST_COUNTRY_LOADING,
    LIST_COUNTRY_SUCCESS
} from "../Constants/Country";
import {getCountries, getDefaultCountry} from "../../services/CountryService";
import {handlePayloadErrors} from "../../Util/ErrorsHandler";

const failedToPostMessageData = "failed to %s country data"


export const getCountriesHandler = (onlyAvailable = false) => async (dispatch) => {
    try {
        dispatch({type: LIST_COUNTRIES_LOADING})

        const {data} = await getCountries(onlyAvailable)

        if (data && !data.success) {
            return dispatch({
                type: LIST_COUNTRIES_FAILED,
                payload: failedToPostMessageData.replace('%s', "get")
            })
        }

        dispatch({
            type: LIST_COUNTRIES_SUCCESS, payload: data?.data
        })
    } catch (e) {
        dispatch({
            type: LIST_COUNTRIES_FAILED, payload: handlePayloadErrors(e)
        })
    }
}


export const getCountryHandler = () => async (dispatch) => {
    try {
        dispatch({type: LIST_COUNTRY_LOADING})

        const {data} = await getDefaultCountry()

        if (data && !data.success) {
            return dispatch({
                type: LIST_COUNTRY_FAILED,
                payload: failedToPostMessageData.replace('%s', "get")
            })
        }

        dispatch({
            type: LIST_COUNTRY_SUCCESS, payload: data?.data
        })
    } catch (e) {
        dispatch({
            type: LIST_COUNTRY_FAILED, payload: handlePayloadErrors(e)
        })
    }
}