import {
    ORDERS_FAILED, ORDERS_LOADING, ORDERS_SUCCESS
    , ATTACHMENT_SUCCESS,
    ATTACHMENT_LOADING,
    ATTACHMENT_FAILED
} from "../Constants/Order";


export const ordersReducer = (state = {data: []}, action) => {
    switch (action.type) {
        case ORDERS_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case ORDERS_LOADING :
            return {
                loading: true,
                errors: null,
                data: state?.data?.length ? state.data : []
            }
        case ORDERS_FAILED :
            return {
                loading: false,
                data: state?.data?.length ? state.data : [],
                errors: action.payload
            }

        default :
            return state

    }
}

const initialState = {
    isProceeded: false,
    proceedingData: false,
    proceedingFailed: false,
}


export const orderAttachmentReducer = (state = initialState, action) => {
    switch (action.type) {
        case ATTACHMENT_SUCCESS :
            return {
                loading: false,
                errors: null,
                isProceeded: true,
                proceedingData: action.payload,
                proceedingFailed: false
            }
        case ATTACHMENT_LOADING :
            return {
                loading: true,
                errors: null,
                isProceeded: false

            }
        case ATTACHMENT_FAILED :
            return {
                loading: false,
                errors: action.payload,
                isProceeded: false,
                proceedingFailed: true,
            }

        default :
            return state

    }
}


