import {decrypt, encrypt} from "../Util/Crypto";
import api from "./AxiosInstance";

const getAppSetting = () => window.localStorage.getItem('appSetting') && JSON.parse(decrypt(localStorage.getItem('appSetting')))

const setAppSetting = (app) => window.localStorage.setItem("appSetting", encrypt(JSON.stringify(app)));

const hasAppSetting = () => window.localStorage.getItem('appSetting') ? 1 : 0

const getLanguage = () => window.localStorage.getItem('appLanguage') && JSON.parse(decrypt(localStorage.getItem('appLanguage')))

const setLanguage = (lang) => window.localStorage.setItem("appLanguage", encrypt(JSON.stringify(lang)));

const hasLanguage = () => window.localStorage.getItem('appLanguage') ? 1 : 0
const removeLanguage = () => window.localStorage.removeItem('appLanguage')
const removeRefreshLanguage = () => window.localStorage.removeItem('refreshLanguage')

const setRefreshLanguage = () => window.localStorage.setItem('refreshLanguage', 'refresh')
const setRefreshedLanguage = () => window.localStorage.setItem('refreshLanguage', 'refreshed')
const canRefreshLanguage = () => !window.localStorage.getItem('refreshLanguage') || window.localStorage.getItem('refreshLanguage') === 'refresh'

const getLocalLanguage = () => window.localStorage.getItem('lang') || 'en'
const createAppSettingIfNotExist = (app) => {

    if (hasAppSetting()) return getAppSetting()

    window.localStorage.setItem("appSetting", encrypt(JSON.stringify(app)))

    return getAppSetting()

}

function updateLanguage(data) {
    return api.post(`/api/v1/language-update`, data);
}

function deleteLanguage(data) {
    return api.post(`/api/v1/language-delete`, data);
}

export function resetCache() {
    return api.post(`/api/v1/reset-cache`);
}

const AppService = {
    getAppSetting,
    setAppSetting,
    hasAppSetting,
    createAppSettingIfNotExist,
    getLanguage,
    getLocalLanguage,
    setLanguage,
    hasLanguage,
    removeLanguage,
    removeRefreshLanguage,
    setRefreshLanguage,
    setRefreshedLanguage,
    canRefreshLanguage,
    updateLanguage,
    deleteLanguage
}

export default AppService