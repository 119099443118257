import {
    LIST_NOTIFICATION_FAILED,
    LIST_NOTIFICATION_SUCCESS,
    LIST_NOTIFICATION_NOTIFIED,
    LIST_NOTIFICATIONS_FAILED,
    LIST_NOTIFICATIONS_LOADING,
    LIST_NOTIFICATIONS_SUCCESS,
} from "../Constants/Notification";

import {handlePayloadErrors} from "../../Util/ErrorsHandler";
import {toast as notify} from "react-toastify";
import {getAllNotifications, markAllAsRead, markAsRead} from "../../services/NotificationService";


export const getAllNotificationsHandler = (id = false, all = false) => async (dispatch) => {
    try {


        dispatch({type: LIST_NOTIFICATIONS_LOADING})

        let data
        if (id) {
            const {data: res} = await markAsRead(id)
            data = res

        } else if (all) {
            const {data: res} = await markAllAsRead()
            data = res
        } else {
            const {data: res} = await getAllNotifications()
            data = res
        }



        dispatch({
            type: LIST_NOTIFICATIONS_SUCCESS, payload: data?.data
        })

    } catch (e) {

        dispatch({
            type: LIST_NOTIFICATIONS_FAILED, payload: handlePayloadErrors(e)
        })
    }
}


export const getNotificationsHandler = (data) => (dispatch, getState) => {
    try {

        const {notifications} = getState()


        // dispatch({type: LIST_NOTIFICATION_LOADING})

        if (data?.count !== notifications.data.count) {
            dispatch({
                type: LIST_NOTIFICATION_SUCCESS, payload: data, toast: true
            })
        }

    } catch (e) {
        dispatch({
            type: LIST_NOTIFICATION_FAILED, payload: handlePayloadErrors(e)
        })
    }
}


export const fireToastNotificationHandler = (t) => dispatch => {


    notify.dismiss()
    notify.info(t('new_notifications_detected'), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });

    dispatch({
        type: LIST_NOTIFICATION_NOTIFIED
    })

}





