import {subMenuOfSystemManagement} from "./MapNav";
/// Active menu

export const dashboard = [
        "",
        "dashboard-dark",
        "dashboard",
        "schedule",
        "instructors",
        "message",
        "activity",
        "profile",
        "task",
    ],
    librarySubscribedManagement = [
        'subscribed-libraries'
    ],
    courses = [
        "courses",
        "course-details-1",
        "course-details-2",
    ],
    user_requests = [
        "user-requests",
    ],
    instructor = [
        "instructor-dashboard",
        "instructor-courses",
        "instructor-schedule",
        "instructor-students",
        "instructor-resources",
        "instructor-transactions",
        "instructor-liveclass",
    ],
    accessManagement = [
        "blocks-list",
    ],
    app = [
        "app-profile",
        "post-details",
        "app-calender",
        "email-compose",
        "email-inbox",
        "email-read",
        "ecom-product-grid",
        "ecom-product-list",
        "ecom-product-order",
        "ecom-checkout",
        "ecom-invoice",
        "ecom-customers",
        "post-details",
        "ecom-product-detail",
    ],
    email = ["email-compose", "email-inbox", "email-read"],
    shop = [
        "ecom-product-grid",
        "ecom-product-list",
        "ecom-product-list",
        "ecom-product-order",
        "ecom-checkout",
        "ecom-invoice",
        "ecom-customers",
        "ecom-product-detail",
    ],
    charts = [
        "chart-rechart",
        "chart-flot",
        "chart-chartjs",
        "chart-chartist",
        "chart-sparkline",
        "chart-apexchart",
    ],
    bootstrap = [
        "ui-accordion",
        "ui-badge",
        "ui-alert",
        "ui-button",
        "ui-modal",
        "ui-button-group",
        "ui-list-group",
        "ui-card",
        "ui-carousel",
        "ui-dropdown",
        "ui-popover",
        "ui-progressbar",
        "ui-tab",
        "ui-typography",
        "ui-pagination",
        "ui-grid",
    ],
    plugins = [
        "uc-select2",
        "uc-nestable",
        "uc-sweetalert",
        "uc-toastr",
        "uc-noui-slider",
        "map-jqvmap",
        "uc-lightgallery",
    ],
    redux = [
        "redux-form",
        "redux-wizard",
        "todo",
    ],
    widget = ["widget-basic"],
    forms = [
        "form-element",
        "form-wizard",
        "form-editor-summernote",
        "form-pickers",
        "form-validation-jquery",
    ],
    table = ["table-bootstrap-basic", "table-datatable-basic"],
    pages = [
        "page-register",
        "page-login",
        "page-lock-screen",
        "page-error-400",
        "page-error-403",
        "page-error-404",
        "page-error-500",
        "page-error-503",
    ],
    error = [
        "page-error-400",
        "page-error-403",
        "page-error-404",
        "page-error-500",
        "page-error-503",
    ],
    orderManagement = [
        "orders-list",
        'generate-payment-link'
    ],
    fundManagement = [
        "temporaryfunds-list",
        "transactions-list"
    ],
    pspManagement = ["create-psp" , 'psps-list' , 'edit-psp'],
    payoutsManagement = ['payouts-list'],

    feeManagement = ["create-fee" , 'fees-list' , 'edit-fee'],

    management = [
        "menu",
        "library",
        "blog",
        "category",
        "event",
        "color",
        "hero",
        "banner",
        "permission",
        "role",
        "course",
        "contact",
        "subscribe",
        'process',
        'ip',

        ...[].concat.apply([], Object.values(subMenuOfSystemManagement))
    ];
