/**
 * @type {{}}
 */



export const dashboardMenu = [
    {
        name: 'dashboard_admin',
        path: "/dashboard"
    },
    {
        name: 'schedule',
        path: "/schedule"
    },
    {
        name: 'instructors',
        path: "/instructors"
    },
    {
        name: 'message',
        path: "/message"
    },
    {
        name: 'activity',
        path: "/activity"
    },
    {
        name: 'profile',
        path: "/profile"
    },

]


export const subMenuOfSystemManagement = {
    menu: [
        'menu-create',
        "menus-list",
        "menu-edit",
    ],
    library: [
        "libraries-list",
        "library-create",
        "library-edit",

    ],
    blog: [
        "blog-create",
        "blog-edit",
        "blogs-list",

    ],
    comment: [
        "comment-create",
        "comment-edit",
        "comments-list",

    ],
    category: [
        "category-create",
        "categories-list",
        "category-edit",
    ],
    event: [
        "event-create",
        "events-list",
        "event-edit",

    ],
    color: [
        "color-create",
        "colors-list",
        "color-edit",

    ],
    hero: [
        "hero-create",
        "heroes-list",
        "hero-edit",

    ],
    banner: [
        "banner-create",
        "banners-list",
        "banner-edit",
    ],
    permission: [
        "permission-create",
        "permission-edit",
        "permissions-list",

    ],
    role: [
        "role-create",
        "role-edit",
        "roles-list",

    ],
    course: [
        "courses-list",
        "course-create",
        "course-edit",

    ],
    faq: [
        "faqs-list",
        "faq-create",
        "faq-edit",
    ],
    sitemap: [
        "sitemaps-list",
        "sitemap-create",
        "sitemap-edit",
    ],
    user: [
        "users-list",
        // "user-create",
        // "user-edit",
    ],
    contact: [
        "contacts-list"
    ],
    subscribe: [
        "subscribers-list"
    ],
    process: [
        "processes-list"
    ],
    ip: [
        "ips-list"
    ],
    log: [
        "logs-list"
    ],
    language: [
        "languages-list"
    ],
}
export const systemManagement = {

    user: [
        {
            name: 'list',
            plural: "users",
            path: "/users-list"
        },
        {
            name: 'log',
            path: "/crud-logs/users"
        },
        // {
        //     name: 'create',
        //     path: "/user-create"
        // },
        // {
        //     name: 'edit',
        //     path: "/user-edit"
        // },
    ],
    menu: [
        {
            name: 'list',
            plural: "menus",
            path: "/menus-list"
        },
        {
            name: 'create',
            path: "/menu-create"
        },
        {
            name: 'edit',
            path: "/menu-edit"
        },
        {
            name: 'trans',
            path: "/translate"
        },
        {
            name: 'log',
            path: "/crud-logs/menus"
        },
    ],
    color: [
        {
            name: 'list',
            plural: "colors",
            path: "/colors-list"
        },
        {
            name: 'create',
            path: "/color-create"
        },
        {
            name: 'edit',
            path: "/color-edit"
        },
        {
            name: 'log',
            path: "/crud-logs/colors"
        },

    ],
    category: [
        {
            name: 'list',
            plural: "categories",
            path: "/categories-list"
        },
        {
            name: 'create',
            path: "/category-create"
        },
        {
            name: 'log',
            path: "/crud-logs/categories"
        },
        {
            name: 'edit',
            path: "/category-edit"
        },
        {
            name: 'trans',
            path: "/translate"
        },
    ],
    library: [
        {
            name: 'list',
            plural: "libraries",
            path: "/libraries-list"
        },
        {
            name: 'create',
            path: "/library-create"
        },
        {
            name: 'edit',
            path: "/library-edit"
        },
        {
            name: 'trans',
            path: "/translate"
        },
        {
            name: 'log',
            path: "/crud-logs/libraries"
        },
    ],

    course: [
        {
            name: 'list',
            plural: "courses",
            path: "/courses-list"
        },
        {
            name: 'create',
            path: "/course-create"
        },

        {
            name: 'edit',
            path: "/course-edit"
        },
        {
            name: 'trans',
            path: "/translate"
        },
        {
            name: 'log',
            path: "/crud-logs/courses"
        },

    ],
    faq: [
        {
            name: 'list',
            plural: "faqs",
            path: "/faqs-list"
        },
        {
            name: 'create',
            path: "/faq-create"
        },
        {
            name: 'edit',
            path: "/faq-edit"
        },
        {
            name: 'trans',
            path: "/translate"
        },
        {
            name: 'log',
            path: "/crud-logs/faqs"
        },
    ],
    sitemap: [
        {
            name: 'list',
            plural: "sitemaps",
            path: "/sitemaps-list"
        },
        {
            name: 'create',
            path: "/sitemap-create"
        },
        {
            name: 'edit',
            path: "/sitemap-edit"
        },
        {
            name: 'log',
            path: "/crud-logs/sitemaps"
        },
    ],
    blog: [

        {
            name: 'list',
            plural: "blogs",
            path: "/blogs-list"
        },
        {
            name: 'create',
            path: "/blog-create"
        },
        {
            name: 'edit',
            path: "/blog-edit"
        },
        {
            name: 'trans',
            path: "/translate"
        },
        {
            name: 'log',
            path: "/crud-logs/blogs"
        },
    ],

    comment: [
        {
            name: 'list',
            plural: "comments",
            path: "/comments-list"
        },
        {
            name: 'create',
            path: "/comment-create"
        },
        {
            name: 'edit',
            path: "/comment-edit"
        },
        {
            name: 'log',
            path: "/crud-logs/comments"
        },
    ],
    event: [
        {
            name: 'list',
            plural: "events",
            path: "/events-list"
        },
        {
            name: 'create',
            path: "/event-create"
        },
        {
            name: 'edit',
            path: "/event-edit"
        },
        {
            name: 'trans',
            path: "/translate"
        },
        {
            name: 'log',
            path: "/crud-logs/events"
        },
    ],

    hero: [
        {
            name: 'list',
            plural: "heroes",
            path: "/heroes-list"
        },
        {
            name: 'create',
            path: "/hero-create"
        },
        {
            name: 'edit',
            path: "/hero-edit"
        },
        {
            name: 'trans',
            path: "/translate"
        },
        {
            name: 'log',
            path: "/crud-logs/heros"
        },
    ],
    banner: [
        {
            name: 'list',
            plural: "banners",
            path: "/banners-list"
        },
        {
            name: 'create',
            path: "/banner-create"
        },
        {
            name: 'edit',
            path: "/banner-edit"
        },
        {
            name: 'trans',
            path: "/translate"
        },
        {
            name: 'log',
            path: "/crud-logs/banners"
        },
    ],

    permission: [
        {
            name: 'create',
            path: "/permission-create"
        },
        {
            name: 'log',
            path: "/crud-logs/permissions"
        },
    ],
    role: [
        {
            name: 'create',
            path: "/role-create"
        },
        {
            name: 'log',
            path: "/crud-logs/roles"
        },
    ],

    contact: [
        {
            name: 'list',
            plural: "contacts",
            path: "/contacts-list"
        },
    ],
    subscribe: [
        {
            name: 'list',
            plural: "subscribers",
            path: "/subscribers-list"
        },
    ],
    process: [
        {
            name: 'list',
            plural: "processes",
            path: "/processes-list"
        },
    ],
    ip: [
        {
            name: 'list',
            plural: "ips",
            path: "/ips-list"
        },
    ],
    log: [
        {
            name: 'list',
            plural: "logs",
            path: "/logs-list"
        },
    ],
    language: [
        {
            name: 'list',
            plural: "languages",
            path: "/languages-list"
        },


    ],
}


export const accessManagement = {
    block: [
        {
            name: 'list',
            plural: "block",
            path: "/blocks-list"
        },
        {
            name: 'create',
            path: "/block-create"
        },
        {
            name: 'edit',
            path: "/block-edit"
        },
        {
            name: 'log',
            path: "/crud-logs/blocks"
        },
    ],
}

export const pspManagement = {
    psp: [
        {
            name: 'list',
            plural: "psps",
            path: "/psps-list"
        },
        {
            name: 'create',
            path: "/create-psp"
        },
        {
            name: 'edit',
            path: "/edit-psp"
        },
        {
            name: 'log',
            path: "/crud-logs/psps"
        },
    ],
}

export const feeManagement = {
    fee: [
        {
            name: 'list',
            plural: "fees",
            path: "/fees-list"
        },
        {
            name: 'create',
            path: "/create-fee"
        },
        {
            name: 'edit',
            path: "/edit-fee"
        },
        {
            name: 'log',
            path: "/crud-logs/fees"
        },
    ],
}