import {
    DELETE_PROCESS_FAILED,
    DELETE_PROCESS_LOADING,
    DELETE_PROCESS_SUCCESS, LIST_PROCESS_FAILED, LIST_PROCESS_LOADING,
    LIST_PROCESS_SUCCESS
} from "../Constants/Processes";


export const deleteProcessReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_PROCESS_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case DELETE_PROCESS_LOADING :
            return {
                loading: true,
                errors: null,
                data: action.payload
            }
        case DELETE_PROCESS_FAILED :
            return {
                loading: false,
                data: state,
                errors: action.payload
            }

        default :
            return state

    }
}

export const listProcessReducer = (state = {}, action) => {
    switch (action.type) {
        case LIST_PROCESS_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case LIST_PROCESS_LOADING :
            return {
                loading: true,
                errors: null,
                data: []
            }
        case LIST_PROCESS_FAILED :
            return {
                loading: false,
                data: [],
                errors: action.payload
            }

        default :
            return state

    }
}
