import {
    PAYMENT_LINK_LOADING,
    PAYMENT_LINK_SUCCESS,
    PAYMENT_LINK_FAILED
} from "../Constants/PaymentLink";


const switcher = (type, action, state) => {
    switch (action.type) {
        case type[0] :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case type[1] :
            return {
                loading: true,
                errors: null,
                data: {}
            }
        case type[2] :
            return {
                loading: false,
                data: {},
                errors: action.payload
            }

        default :
            return state

    }
}
export const paymentLinkReducer = (state = {data: []}, action) => {

    return switcher([
        PAYMENT_LINK_SUCCESS,
        PAYMENT_LINK_LOADING,
        PAYMENT_LINK_FAILED,
    ], action, state)
}

