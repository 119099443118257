import SsoService from "./SsoService";


/**
 * @deprecated
 * @returns {*|null}
 */
const getLocalRefreshToken = () => {
    const user = getUser()
    return user ? user.refreshToken : null;

};

/**
 * @deprecated
 * @returns {*}
 */
const getLocalAccessToken = () => {
    const user = getUser()
    return user ? user.accessToken : null;
};

/**
 * @deprecated
 * @param token
 */
const updateLocalAccessToken = (token) => {
    let user = getUser()
    user.accessToken = token;
    setUser(user)
};

/**
 *
 * @returns {any}
 */
const getUser = () =>  SsoService.getUser()

const getPermission = () => getUser()?.group[0]?.toString()?.replace("/", "")


/**
 * @deprecated
 * @param user
 */
const setUser = (user) => null;


/**
 * @deprecated
 * remove user from local storage
 */
const removeUser = () => window.localStorage.removeItem("user");


export const TokenService = {
    getLocalRefreshToken,
    getLocalAccessToken,
    updateLocalAccessToken,
    getUser,
    setUser,
    removeUser,
    getPermission
};