import {CLEAR_LOG_FAILED, CLEAR_LOG_LOADING, CLEAR_LOG_SUCCESS} from "../Constants/Log";
import {DELETE_LOG_FAILED, DELETE_LOG_LOADING, DELETE_LOG_SUCCESS} from "../Constants/Log";
import {LIST_LOG_FAILED, LIST_LOG_LOADING, LIST_LOG_SUCCESS} from "../Constants/Log";

import {GLOBAL_REFRESH} from "../Constants/Refresh";
import {refresh} from "./index";


export const clearLogReducer = (state = {}, action) => {
    switch (action.type) {
        case CLEAR_LOG_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case CLEAR_LOG_LOADING :
            return {
                loading: true,
                errors: null,
                data: null
            }
        case CLEAR_LOG_FAILED :
            return {
                loading: false,
                data: null,
                errors: action.payload
            }
        case GLOBAL_REFRESH :
            return refresh()
        default :
            return state

    }
}

export const deleteLogReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_LOG_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case DELETE_LOG_LOADING :
            return {
                loading: true,
                errors: null,
                data: action.payload
            }
        case DELETE_LOG_FAILED :
            return {
                loading: false,
                data: state,
                errors: action.payload
            }

        default :
            return state

    }
}

export const listLogReducer = (state = {}, action) => {
    switch (action.type) {
        case LIST_LOG_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case LIST_LOG_LOADING :
            return {
                loading: true,
                errors: null,
                data: []
            }
        case LIST_LOG_FAILED :
            return {
                loading: false,
                data: [],
                errors: action.payload
            }

        default :
            return state

    }
}
