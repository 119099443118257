export const CREATE_LIBRARY_SUCCESS = 'CREATE_LIBRARY_SUCCESS';
export const CREATE_LIBRARY_LOADING = 'CREATE_LIBRARY_LOADING';
export const CREATE_LIBRARY_FAILED = 'CREATE_LIBRARY_FAILED';


export const UPDATE_LIBRARY_SUCCESS = 'UPDATE_LIBRARY_SUCCESS';
export const UPDATE_LIBRARY_LOADING = 'UPDATE_LIBRARY_LOADING';
export const UPDATE_LIBRARY_FAILED = 'UPDATE_LIBRARY_FAILED';


export const DELETE_LIBRARY_SUCCESS = 'DELETE_LIBRARY_SUCCESS';
export const DELETE_LIBRARY_LOADING = 'DELETE_LIBRARY_LOADING';
export const DELETE_LIBRARY_FAILED = 'DELETE_LIBRARY_FAILED';


export const LIST_LIBRARY_SUCCESS = 'LIST_LIBRARY_SUCCESS';
export const LIST_LIBRARY_LOADING = 'LIST_LIBRARY_LOADING';
export const LIST_LIBRARY_FAILED = 'LIST_LIBRARY_FAILED';


export const SHOW_LIBRARY_LOADING = 'SHOW_LIBRARY_LOADING';
export const SHOW_LIBRARY_FAILED = 'SHOW_LIBRARY_FAILED';
export const SHOW_LIBRARY_SUCCESS = 'SHOW_LIBRARY_SUCCESS';


export const MY_LIBRARY_SUCCESS = 'MY_LIBRARY_SUCCESS';
export const MY_LIBRARY_LOADING = 'MY_LIBRARY_LOADING';
export const MY_LIBRARY_FAILED = 'MY_LIBRARY_FAILED';