import React from "react";
import {Link} from "react-router-dom";
import {t} from "i18next";
import {useNavigate} from "react-router";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

const renderTooltip = (props) => <Tooltip id="button-tooltip" {...props}>
    {t('back')}
</Tooltip>
const PageTitle = ({motherMenu, activeMenu, pageContent = false}) => {

    const navigate = useNavigate()

    motherMenu = t(motherMenu?.toString()?.toLocaleLowerCase())?.toLocaleUpperCase()


    return (
        <>

            {/*{*/}
            {/*    pageContent ? <div className={"row page-titles mx-0"}>*/}
            {/*        <div className="col-sm-6 p-md-0">*/}
            {/*            <div className="welcome-text">*/}
            {/*                <h4>Hi, welcome back!</h4>*/}
            {/*                <p className="mb-0">*/}
            {/*                    {pageContent}*/}
            {/*                </p>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div> : null*/}
            {/*}*/}
            <div className="row page-titles mx-0">


                <ol className="breadcrumb ">
                    <li>
                        <button className={"btn btn-primary bg-white border border-none  mx-2  pt-2 mt-1"}
                                onClick={() => {
                                    try {
                                        navigate(-1)
                                    } catch (e) {
                                        navigate('/dashboard')
                                    }
                                }}>
                            <div className={"pt-1"}>
                                <OverlayTrigger
                                    placement="right"
                                    delay={{show: 250, hide: 400}}
                                    overlay={renderTooltip}
                                    defaultShow={false}
                                    flip={false}
                                    onHide={null}
                                    onToggle={null}>
                                    <i className="bi bi-arrow-left-circle-fill text-info " style={{fontSize: 20}}> </i>
                                </OverlayTrigger>
                            </div>
                        </button>
                    </li>
                    {motherMenu && <li className="breadcrumb-item active pt-3" onClick={() => {
                        try {
                            navigate(-1)
                        } catch (e) {
                            navigate('/dashboard')
                        }
                    }
                    }>{motherMenu}
                    </li>}
                    <li className="breadcrumb-item pt-3"><Link to={`#`}>{activeMenu}</Link></li>
                </ol>
            </div>
        </>
    );
};

export default PageTitle;
