import React from 'react'
import AuditList from "../jsx/components/SystemManagement/Audit/AuditList";

const Home = React.lazy(() => import( "../jsx/components/Dashboard/Home"))
const DashboardDark = React.lazy(() => import( "../jsx/components/Dashboard/DashboardDark"))
const Instructors = React.lazy(() => import( "../jsx/components/Dashboard/Instructors"))
const Schedule = React.lazy(() => import( "../jsx/components/Dashboard/Schedule"))
const Message = React.lazy(() => import( "../jsx/components/Dashboard/Message"))
const Activity = React.lazy(() => import( "../jsx/components/Dashboard/Activity"))
const Profile = React.lazy(() => import( "../jsx/components/Dashboard/Profile"))
const CreateMenu = React.lazy(() => import( "../jsx/components/SystemManagement/Menu/CreateMenu"))
const CreateColor = React.lazy(() => import( "../jsx/components/SystemManagement/Color/CreateColor"))
const CreateBanner = React.lazy(() => import( "../jsx/components/SystemManagement/Banner/CreateBanner"))
const CreatePermission = React.lazy(() => import( "../jsx/components/SystemManagement/Permission/CreatePermission"))
const CreateRole = React.lazy(() => import( "../jsx/components/SystemManagement/Role/CreateRole"))
const CreateCategory = React.lazy(() => import( "../jsx/components/SystemManagement/Category/CreateCategory"))
const CreateEvent = React.lazy(() => import( "../jsx/components/SystemManagement/Event/CreateEvent"))
const CreateHero = React.lazy(() => import( "../jsx/components/SystemManagement/Hero/CreateHero"))
const CreateLibrary = React.lazy(() => import( "../jsx/components/SystemManagement/Library/CreateLibrary"))
const EditLibrary = React.lazy(() => import( "../jsx/components/SystemManagement/Library/EditLibrary"))
const LibraryList = React.lazy(() => import( "../jsx/components/SystemManagement/Library/LibraryList"))
const CreateBlog = React.lazy(() => import( "../jsx/components/SystemManagement/Blog/CreateBlog"))
const CoursesMain = React.lazy(() => import( "../jsx/components/Courses/CoursesMain"))
const CourseDetail1 = React.lazy(() => import( "../jsx/components/Courses/CourseDetail1"))
const CourseDetail2 = React.lazy(() => import( "../jsx/components/Courses/CourseDetail2"))
const LibraryDashboard = React.lazy(() => import( "../jsx/components/Library/LibraryDashboard"))
// const LibraryCourses = React.lazy(() => import( "../jsx/components/Library/LibraryCourses"))
// const LibrarySchedule = React.lazy(() => import( "../jsx/components/Library/LibrarySchedule"))
// const LibraryResources = React.lazy(() => import( "../jsx/components/Library/LibraryResources"))
// const LibraryStudents = React.lazy(() => import( "../jsx/components/Library/LibraryStudents"))
const LibraryTransactions = React.lazy(() => import( "../jsx/components/Library/LibraryTransactions"))
const LibraryProfile = React.lazy(() => import( "../jsx/components/Library/MyLibrary/Profile"))

const MyTransactions = React.lazy(() => import( "../jsx/components/Library/MyTransactions"))
// const LiveClass = React.lazy(() => import( "../jsx/components/Library/LiveClass"))
const Theme1 = React.lazy(() => import( "../jsx/components/Dashboard/Demo/Theme1"))
const Theme2 = React.lazy(() => import( "../jsx/components/Dashboard/Demo/Theme2"))
const Theme3 = React.lazy(() => import( "../jsx/components/Dashboard/Demo/Theme3"))
const Theme4 = React.lazy(() => import( "../jsx/components/Dashboard/Demo/Theme4"))
const Theme5 = React.lazy(() => import( "../jsx/components/Dashboard/Demo/Theme5"))
const Theme6 = React.lazy(() => import( "../jsx/components/Dashboard/Demo/Theme6"))
const Theme7 = React.lazy(() => import( "../jsx/components/Dashboard/Demo/Theme7"))
const Theme8 = React.lazy(() => import( "../jsx/components/Dashboard/Demo/Theme8"))
const AppProfile = React.lazy(() => import( "../jsx/components/AppsMenu/AppProfile/AppProfile"))
const Compose = React.lazy(() => import( "../jsx/components/AppsMenu/Email/Compose/Compose"))
const Inbox = React.lazy(() => import( "../jsx/components/AppsMenu/Email/Inbox/Inbox"))
const Read = React.lazy(() => import( "../jsx/components/AppsMenu/Email/Read/Read"))
const Calendar = React.lazy(() => import( "../jsx/components/AppsMenu/Calendar/Calendar"))
const PostDetails = React.lazy(() => import( "../jsx/components/AppsMenu/AppProfile/PostDetails"))
const SparklineChart = React.lazy(() => import( "../jsx/components/charts/Sparkline"))
const ChartJs = React.lazy(() => import( "../jsx/components/charts/Chartjs"))
const Chartist = React.lazy(() => import( "../jsx/components/charts/chartist"))
const ApexChart = React.lazy(() => import( "../jsx/components/charts/apexcharts"))
const RechartJs = React.lazy(() => import( "../jsx/components/charts/rechart"))
const UiAlert = React.lazy(() => import( "../jsx/components/bootstrap/Alert"))
const UiBadge = React.lazy(() => import( "../jsx/components/bootstrap/Badge"))
const UiButton = React.lazy(() => import( "../jsx/components/bootstrap/Button"))
const UiModal = React.lazy(() => import( "../jsx/components/bootstrap/Modal"))
const UiButtonGroup = React.lazy(() => import( "../jsx/components/bootstrap/ButtonGroup"))
const UiAccordion = React.lazy(() => import( "../jsx/components/bootstrap/Accordion"))
const UiListGroup = React.lazy(() => import( "../jsx/components/bootstrap/ListGroup"))
const UiCards = React.lazy(() => import( "../jsx/components/bootstrap/Cards"))
const UiCarousel = React.lazy(() => import( "../jsx/components/bootstrap/Carousel"))
const UiDropDown = React.lazy(() => import( "../jsx/components/bootstrap/DropDown"))
const UiPopOver = React.lazy(() => import( "../jsx/components/bootstrap/PopOver"))
const UiProgressBar = React.lazy(() => import( "../jsx/components/bootstrap/ProgressBar"))
const UiTab = React.lazy(() => import( "../jsx/components/bootstrap/Tab"))
const UiPagination = React.lazy(() => import( "../jsx/components/bootstrap/Pagination"))
const UiTypography = React.lazy(() => import( "../jsx/components/bootstrap/Typography"))
const UiGrid = React.lazy(() => import( "../jsx/components/bootstrap/Grid"))
const Select2 = React.lazy(() => import( "../jsx/components/PluginsMenu/Select2/Select2"))
const MainNouiSlider = React.lazy(() => import( "../jsx/components/PluginsMenu/NouiSlider/MainNouiSlider"))
const MainSweetAlert = React.lazy(() => import( "../jsx/components/PluginsMenu/SweetAlert/SweetAlert"))
const Toastr = React.lazy(() => import( "../jsx/components/PluginsMenu/Toastr/Toastr"))
const JqvMap = React.lazy(() => import( "../jsx/components/PluginsMenu/JqvMap/JqvMap"))
const Lightgallery = React.lazy(() => import( "../jsx/components/PluginsMenu/Lightgallery/Lightgallery"))
const Todo = React.lazy(() => import( "../jsx/pages/Todo"))
const Widget = React.lazy(() => import( "../jsx/pages/Widget"))
const ProductGrid = React.lazy(() => import( "../jsx/components/AppsMenu/Shop/ProductGrid/ProductGrid"))
const ProductList = React.lazy(() => import( "../jsx/components/AppsMenu/Shop/ProductList/ProductList"))
const ProductDetail = React.lazy(() => import( "../jsx/components/AppsMenu/Shop/ProductGrid/ProductDetail"))
const ProductOrder = React.lazy(() => import( "../jsx/components/AppsMenu/Shop/ProductOrder"))
const Checkout = React.lazy(() => import( "../jsx/components/AppsMenu/Shop/Checkout/Checkout"))
const Invoice = React.lazy(() => import( "../jsx/components/AppsMenu/Shop/Invoice/Invoice"))
const Customers = React.lazy(() => import( "../jsx/components/AppsMenu/Shop/Customers/Customers"))
const Element = React.lazy(() => import( "../jsx/components/Forms/Element/Element"))
const Wizard = React.lazy(() => import( "../jsx/components/Forms/Wizard/Wizard"))
const SummerNote = React.lazy(() => import( "../jsx/components/Forms/Summernote/SummerNote"))
const Pickers = React.lazy(() => import( "../jsx/components/Forms/Pickers/Pickers"))
// const jQueryValidation = React.lazy(()=> import("../jsx/components/Forms/jQueryValidation/jQueryValidation"))
const FilteringTable = React.lazy(() => import( "../jsx/components/table/FilteringTable/FilteringTable"))
const SortingTable = React.lazy(() => import( "../jsx/components/table/SortingTable/SortingTable"))
const DataTable = React.lazy(() => import( "../jsx/components/table/DataTable"))
const BootstrapTable = React.lazy(() => import( "../jsx/components/table/BootstrapTable"))
const ColorList = React.lazy(() => import( "../jsx/components/SystemManagement/Color/ColorList"))
const EditColor = React.lazy(() => import( "../jsx/components/SystemManagement/Color/EditColor"))
const CategoryList = React.lazy(() => import( "../jsx/components/SystemManagement/Category/CategoryList"))
const EditCategory = React.lazy(() => import( "../jsx/components/SystemManagement/Category/EditCategory"))
const BlogList = React.lazy(() => import( "../jsx/components/SystemManagement/Blog/BlogList"))
const EditBlog = React.lazy(() => import( "../jsx/components/SystemManagement/Blog/EditBlog"))
const EventList = React.lazy(() => import( "../jsx/components/SystemManagement/Event/EventList"))
const EditEvent = React.lazy(() => import( "../jsx/components/SystemManagement/Event/EditEvent"))
const HeroList = React.lazy(() => import( "../jsx/components/SystemManagement/Hero/HeroList"))
const EditHero = React.lazy(() => import( "../jsx/components/SystemManagement/Hero/EditHero"))
const BannerList = React.lazy(() => import( "../jsx/components/SystemManagement/Banner/BannerList"))
const EditBanner = React.lazy(() => import( "../jsx/components/SystemManagement/Banner/EditBanner"))
const MenuList = React.lazy(() => import( "../jsx/components/SystemManagement/Menu/MenuList"))
const EditMenu = React.lazy(() => import( "../jsx/components/SystemManagement/Menu/EditMenu"))
const EditTranslation = React.lazy(() => import( "../jsx/components/SystemManagement/Translation/EditTranslation"))
const CourseList = React.lazy(() => import( "../jsx/components/SystemManagement/Course/CourseList"))
const CreateCourse = React.lazy(() => import( "../jsx/components/SystemManagement/Course/CreateCourse"))
const EditCourse = React.lazy(() => import( "../jsx/components/SystemManagement/Course/EditCourse"))
const FaqList = React.lazy(() => import( "../jsx/components/SystemManagement/Faq/FaqList"))
const CreateFaq = React.lazy(() => import( "../jsx/components/SystemManagement/Faq/CreateFaq"))
const EditFaq = React.lazy(() => import( "../jsx/components/SystemManagement/Faq/EditFaq"))

const CommentList = React.lazy(() => import( "../jsx/components/SystemManagement/Comment/CommentList"))
const CreateComment = React.lazy(() => import( "../jsx/components/SystemManagement/Comment/CreateComment"))
const EditComment = React.lazy(() => import( "../jsx/components/SystemManagement/Comment/EditComment"))

const SiteMapList = React.lazy(() => import( "../jsx/components/SystemManagement/SiteMap/SiteMapList"))
const CreateSiteMap = React.lazy(() => import( "../jsx/components/SystemManagement/SiteMap/CreateSiteMap"))
const EditSiteMap = React.lazy(() => import( "../jsx/components/SystemManagement/SiteMap/EditSiteMap"))

const UsersList = React.lazy(() => import( "../jsx/components/SystemManagement/User/UserList"))

const LogsList = React.lazy(() => import( "../jsx/components/SystemManagement/Log/LogList"))

const LanguagesList = React.lazy(() => import( "../jsx/components/SystemManagement/Language/LanguageList"))

const RequestList = React.lazy(() => import( "../jsx/components/UserRequest/UserRequestList"))

const BlockList = React.lazy(() => import( "../jsx/components/AccessManagement/Block/BlockList"))
const BlockCreate = React.lazy(() => import( "../jsx/components/AccessManagement/Block/CreateBlock"))
const EditBlock = React.lazy(() => import( "../jsx/components/AccessManagement/Block/EditBlock"))

const OrderList = React.lazy(() => import( "../jsx/components/OrderManagement/Order/OrderList"))
const OrderShow = React.lazy(() => import( "../jsx/components/OrderManagement/Order/ShowOrder"))

const GeneratePaymentLink = React.lazy(() => import( "../jsx/components/OrderManagement/Order/GeneratePaymentLink"))


const TemporaryFundList = React.lazy(() => import( "../jsx/components/FundManagement/TemporaryFund/TemporaryFundList"))
const TransactionsList = React.lazy(() => import( "../jsx/components/FundManagement/Transactions/TransactionsList"))
const TransactionsShow = React.lazy(() => import( "../jsx/components/FundManagement/Transactions/ShowTransaction"))


const ContactsList = React.lazy(() => import( "../jsx/components/SystemManagement/Contact/ContactList"))
const SubscribersList = React.lazy(() => import( "../jsx/components/SystemManagement/Subscription/SubscriberList"))

const ProcessList = React.lazy(() => import( "../jsx/components/SystemManagement/Process/ProcessList"))
const IpList = React.lazy(() => import( "../jsx/components/SystemManagement/IP/IPList"))
const SubscribedLibrariesList = React.lazy(() => import( "../jsx/components/SubscribedLibrariesManagement/SubscribedLibraries/SubscribedLibrariesList"))
const CreatePsp = React.lazy(() => import( "../jsx/components/PSPManagement/Psp/CreatePsp"))
const PspList = React.lazy(() => import( "../jsx/components/PSPManagement/Psp/PspList"))
const EditPsp = React.lazy(() => import( "../jsx/components/PSPManagement/Psp/EditPsp"))

const CreateFee = React.lazy(() => import( "../jsx/components/FeesManagement/Fee/CreateFee"))
const FeeList = React.lazy(() => import( "../jsx/components/FeesManagement/Fee/FeeList"))
const EditFee = React.lazy(() => import( "../jsx/components/FeesManagement/Fee/EditFee"))
const CustomerSupport = React.lazy(() => import( "../jsx/components/CustomerSupport/index"))


const PayoutList = React.lazy(() => import( "../jsx/components/PayoutManagement/PayoutList"))
export const routes = [
    /// Dashboard
    {path: "", element: <Home/>},

    {path: "dashboard", element: <Home/>},
    {path: "dashboard-dark", element: <DashboardDark/>},
    {path: "instructors", element: <Instructors/>},
    {path: "schedule", element: <Schedule/>},
    {path: "message", element: <Message/>},
    {path: "activity", element: <Activity/>},
    {path: "profile", element: <Profile/>},
// customer support
    {path: "customer_support", element: <CustomerSupport/>},

    // psp management
    {path: "create-psp", element: <CreatePsp/>},
    {path: "psps-list", element: <PspList/>},
    {path: "edit-psp/:id", element: <EditPsp/>},

    // fees
    {path: "create-fee", element: <CreateFee/>},
    {path: "fees-list", element: <FeeList/>},
    {path: "edit-fee/:id", element: <EditFee/>},


    //SubscribedLibrariesManagement

    {path: "subscribed_libraries-list", element: <SubscribedLibrariesList/>},


    // Access Management
    {path: "blocks-list", element: <BlockList/>},
    {path: "block-edit/:id", element: <EditBlock/>},
    {path: "block-create", element: <BlockCreate/>},

    // Order Management
    {path: "orders-list", element: <OrderList/>},
    {path: "orders-show/:id", element: <OrderShow/>},

    {path: "generate-payment-link", element: <GeneratePaymentLink/>},

    // user requests
    {path: "user_requests-list", element: <RequestList/>},

    // temporary funds
    {path: "temporaryfunds-list", element: <TemporaryFundList/>},
    {path: "transactions-list", element: <TransactionsList/>},
    {path: "transactions-show/:id", element: <TransactionsShow/>},

    // payouts


    {path: "payouts-list", element: <PayoutList/>},

    // Audit
    {path: "crud-logs/:model", element: <AuditList/>},
    {path: "crud-logs/:model/:id", element: <AuditList/>},
    /// System Management
    {path: "ips-list", element: <IpList/>},

    // subscribers-list
    {path: "subscribers-list", element: <SubscribersList/>},

    {path: "contacts-list", element: <ContactsList/>},

    {path: "processes-list", element: <ProcessList/>},

    {path: "translate/:id", element: <EditTranslation/>},

    {path: "users-list", element: <UsersList/>},
    {path: "logs-list", element: <LogsList/>},
    {path: "Languages-list", element: <LanguagesList/>},

    {path: "sitemaps-list", element: <SiteMapList/>},
    {path: "sitemap-create", element: <CreateSiteMap/>},
    {path: "sitemap-edit/:id", element: <EditSiteMap/>},

    {path: "menu-create", element: <CreateMenu/>},
    {path: "menus-list", element: <MenuList/>},
    {path: "menu-edit/:id", element: <EditMenu/>},

    {path: "comment-create", element: <CreateComment/>},
    {path: "comments-list", element: <CommentList/>},
    {path: "comment-edit/:id", element: <EditComment/>},

    {path: "color-create", element: <CreateColor/>},
    {path: "color-edit/:id", element: <EditColor/>},
    {path: "colors-list", element: <ColorList/>},

    {path: "faq-create", element: <CreateFaq/>},
    {path: "faq-edit/:id", element: <EditFaq/>},
    {path: "faqs-list", element: <FaqList/>},

    {path: "courses-list", element: <CourseList/>},
    {path: "course-create", element: <CreateCourse/>},
    {path: "course-edit/:id", element: <EditCourse/>},


    {path: "banner-create", element: <CreateBanner/>},
    {path: "banners-list", element: <BannerList/>},
    {path: "banner-edit/:id", element: <EditBanner/>},


    {path: "permission-create", element: <CreatePermission/>},
    {path: "role-create", element: <CreateRole/>},

    {path: "category-create", element: <CreateCategory/>},
    {path: "category-edit/:id", element: <EditCategory/>},
    {path: "categories-list", element: <CategoryList/>},

    {path: "event-create", element: <CreateEvent/>},
    {path: "events-list", element: <EventList/>},
    {path: "event-edit/:id", element: <EditEvent/>},


    {path: "hero-create", element: <CreateHero/>},
    {path: "heroes-list", element: <HeroList/>},
    {path: "hero-edit/:id", element: <EditHero/>},


    {path: "library-create", element: <CreateLibrary/>},
    {path: "library-edit/:id", element: <EditLibrary/>},
    {path: "libraries-list", element: <LibraryList/>},


    {path: "blog-create", element: < CreateBlog/>},
    {path: "blogs-list", element: < BlogList/>},
    {path: "blog-edit/:id", element: <EditBlog/>},


    // Courses ----
    {path: "courses", element: < CoursesMain/>},
    {path: "course-details-1", element: < CourseDetail1/>},
    {path: "course-details-2", element: < CourseDetail2/>},


    ///Library  Pages path
    {path: "library-dashboard", element: < LibraryDashboard/>},
    {path: "library-schedule", element: < Calendar/>},
    {path: "library-transactions", element: < LibraryTransactions/>},
    {path: "library-profile", element: < LibraryProfile/>},

    {path: "my-transactions", element: <MyTransactions/>},
    // {path: "library-courses", element: < LibraryCourses/>},
    // {path: "library-resources", element: < LibraryResources/>},
    // {path: "Library-students", element: < LibraryStudents/>},
    // {path: "Library-liveclass", element: < LiveClass/>},


    /////Demo
    {path: "secondary-sidebar", element: < Theme1/>},
    {path: "mini-primary-sidebar", element: < Theme2/>},
    {path: "nav-header", element: < Theme3/>},
    {path: "horizontal-header", element: < Theme4/>},
    {path: "header-nav", element: < Theme5/>},
    {path: "sidebar-theme", element: < Theme6/>},
    {path: "dark-sidebar", element: < Theme7/>},
    {path: "theme-dashboard", element: < Theme8/>},

    /// Apps
    {path: "app-profile", element: < AppProfile/>},
    {path: "email-compose", element: < Compose/>},
    {path: "email-inbox", element: < Inbox/>},
    {path: "email-read", element: < Read/>},
    {path: "app-calender", element: < Calendar/>},
    {path: "post-details", element: < PostDetails/>},

    /// Chart
    {path: "chart-sparkline", element: < SparklineChart/>},
    {path: "chart-chartjs", element: < ChartJs/>},
    {path: "chart-chartist", element: < Chartist/>},
    {path: "chart-apexchart", element: < ApexChart/>},
    {path: "chart-rechart", element: < RechartJs/>},

    /// Bootstrap
    {path: "ui-alert", element: < UiAlert/>},
    {path: "ui-badge", element: < UiBadge/>},
    {path: "ui-button", element: < UiButton/>},
    {path: "ui-modal", element: < UiModal/>},
    {path: "ui-button-group", element: < UiButtonGroup/>},
    {path: "ui-accordion", element: < UiAccordion/>},
    {path: "ui-list-group", element: < UiListGroup/>},
    {path: "ui-card", element: < UiCards/>},
    {path: "ui-carousel", element: < UiCarousel/>},
    {path: "ui-dropdown", element: < UiDropDown/>},
    {path: "ui-popover", element: < UiPopOver/>},
    {path: "ui-progressbar", element: < UiProgressBar/>},
    {path: "ui-tab", element: < UiTab/>},
    {path: "ui-pagination", element: < UiPagination/>},
    {path: "ui-typography", element: < UiTypography/>},
    {path: "ui-grid", element: < UiGrid/>},

    /// Plugin
    {path: "uc-select2", element: < Select2/>},
    {path: "uc-noui-slider", element: < MainNouiSlider/>},
    {path: "uc-sweetalert", element: < MainSweetAlert/>},
    {path: "uc-toastr", element: < Toastr/>},
    {path: "map-jqvmap", element: < JqvMap/>},
    {path: "uc-lightgallery", element: < Lightgallery/>},

    ///Redux
    {path: "todo", element: < Todo/>},

    /// Widget
    {path: "widget-basic", element: < Widget/>},

    /// Shop
    {path: "ecom-product-grid", element: < ProductGrid/>},
    {path: "ecom-product-list", element: < ProductList/>},
    {path: "ecom-product-detail", element: < ProductDetail/>},
    {path: "ecom-product-order", element: < ProductOrder/>},
    {path: "ecom-checkout", element: < Checkout/>},
    {path: "ecom-invoice", element: < Invoice/>},
    {path: "ecom-customers", element: < Customers/>},

    /// Form
    {path: "form-element", element: < Element/>},
    {path: "form-wizard", element: < Wizard/>},
    {path: "form-editor-summernote", element: < SummerNote/>},
    {path: "form-pickers", element: < Pickers/>},
    {path: "form-validation-jquery", element: < jQueryValidation/>},

    /// table
    {path: 'table-filtering', element: < FilteringTable/>},
    {path: 'table-sorting', element: < SortingTable/>},
    {path: "table-datatable-basic", element: < DataTable/>},
    {path: "table-bootstrap-basic", element: < BootstrapTable/>},

];

