import {AUDITS_FAILED, AUDITS_LOADING, AUDITS_SUCCESS} from "../Constants/Audit";
import {getAudits} from "../../services/AuditService";
import {handlePayloadErrors} from "../../Util/ErrorsHandler";
const failedToGetUserData = "failed to get audit data "
export const AuditsHandler = (model , id) => async (dispatch) => {

    try {

        dispatch({type: AUDITS_LOADING})

        const {data} = await getAudits(model , id )

        if (data && !data.success) {
            return dispatch({
                type: AUDITS_FAILED, payload: failedToGetUserData
            })
        }

        dispatch({
            type: AUDITS_SUCCESS, payload: data?.data
        })

    } catch (e) {

        dispatch({
            type: AUDITS_FAILED, payload: handlePayloadErrors(e)
        })

    }


}