import {DELETE_SUBSCRIBER_FAILED, DELETE_SUBSCRIBER_LOADING, DELETE_SUBSCRIBER_SUCCESS} from "../Constants/Subscriber";
import {LIST_SUBSCRIBER_FAILED, LIST_SUBSCRIBER_LOADING, LIST_SUBSCRIBER_SUCCESS} from "../Constants/Subscriber";




export const deleteSubscriberReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_SUBSCRIBER_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case DELETE_SUBSCRIBER_LOADING :
            return {
                loading: true,
                errors: null,
                data: action.payload
            }
        case DELETE_SUBSCRIBER_FAILED :
            return {
                loading: false,
                data: state,
                errors: action.payload
            }

        default :
            return state

    }
}

export const listSubscriberReducer = (state = {}, action) => {
    switch (action.type) {
        case LIST_SUBSCRIBER_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case LIST_SUBSCRIBER_LOADING :
            return {
                loading: true,
                errors: null,
                data: []
            }
        case LIST_SUBSCRIBER_FAILED :
            return {
                loading: false,
                data: [],
                errors: action.payload
            }

        default :
            return state

    }
}
