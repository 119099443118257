export const
    CREATE_BLOG_FAILED = "CREATE_BLOG_FAILED",
    CREATE_BLOG_LOADING = "CREATE_BLOG_LOADING",
    CREATE_BLOG_SUCCESS = "CREATE_BLOG_SUCCESS",
    UPDATE_BLOG_FAILED = "UPDATE_BLOG_FAILED",
    UPDATE_BLOG_LOADING = "UPDATE_BLOG_LOADING",
    UPDATE_BLOG_SUCCESS = "UPDATE_BLOG_SUCCESS",
    DELETE_BLOG_FAILED = "DELETE_BLOG_FAILED",
    DELETE_BLOG_LOADING = "DELETE_BLOG_LOADING",
    DELETE_BLOG_SUCCESS = "DELETE_BLOG_SUCCESS",
    LIST_BLOG_FAILED = "LIST_BLOG_FAILED",
    LIST_BLOG_LOADING = "LIST_BLOG_LOADING",
    LIST_BLOG_SUCCESS = "LIST_BLOG_SUCCESS",
    SHOW_BLOG_LOADING = "SHOW_BLOG_LOADING",
    SHOW_BLOG_FAILED = "SHOW_BLOG_FAILED",
    SHOW_BLOG_SUCCESS = "SHOW_BLOG_SUCCESS"