import {CREATE_MENU_FAILED, CREATE_MENU_LOADING, CREATE_MENU_SUCCESS} from "../Constants/Menu";
import {UPDATE_MENU_FAILED, UPDATE_MENU_LOADING, UPDATE_MENU_SUCCESS} from "../Constants/Menu";
import {DELETE_MENU_FAILED, DELETE_MENU_LOADING, DELETE_MENU_SUCCESS} from "../Constants/Menu";
import {LIST_MENU_FAILED, LIST_MENU_LOADING, LIST_MENU_SUCCESS} from "../Constants/Menu";
import {SHOW_MENU_FAILED, SHOW_MENU_LOADING, SHOW_MENU_SUCCESS} from "../Constants/Menu";
import {GLOBAL_REFRESH} from "../Constants/Refresh";
import {refresh} from "./index";


export const createMenuReducer = (state = {}, action) => {
    switch (action.type) {
        case CREATE_MENU_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case CREATE_MENU_LOADING :
            return {
                loading: true,
                errors: null,
                data: null
            }
        case CREATE_MENU_FAILED :
            return {
                loading: false,
                data: null,
                errors: action.payload
            }
        case GLOBAL_REFRESH :
            return refresh()
        default :
            return state

    }
}

export const updateMenuReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_MENU_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case UPDATE_MENU_LOADING :
            return {
                loading: true,
                errors: null,
                data: action.payload
            }
        case UPDATE_MENU_FAILED :
            return {
                loading: false,
                data: state,
                errors: action.payload
            }
        case GLOBAL_REFRESH :
            return refresh()

        default :
            return state

    }
}


export const showMenuReducer = (state = {}, action) => {
    switch (action.type) {
        case SHOW_MENU_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case SHOW_MENU_LOADING :
            return {
                loading: true,
                errors: null,
                data: null
            }
        case SHOW_MENU_FAILED :
            return {
                loading: false,
                data: null,
                errors: action.payload
            }

        default :
            return state

    }
}


export const deleteMenuReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_MENU_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case DELETE_MENU_LOADING :
            return {
                loading: true,
                errors: null,
                data: action.payload
            }
        case DELETE_MENU_FAILED :
            return {
                loading: false,
                data: state,
                errors: action.payload
            }

        default :
            return state

    }
}

export const listMenuReducer = (state = {}, action) => {
    switch (action.type) {
        case LIST_MENU_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case LIST_MENU_LOADING :
            return {
                loading: true,
                errors: null,
                data: []
            }
        case LIST_MENU_FAILED :
            return {
                loading: false,
                data: [],
                errors: action.payload
            }

        default :
            return state

    }
}






