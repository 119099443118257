import React from "react";
import {useTranslation} from "react-i18next";
import Select from "react-select";
import {TokenService} from "../../../services/TokenService";
import Badge from "./Badge";
import {isAdmin} from "../../../Role";
import AppService from "../../../services/AppService";


const SelectCreator = ({setSelectedOption, options, name, value = null, id = null, style = null}) => {

    const {t} = useTranslation();

    if (name === 'user' && !isAdmin()) {
        const user = TokenService.getUser()
        return <div id={"user"}>
            <Badge message={user?.first_name + " " + user?.last_name}/>
        </div>
    }


    // used on datatable
    if (style) {
        return <Select
            name={name}
            id={id || name}
            placeholder={t(name)}
            onChange={setSelectedOption}
            options={options}
            style={style}
            isRtl={AppService.getLocalLanguage() === 'ar'}
        />
    }

    // for update
    if (value) {
        return <Select
            value={value}
            name={name}
            id={id || name}
            placeholder={t(name)}
            onChange={setSelectedOption}
            options={options}
            style={{
                lineHeight: "40px",
                color: "#7e7e7e",
                paddingLeft: " 15px",
            }}
            isSearchable={true}
            isRtl={AppService.getLocalLanguage() === 'ar'}


        />
    }
    return <Select
        name={name}
        id={id || name}
        placeholder={t(name)}
        onChange={setSelectedOption}
        options={options}
        style={{
            lineHeight: "40px",
            color: "#7e7e7e",
            paddingLeft: " 15px",
        }}
        isSearchable={true}
        isRtl={AppService.getLocalLanguage() === 'ar'}

    />

}

export default React.memo(SelectCreator)