const DropdownFilter = (keyName, rows, id, filterValues) => {


    if (filterValues === "" || filterValues === '') return rows;
    return rows?.filter(row => {

        if (!keyName) {
            return row?.values?.[id[0]]?.toString()?.toLowerCase() === filterValues?.toString()?.toLowerCase()
        }
        return row?.values?.[id[0]][keyName]?.toString()?.toLowerCase() === filterValues?.toString()?.toLowerCase()
    })
}

export default DropdownFilter