import {
    LIST_SUBSCRIBED_LIBRARIES_FAILED,
    LIST_SUBSCRIBED_LIBRARIES_LOADING,
    LIST_SUBSCRIBED_LIBRARIES_SUCCESS
} from "../Constants/SubscribedLibraries";


const switcher = (type, action, state) => {
    switch (action.type) {
        case type[0] :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case type[1] :
            return {
                loading: true,
                errors: null,
                data: []
            }
        case type[2] :
            return {
                loading: false,
                data: [],
                errors: action.payload
            }

        default :
            return state

    }
}
export const getSubscribedLibrariesReducer = (state = {data: []}, action) => {

    return switcher([
        LIST_SUBSCRIBED_LIBRARIES_SUCCESS,
        LIST_SUBSCRIBED_LIBRARIES_LOADING,
        LIST_SUBSCRIBED_LIBRARIES_FAILED,
    ], action, state)
}

