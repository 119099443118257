import {TokenService} from "../../services/TokenService";
import {
    USER_LIBERIAN_FAILED,
    USER_LIBERIAN_LOADING,
    USER_LIBERIAN_SUCCESS,
    USER_LOGIN_FAILED,
    USER_LOGIN_LOADING,
    USER_LOGIN_SUCCESS,
    USER_REFRESH,
    USER_PROFILE_LOADING,
    USER_PROFILE_FAILED,
    USER_PROFILE_SUCCESS,
    USERS_LOADING,
    USERS_FAILED,
    USERS_SUCCESS,
    USER_DETAILS_LOADING,
    USER_DETAILS_FAILED,
    USER_DETAILS_SUCCESS,
} from "../Constants/User";
import {handlePayloadErrors} from "../../Util/ErrorsHandler";
import {getUserDetails, login, update} from "../../services/AuthService";
import {librarianUsers, users} from "../../services/UserService";
import {errorFormat} from "#validator";
import AppService from "../../services/AppService";
import SsoService from "../../services/SsoService";


const failedToGetUserData = "failed to get user data "


/**
 * @param userCredentials
 * @returns {(function(*): Promise<*|undefined>)|*}
 */
export const userSignInHandler = (userCredentials) => async (dispatch) => {

    try {

        dispatch({type: USER_LOGIN_LOADING})

        const {data} = await login(userCredentials)

        if (data && !data.success) {
            return dispatch({
                type: USER_LOGIN_FAILED, payload: failedToGetUserData
            })
        }

        const user = data?.data

        if (!['admin', 'liberian'].includes(user.role.name)) {
            return window.close()
        }

        dispatch({
            type: USER_LOGIN_SUCCESS, payload: {
                accessToken: user.accessToken,
                refreshToken: user.refreshToken
            }
        })

        await TokenService.removeUser()
        await TokenService.setUser(user)

        return window.close()
    } catch (e) {
        errorFormat(e)
        dispatch({
            type: USER_LOGIN_FAILED, payload: handlePayloadErrors(e)
        })
        return window.close()

    }


}


export const LogoutHandler = () => async (dispatch) => {
    await TokenService.removeUser()
    AppService.removeRefreshLanguage()
    await dispatch({type: USER_REFRESH})
    await SsoService.doLogout();
    // window.location.assign(env.FRONTEND_URL)
}


export const liberianUsersHandler = (isSpecific = false) => async (dispatch) => {

    try {

        dispatch({type: USER_LIBERIAN_LOADING})

        const {data} = await librarianUsers(isSpecific)

        if (data && !data.success) {
            return dispatch({
                type: USER_LIBERIAN_FAILED, payload: failedToGetUserData
            })
        }

        dispatch({
            type: USER_LIBERIAN_SUCCESS, payload: data?.data
        })

    } catch (e) {

        dispatch({
            type: USER_LIBERIAN_FAILED, payload: handlePayloadErrors(e)
        })

    }


}

export const usersHandler = () => async (dispatch) => {

    try {

        dispatch({type: USERS_LOADING})

        const {data} = await users()

        if (data && !data.success) {
            return dispatch({
                type: USERS_FAILED, payload: failedToGetUserData
            })
        }

        dispatch({
            type: USERS_SUCCESS, payload: data?.data
        })

    } catch (e) {

        dispatch({
            type: USERS_FAILED, payload: handlePayloadErrors(e)
        })

    }


}

export const getUserHandler = () => async (dispatch) => {


    try {

        dispatch({type: USER_DETAILS_LOADING})
        const {data} = await getUserDetails()
        if (data && !data.success) {
            return dispatch({
                type: USER_DETAILS_FAILED, payload: "User  Error. "
            })
        }

        dispatch({
            type: USER_DETAILS_SUCCESS, payload: data?.data
        })

    } catch (e) {
        errorFormat(e)
        dispatch({
            type: USER_DETAILS_FAILED, payload: handlePayloadErrors(e)
        })
    }


}
export const userProfileUpdateHandler = (payload) => async (dispatch) => {
    const form = new FormData()

    try {

        dispatch({type: USER_PROFILE_LOADING})

        Object.entries(payload).forEach(([key, value]) => form.append(key, value))

        const {data} = await update(form)

        if (data && !data.success) {
            return dispatch({
                type: USER_PROFILE_FAILED, payload: "User Profile Error. "
            })
        }


        dispatch({
            type: USER_PROFILE_SUCCESS, payload: data?.data
        })
        dispatch({
            type: USER_DETAILS_SUCCESS, payload: data?.data
        })
    } catch (e) {
        errorFormat(e)
        dispatch({
            type: USER_PROFILE_FAILED, payload: handlePayloadErrors(e)
        })
    }


}

