import jwt_decode from "jwt-decode";
import dayjs from "dayjs";
import api from "../services/AxiosInstance";
import env from "#env";
import SsoService from "../services/SsoService";
import _kc from "../Util/keycloack";
import AppService from "../services/AppService";

let isRefreshing = false;
let expectURL = env.EXCEPT_END_POINTS
let abortRequest = false
expectURL = expectURL.split(",").map(url => url.trim())

export function isRefreshTokenExpired(refreshToken) {

    if (refreshToken) {
        //read user token
        const user = jwt_decode(refreshToken)
        // check user token is expired !
        return dayjs.unix(user?.exp).diff(dayjs()) < 1
    }

    return true
}


const getPath = () => {
    let path = window.location.pathname;
    path = path.split("/");
    let firstArgPath = path[path.length - 2];
    path = path[path.length - 1];
    return {path, firstArgPath}
}

export function Interceptor(history) {

    const accessToken = SsoService.getToken()
    const refreshToken = SsoService.getRefreshToken()
    const {isExpired: isRefreshExpired} = isRefreshTokenExpired(refreshToken);

    let {path, firstArgPath} = getPath()

    if (path !== 'login' && path !== 'register' && firstArgPath !== 'shake-hands') {
        if (isRefreshExpired) {
            SsoService.updateToken(function (refreshed) {
                if (refreshed) {
                    console.log('Interceptor Token was successfully refreshed', refreshed);
                } else {
                    console.log('Interceptor Token is still valid');
                }
            }).then(InterceptorUpdateTokenResponse => console.log({InterceptorUpdateTokenResponse}))
        }
    }


    api.interceptors.request.use(config => {


        config.headers.common['lang'] = 'en'
        config.headers['lang'] = 'en'
        config.headers['Authorization'] = 'Bearer ' + accessToken;
        config.headers['x-refresh-token'] = refreshToken;
        return config
    }, error => {
        return error
    })

    return api.interceptors.response.use((response) => {
        return response;
    }, (error) => {


        const originalRequest = error.config;

        if (error?.response?.status === 401 && !originalRequest._retry && !expectURL.includes(originalRequest.url)) {

            if (error?.response?.data && error?.response?.data.code === 401) {
                abortRequest = true
            }


            if (isRefreshing) {
                return new Promise(function (resolve, reject) {
                }).then(() => {
                    originalRequest.headers['Authorization'] = 'Bearer ' + SsoService.getToken();
                    return api(originalRequest);
                }).catch(err => {
                    return Promise.reject(err);
                })
            }

            originalRequest._retry = true;
            isRefreshing = true;

            return new Promise(function (resolve, reject) {

                SsoService.updateToken(function (refreshed) {
                    if (refreshed) {
                        console.log('Promise Token was successfully refreshed', refreshed);
                    } else {
                        console.log('Promise Token is still valid interceptors response');
                    }
                }).then(PromiseUpdateTokenResponse => {
                    console.log({PromiseUpdateTokenResponse})
                    api.defaults.headers.common['Authorization'] = 'Bearer ' + SsoService.getToken();
                    api.defaults.headers.common['x-refresh-token'] = SsoService.getRefreshToken();
                    api.defaults.headers['Authorization'] = 'Bearer ' + SsoService.getToken();
                    api.defaults.headers['x-refresh-token'] = SsoService.getRefreshToken();
                    originalRequest.headers['Authorization'] = 'Bearer ' + SsoService.getToken();
                    originalRequest.headers['x-refresh-token'] = SsoService.getRefreshToken();
                    resolve(api(originalRequest));

                }).catch(errorPromiseInterceptor => {
                    console.log({errorPromiseInterceptor})
                    AppService.removeRefreshLanguage()
                    SsoService.doLogout()
                    reject(errorPromiseInterceptor)
                }).finally(() => {
                    isRefreshing = false
                })


            })
        }

        if (error?.response?.status === 404) {

            return new Promise(function (resolve, reject) {
                try {
                    resolve(history.push('/404'))
                } catch (e) {
                    reject(e)
                }

            }).catch(err => Promise.reject(err))
        }

        if (error?.response?.status === 403) {

            return new Promise(function (resolve, reject) {
                try {
                    resolve(history.push('/403'))
                } catch (e) {
                    reject(e)
                }

            }).catch(err => Promise.reject(err))
        }

        if (error?.response?.status === 200) {
            if (Number(error?.response?.data?.code || 200) === 555) {
                return new Promise(function (resolve, reject) {
                    try {
                        resolve(history.push('/account-locked'))
                    } catch (e) {
                        reject(e)
                    }

                }).catch(err => Promise.reject(err))
            }
        }

        if (error?.response?.status === 451) {
            return new Promise(function (resolve, reject) {
                try {
                    resolve(history.push('/unavailable/' + error?.response?.data?.ip))
                } catch (e) {
                    reject(e)
                }

            }).catch(err => Promise.reject(err))
        }


        if (abortRequest) {
            AppService.removeRefreshLanguage()
            SsoService.doLogout()
            return Promise.reject(
                _kc.init({onLoad: 'login-required'}).then(auth => console.log(auth))
                    .catch(e => console.log(e))
            )
        }
        return Promise.reject(error);

    });

}