import React, {useEffect} from 'react'
import "./services/TranslationService"
import ProtectedRoute from "./Middlewares/ProtectedRoute";
import {Route, Routes} from "react-router-dom";
import {routes} from "./Router";
import Error400 from "./jsx/pages/Error400"
import Error403 from "./jsx/pages/Error403"
import Error404 from "./jsx/pages/Error404"
import Error500 from "./jsx/pages/Error500"
import Error503 from "./jsx/pages/Error503"
import {toast} from "react-toastify";
import env from "./env";
import {pdfjs} from "react-pdf";
import IsAuthenticated from "./Middlewares/IsAuthenticated";
import AppService from "./services/AppService";
import _kc from "./Util/keycloack";
import LockScreen from "./jsx/pages/LockScreen";
import Unavailable from "./jsx/pages/Unavailable";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function App() {

    toast.dismiss()

    if (env.NODE_ENV !== "development") {
        window['console']['log'] = function () {
        };
    }

    const isAuthenticated = IsAuthenticated();

    useEffect(() => {
        if (!isAuthenticated) {
            AppService.removeRefreshLanguage()
            _kc.init({onLoad: 'login-required'}).then(auth => console.log(auth))
                .catch(e => console.log(e))
        }
    }, [isAuthenticated])

    let routesList = (
        routes.map((data, i) =>
            (
                <Route
                    key={"route_" + i}
                    path={`${data.path}`}
                    element={data.element}
                />
            )
        )
    )


    return <Routes>
        <Route path={"account-locked"} element={<LockScreen/>}></Route>
        <Route path={"unavailable/:ips"} element={<Unavailable/>}></Route>

        <Route element={<ProtectedRoute/>}>
            <Route path="400" element={<Error400/>}/>
            <Route path="403" element={<Error403/>}/>
            <Route path="404" element={<Error404/>}/>
            <Route path="500" element={<Error500/>}/>
            <Route path="503" element={<Error503/>}/>
            <Route path="*" element={<Error404/>}/>
            {routesList}
        </Route>

    </Routes>

}

export default App

