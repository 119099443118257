import {
    LIST_FEE_FAILED,
    LIST_FEE_LOADING,
    LIST_FEE_SUCCESS,
    CREATE_FEE_SUCCESS,
    CREATE_FEE_LOADING,
    CREATE_FEE_FAILED,
    UPDATE_FEE_SUCCESS,
    UPDATE_FEE_LOADING,
    UPDATE_FEE_FAILED, SHOW_FEE_SUCCESS, SHOW_FEE_FAILED, SHOW_FEE_LOADING,
    DELETE_FEE_FAILED,
    DELETE_FEE_LOADING,
    DELETE_FEE_SUCCESS,
} from "../Constants/Fee";


const switcher = (type, action, state) => {
    switch (action.type) {
        case type[0] :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case type[1] :
            return {
                loading: true,
                errors: null,
                data: []
            }
        case type[2] :
            return {
                loading: false,
                data: [],
                errors: action?.payload
            }

        default :
            return state

    }
}
export const feesReducer = (state = {data: []}, action) => {
    return switcher([
        LIST_FEE_SUCCESS,
        LIST_FEE_LOADING,
        LIST_FEE_FAILED,
    ], action, state)
}

export const createFeeReducer = (state = {data: null}, action) => {

    return switcher([
        CREATE_FEE_SUCCESS,
        CREATE_FEE_LOADING,
        CREATE_FEE_FAILED,
    ], action, state)
}
export const editFeeReducer = (state = {data: null}, action) => {

    return switcher([
        UPDATE_FEE_SUCCESS,
        UPDATE_FEE_LOADING,
        UPDATE_FEE_FAILED,
    ], action, state)
}

export const showFeeReducer = (state = {data: null}, action) => {

    return switcher([
        SHOW_FEE_SUCCESS,
        SHOW_FEE_LOADING,
        SHOW_FEE_FAILED,
    ], action, state)
}

export const deleteFeeReducer = (state = {data: null}, action) => {
    return switcher([
        DELETE_FEE_SUCCESS,
        DELETE_FEE_LOADING,
        DELETE_FEE_FAILED,
    ], action, state)
}
