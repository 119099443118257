import {DASHBOARD_LIBRARY_FAILED, DASHBOARD_LIBRARY_LOADING, DASHBOARD_LIBRARY_SUCCESS} from "../Constants/Dashboard";


export const dashboardLibraryReducer = (state = {
    data: {
        totalCourses: 0,
        createdCoursesPerMonth: {},
        earningDays: [],
        totalPayers: {},
        totalEarningInCurrentMonth: 0
    }
}, action) => {
    switch (action.type) {
        case DASHBOARD_LIBRARY_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: {...state.data, ...action.payload}
            }
        case DASHBOARD_LIBRARY_LOADING :
            return {
                loading: true,
                errors: null,
                data: state.data
            }
        case DASHBOARD_LIBRARY_FAILED :
            return {
                loading: false,
                data: state.data,
                errors: action.payload
            }

        default :
            return state

    }
}







