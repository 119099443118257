import {TokenService} from "./TokenService";
import jwt_decode from "jwt-decode";
import api from "./AxiosInstance";

/**
 *
 * @returns {{id: *, username: *}}
 */
export const me = () => {

    if (TokenService.getUser()) {
        //read user token
        const user = jwt_decode(TokenService.getLocalRefreshToken())

        // check user token is expired !
        return user?.user
    }
}


export function librarianUsers(isSpecific = false) {

    if (!isSpecific) {
        return api.get(`/api/v1/librarian/users`);
    }
    return api.get(`/api/v1/librarian/users/Specific`);
}


export function users() {
    return api.get(`/api/v1/users`);
}
