import {
    CREATE_CONTACT_FAILED,
    CREATE_CONTACT_LOADING,
    CREATE_CONTACT_SUCCESS,
    DELETE_CONTACT_FAILED,
    DELETE_CONTACT_LOADING,
    DELETE_CONTACT_SUCCESS
} from "../Constants/Contact";
import {LIST_CONTACT_FAILED, LIST_CONTACT_LOADING, LIST_CONTACT_SUCCESS} from "../Constants/Contact";




export const deleteContactReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_CONTACT_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case DELETE_CONTACT_LOADING :
            return {
                loading: true,
                errors: null,
                data: action.payload
            }
        case DELETE_CONTACT_FAILED :
            return {
                loading: false,
                data: state,
                errors: action.payload
            }

        default :
            return state

    }
}

export const listContactReducer = (state = {}, action) => {
    switch (action.type) {
        case LIST_CONTACT_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case LIST_CONTACT_LOADING :
            return {
                loading: true,
                errors: null,
                data: []
            }
        case LIST_CONTACT_FAILED :
            return {
                loading: false,
                data: [],
                errors: action.payload
            }

        default :
            return state

    }
}

export const createContactReducer = (state = {}, action) => {
    switch (action.type) {
        case CREATE_CONTACT_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case CREATE_CONTACT_LOADING :
            return {
                loading: true,
                errors: null,
                data: []
            }
        case CREATE_CONTACT_FAILED :
            return {
                loading: false,
                data: [],
                errors: action.payload
            }

        default :
            return state

    }
}
