import {CREATE_COLOR_FAILED, CREATE_COLOR_LOADING, CREATE_COLOR_SUCCESS} from "../Constants/Color";
import {UPDATE_COLOR_FAILED, UPDATE_COLOR_LOADING, UPDATE_COLOR_SUCCESS} from "../Constants/Color";
import {DELETE_COLOR_FAILED, DELETE_COLOR_LOADING, DELETE_COLOR_SUCCESS} from "../Constants/Color";
import {LIST_COLOR_FAILED, LIST_COLOR_LOADING, LIST_COLOR_SUCCESS} from "../Constants/Color";
import {SHOW_COLOR_FAILED, SHOW_COLOR_LOADING, SHOW_COLOR_SUCCESS} from "../Constants/Color";
import {GLOBAL_REFRESH} from "../Constants/Refresh";
import {refresh} from "./index";


export const createColorReducer = (state = {}, action) => {
    switch (action.type) {
        case CREATE_COLOR_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case CREATE_COLOR_LOADING :
            return {
                loading: true,
                errors: null,
                data: null
            }
        case CREATE_COLOR_FAILED :
            return {
                loading: false,
                data: null,
                errors: action.payload
            }
        case GLOBAL_REFRESH :
            return refresh()
        default :
            return state

    }
}

export const updateColorReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_COLOR_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case UPDATE_COLOR_LOADING :
            return {
                loading: true,
                errors: null,
                data: action.payload
            }
        case UPDATE_COLOR_FAILED :
            return {
                loading: false,
                data: state,
                errors: action.payload
            }
        case GLOBAL_REFRESH :
            return refresh()

        default :
            return state

    }
}


export const showColorReducer = (state = {}, action) => {
    switch (action.type) {
        case SHOW_COLOR_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case SHOW_COLOR_LOADING :
            return {
                loading: true,
                errors: null,
                data: null
            }
        case SHOW_COLOR_FAILED :
            return {
                loading: false,
                data: null,
                errors: action.payload
            }

        default :
            return state

    }
}


export const deleteColorReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_COLOR_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case DELETE_COLOR_LOADING :
            return {
                loading: true,
                errors: null,
                data: action.payload
            }
        case DELETE_COLOR_FAILED :
            return {
                loading: false,
                data: state,
                errors: action.payload
            }

        default :
            return state

    }
}

export const listColorReducer = (state = {}, action) => {
    switch (action.type) {
        case LIST_COLOR_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case LIST_COLOR_LOADING :
            return {
                loading: true,
                errors: null,
                data: []
            }
        case LIST_COLOR_FAILED :
            return {
                loading: false,
                data: [],
                errors: action.payload
            }

        default :
            return state

    }
}






