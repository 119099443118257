import $ from "jquery";
import i18next, {t} from 'i18next'
import {setLocale} from "yup";
import {ar} from 'yup-locales'
import _default from "yup/lib/locale";

const yup = require("yup");


let keysMustValidAsArray = [
    'permissions',
    'tags',
    'submenu',
    'target_audience'
]

let keysMustValidAsObject = [
    'image',
    'main_img',
    'sub_img',
    'bg_image',
    'attachment',
    'photo'

]

let MAX_FILE_SIZE = 2 // mb

/**
 * nullable keys
 * @type {{}}
 */
let optionalKeys = {}

let acceptedFileType = (key) => {

    if (key === 'attachment') {
        MAX_FILE_SIZE = 1024
        return ['pdf']
    }
    return ['png', 'jpeg', 'jpg']
}


const Validator = async (data, nullable = []) => {

    if (i18next.language === 'ar') {
        setLocale(ar)
    } else {
        setLocale(_default)
    }


    let shapeSchema = {}

    for (const key in data) {

        if (optionalKeys[key]) continue

        if (keysMustValidAsArray.includes(key)) {
            if (nullable.includes(key)) {
                shapeSchema[key] = yup.object().nullable()
            } else {
                shapeSchema[key] = yup.array().required().min(1)
            }

        } else if (keysMustValidAsObject.includes(key)) {
            if (nullable.includes(key)) {
                shapeSchema[key] = yup.object().nullable()
            } else {
                // TODO TRANSLATE
                shapeSchema[key] = yup.object().shape({
                    file: yup.mixed()
                        .test({
                            message: 'Please provide a supported file type',
                            test: (file, context) => {
                                const isValid = acceptedFileType(key).includes((file?.name.split('.').pop()));
                                if (!isValid) context?.createError();
                                return isValid;
                            }
                        })
                        .test({
                            message: `File too big, can't exceed ${MAX_FILE_SIZE} MB`,
                            test: (file) => (file?.size / 1024 / 1024) <= MAX_FILE_SIZE
                        }),
                    dataUrl: yup.string().required(),
                }).required()
            }

        } else {
            if (nullable.includes(key)) {
                shapeSchema[key] = yup.object().nullable()
            } else {
                shapeSchema[key] = yup.string().required()
            }
        }

    }

    let validate = yup.object().shape(shapeSchema)

    return await validate.validate(data, {abortEarly: false}).catch(e => {

        let errorsCollection = e.inner

        if (!errorsCollection) {
            return console.warn("[errorsCollection]: failed to catch errors")
        }

        let errors = {hasErrors: true}

        errorsCollection.forEach(error => {
            // for nested validation || validate object
            if (error.path.includes('.')) {
                let split = error.path.split('.')
                error.path = split[0]
                error.message = error.message.replace("." + split[1], "")
            }

            return errors[error.path] = error.message
        })

        return errors
    });
};


export function errorFormat(errors) {

    if (errors?.response?.data?.data) {
        let collection = errors?.response?.data?.data;
        return Object?.entries(collection)?.forEach(([inputName, errorMessage]) => {

            let selector = $('#' + inputName)

            return selector.parent().append(
                "<span class='errors text-danger'>" + errorMessage.toString()
                    .replace(inputName.toLowerCase(), t(inputName))
                    .replace("_", " ")
                    .replace("-", " ")
                + "</span>"
            )
        })
    }

}

export const callbackErrorsHandler = ({errors, state = {}, resetData = true}, exec) => {
    $('.errors').remove()

    if (errors?.hasOwnProperty('hasErrors') && errors?.hasErrors === true) {
        return Object?.entries(errors)?.forEach(([inputName, errorMessage]) => {
            if (inputName) {
                let selector = $('#' + inputName)
                return selector.parent().append(
                    "<span class='errors text-danger'>" + errorMessage.toString()
                        .replace(inputName, t(inputName))
                        .replace("_", " ")
                        .replace("-", " ")
                    + "</span>"
                )
            }
        })
    } else {
        let result = exec()

        if (resetData) {
            // reset inputs  value to null
            // Object.keys(state).forEach(input => {
            // $('#' + input).val("")
            // $("input[name='" + input + "']").val("")
            // $("select[name='" + input + "']").val("")
            // $("textarea[name='" + input + "']").val("")
            // const form = $('form')
            // if (form) form?.reset()
            // })
        }


        return result
    }

}


export default Validator