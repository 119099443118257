import React from "react";
import {Link} from "react-router-dom";
import {useNavigate} from "react-router";
import {useTranslation} from "react-i18next";

const Error404 = () => {

    const {t} = useTranslation()

    const navigate = useNavigate()
    return (
        <div className="authincation h-100 p-meddle">
            <div className="container h-100">
                <div className="row justify-content-center h-100 align-items-center ">
                    <div className="col-md-5">
                        <div className="form-input-content text-center error-page">
                            <h1 className="error-text font-weight-bold">404</h1>
                            <h4>
                                <i className="fa fa-exclamation-triangle text-warning"/>{" "}
                                {t('The page you were looking for is not found!')}

                            </h4>
                            <p>
                                {t('You may have mistyped the address or the page may have moved.')}
                            </p>
                            <div>
                                <button className={"btn btn-white  pt-2 mt-1"} onClick={() => navigate(-1)}>
                                    <i className="bi bi-arrow-left-circle-fill text-dark "
                                       style={{fontSize: 20}}> {t('GO BACK')} </i>
                                </button>

                                <Link className="btn btn-primary" to="/dashboard">
                                    {t('Back to Home')}
                                </Link>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Error404;
