import {
    LIST_COUNTRIES_FAILED,
    LIST_COUNTRIES_LOADING,
    LIST_COUNTRIES_SUCCESS,
    LIST_COUNTRY_FAILED,
    LIST_COUNTRY_LOADING,
    LIST_COUNTRY_SUCCESS
} from "../Constants/Country";


const switcher = (type, action, state) => {
    switch (action.type) {
        case type[0] :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case type[1] :
            return {
                loading: true,
                errors: null,
                data: []
            }
        case type[2] :
            return {
                loading: false,
                data: [],
                errors: action.payload
            }

        default :
            return state

    }
}
export const getDefaultCountryReducer = (state = {data: []}, action) => {

    return switcher([
        LIST_COUNTRY_SUCCESS,
        LIST_COUNTRY_LOADING,
        LIST_COUNTRY_FAILED,
    ], action, state)
}

export const getCountriesReducer = (state = {}, action) => {

    return switcher([
        LIST_COUNTRIES_SUCCESS,
        LIST_COUNTRIES_LOADING,
        LIST_COUNTRIES_FAILED,
    ], action, state)
}
