import KeycloakProvider from "keycloak-js";
import env from "./../env.js"

const keycloak = new KeycloakProvider({
    url: env.AUTH_URL ,
    realm: env.AUTH_REALM,
    clientId: env.AUTH_CLIENT_ID
});



export default keycloak;