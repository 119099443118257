export const CREATE_FEE_FAILED = "CREATE_FEE_FAILED",
    CREATE_FEE_LOADING = "CREATE_FEE_LOADING",
    CREATE_FEE_SUCCESS = "CREATE_FEE_SUCCESS",
    UPDATE_FEE_FAILED = "UPDATE_FEE_FAILED",
    UPDATE_FEE_LOADING = "UPDATE_FEE_LOADING",
    UPDATE_FEE_SUCCESS = "UPDATE_FEE_SUCCESS",
    DELETE_FEE_FAILED = "DELETE_FEE_FAILED",
    DELETE_FEE_LOADING = "DELETE_FEE_LOADING",
    DELETE_FEE_SUCCESS = "DELETE_FEE_SUCCESS",
    LIST_FEE_FAILED = "LIST_FEE_FAILED",
    LIST_FEE_LOADING = "LIST_FEE_LOADING",
    LIST_FEE_SUCCESS = "LIST_FEE_SUCCESS",
    SHOW_FEE_LOADING = "SHOW_FEE_LOADING",
    SHOW_FEE_FAILED = "SHOW_FEE_FAILED",
    SHOW_FEE_SUCCESS = "SHOW_FEE_SUCCESS"