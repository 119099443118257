import React from "react";
import NoTableResult from '../../../images/no.png'

import {Image} from "react-bootstrap";
import {t} from 'i18next'

const NoResult = () => {
    return <div className="col-12">
        <div className="card">
            <div className="card-body justify-content-center">
                <div className="w-100 d-flex justify-content-center  ">
                    <Image title={t('no_result')} className={"rounded-circle"} src={NoTableResult}/>
                </div>
                <div className="col-12  p-2  center-block text-danger text-sm text-center">
                    {t('no_result')}
                </div>
            </div>


        </div>
    </div>
}

export const TableNoResult = () => {
    return <div>
        <div className="row offset-md-4 col-md-4 d-flex justify-content-center m-xs-5 ">
            <Image title={"no_result"} src={NoTableResult} className={"rounded-circle"}/>
        </div>

        <div className="col-12  py-2  center-block text-danger text-sm text-center">
            {t('no_result_table')}
        </div>
    </div>
}


export default React.memo(NoResult)