import AppService from "../services/AppService";
import {useEffect} from "react";

export default function () {

    const language = AppService.getLocalLanguage()
    useEffect(() => {
        for (let elementsByClassNameElement of document.getElementsByClassName('inputDir')) {
            elementsByClassNameElement.setAttribute('dir', language === 'ar' ? 'rtl' : 'ltr')
        }
    }, [language]);



    return language
}


