export const
    CREATE_EVENT_FAILED = "CREATE_EVENT_FAILED",
    CREATE_EVENT_LOADING = "CREATE_EVENT_LOADING",
    CREATE_EVENT_SUCCESS = "CREATE_EVENT_SUCCESS",
    UPDATE_EVENT_FAILED = "UPDATE_EVENT_FAILED",
    UPDATE_EVENT_LOADING = "UPDATE_EVENT_LOADING",
    UPDATE_EVENT_SUCCESS = "UPDATE_EVENT_SUCCESS",
    DELETE_EVENT_FAILED = "DELETE_EVENT_FAILED",
    DELETE_EVENT_LOADING = "DELETE_EVENT_LOADING",
    DELETE_EVENT_SUCCESS = "DELETE_EVENT_SUCCESS",
    LIST_EVENT_FAILED = "LIST_EVENT_FAILED",
    LIST_EVENT_LOADING = "LIST_EVENT_LOADING",
    LIST_EVENT_SUCCESS = "LIST_EVENT_SUCCESS",
    SHOW_EVENT_LOADING = "SHOW_EVENT_LOADING",
    SHOW_EVENT_FAILED = "SHOW_EVENT_FAILED",
    SHOW_EVENT_SUCCESS = "SHOW_EVENT_SUCCESS"