export const CREATE_TRANSACTION_FAILED = 'CREATE_TRANSACTION_FAILED',
    CREATE_TRANSACTION_LOADING = 'CREATE_TRANSACTION_LOADING',
    CREATE_TRANSACTION_SUCCESS = 'CREATE_TRANSACTION_SUCCESS',
    UPDATE_TRANSACTION_FAILED = 'UPDATE_TRANSACTION_FAILED',
    UPDATE_TRANSACTION_LOADING = 'UPDATE_TRANSACTION_LOADING',
    UPDATE_TRANSACTION_SUCCESS = 'UPDATE_TRANSACTION_SUCCESS',
    DELETE_TRANSACTION_FAILED = 'DELETE_TRANSACTION_FAILED',
    DELETE_TRANSACTION_LOADING = 'DELETE_TRANSACTION_LOADING',
    DELETE_TRANSACTION_SUCCESS = 'DELETE_TRANSACTION_SUCCESS',
    LIST_TRANSACTION_FAILED = 'LIST_TRANSACTION_FAILED',
    LIST_TRANSACTION_LOADING = 'LIST_TRANSACTION_LOADING',
    LIST_TRANSACTION_SUCCESS = 'LIST_TRANSACTION_SUCCESS',
    SHOW_TRANSACTION_LOADING = 'SHOW_TRANSACTION_LOADING',
    SHOW_TRANSACTION_FAILED = 'SHOW_TRANSACTION_FAILED',
    SHOW_TRANSACTION_SUCCESS = 'SHOW_TRANSACTION_SUCCESS',
    MY_TRANSACTION_STATISTICS_LOADING = 'MY_TRANSACTION_STATISTICS_LOADING',
    MY_TRANSACTION_STATISTICS_FAILED = 'MY_TRANSACTION_STATISTICS_FAILED',
    MY_TRANSACTION_STATISTICS_SUCCESS = 'MY_TRANSACTION_STATISTICS_SUCCESS',
    MY_TRANSACTION_LOADING = 'MY_TRANSACTION_LOADING',
    MY_TRANSACTION_FAILED = 'MY_TRANSACTION_FAILED',
    MY_TRANSACTION_SUCCESS = 'MY_TRANSACTION_SUCCESS'