import React from "react";
import {useTranslation} from "react-i18next";

const Alert = ({message, type = 'error'}) => {

    const {t} = useTranslation()

    if (message && typeof message === 'string') {
        message = message.replace("%l", t("library")) // lib
            .replace("&&ur", t("user_requests"))
            .replace("%ap", t("already_applied"))
            .replace("%nf", t("not_found"))
            .replace("%id", t('id'))
            .replace("%state", t('state'))
            .replace("%u", t('user'))
            .replace("%cat", t('category'))
            .replace("%c", t('color'))
            .replace("%e", t('event'))
            .replace("%h", t('hero'))
            .replace("%m", t('menu'))
            .replace("%ba", t('banner'))
            .replace("%t", t('translate'))
            .replace("%f", t('faq'))
            .replace("%co", t('course'))
            .replace("%b", t('blog'))
            .replace("%comment", t('comment'))
            .replace("%u", t('user'))
            .replace("!!cov", t('course_violate_terms'))
            .replace("??alreadyExist", t('already_exist'))
            .replace("%cannot_be_free", t('cannot_be_free'))
            .replace("%due_to_price_more_than_zero", t('due_to_price_more_than_zero'))
            .replace("%Contact", t('contact'))
            .replace("%psp", t('psp'))
            .replace("~fee", t('fee'))
            .replace('%order', t('order'))

        if (message?.startsWith("@")) {
            message = t(message)
        }
    } else if (message && typeof message === 'object') {
        message = Object.values(message)[1]
    } else if (message && Array.isArray(message)) {
        message = message[0]
    }


    return (
        message ?
            <div className="m-2 text-center">

                {
                    type === 'success' && <div className="alert alert-info solid alert-dismissible fade show inputDir">
                        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none"
                             strokeLinecap="round" strokeLinejoin="round" className="mx-2">
                            <polyline points="9 11 12 14 22 4"></polyline>
                            <path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"></path>
                        </svg>
                        <strong>{t("Success")}!</strong> {message}

                    </div>
                }

                {
                    type === 'error' && <div className="alert alert-danger solid alert-dismissible fade show inputDir">
                        <svg viewBox="0 0 24 24" width="24 " height="24" stroke="currentColor" strokeWidth="2" fill="none"
                             strokeLinecap="round" strokeLinejoin="round" className="mx-2">
                            <polygon
                                points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon>
                            <line x1="15" y1="9" x2="9" y2="15"></line>
                            <line x1="9" y1="9" x2="15" y2="15"></line>
                        </svg>
                        <strong>{t("Error")}!</strong> {message}

                    </div>
                }

                {
                    type === 'warn' && <div className="alert alert-warning solid alert-dismissible fade show inputDir">
                        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none"
                             strokeLinecap="round" strokeLinejoin="round" className="mx-2">
                            <path
                                d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
                            <line x1="12" y1="9" x2="12" y2="13"></line>
                            <line x1="12" y1="17" x2="12.01" y2="17"></line>
                        </svg>
                        <strong>{t("Warning")}!</strong> {message}

                    </div>
                }


                {
                    type === 'info' && <div className="alert alert-info solid alert-dismissible fade show inputDir">
                        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none"
                             strokeLinecap="round" strokeLinejoin="round" className="mx-2">
                            <circle cx="12" cy="12" r="10"></circle>
                            <line x1="12" y1="16" x2="12" y2="12"></line>
                            <line x1="12" y1="8" x2="12.01" y2="8"></line>
                        </svg>
                        <strong>{t("Info")}!</strong> {message}

                    </div>
                }

            </div>
            : null
    )
}


export default React.memo(Alert)