import React, {useState} from "react";
/// React router dom
import logoFull from '../../../images/logo-full.png';
import {useDispatch} from "react-redux";
import {AppHandler} from "../../../store/actions/AppActions";


const NavHeader = () => {
    const [toggle, setToggle] = useState(false);

    const dispatch = useDispatch()

    const onClick = () => {
        setToggle(!toggle);
        dispatch(AppHandler({
            "openMenuToggle": toggle,
            "windowWidth": window.innerWidth
        }))
    }


    return (
        <div className="nav-header d-flex justify-content-around">

            <div className="  col-sm-12 mt-2  col-md-7 offset-md-3 ">
                <img className="m-0" width={100} height={100}
                     style={{maxHeight: "100%", maxWidth: "100%", display: "block", height: "auto"}}
                     src={logoFull} alt={"logo"}/>
            </div>
            <div
                className="nav-control col-sm-7 col-md-3"
                onClick={onClick}
            >
                <div className={`hamburger ${toggle ? "is-active" : ""}`}>
                    <span className="line"></span>
                    <span className="line"></span>
                    <span className="line"></span>
                </div>
            </div>
        </div>
    );
};

export default NavHeader;
