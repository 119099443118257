import {CREATE_LIBRARY_FAILED, CREATE_LIBRARY_LOADING, CREATE_LIBRARY_SUCCESS} from "../Constants/Library";
import {UPDATE_LIBRARY_FAILED, UPDATE_LIBRARY_LOADING, UPDATE_LIBRARY_SUCCESS} from "../Constants/Library";
import {DELETE_LIBRARY_FAILED, DELETE_LIBRARY_LOADING, DELETE_LIBRARY_SUCCESS} from "../Constants/Library";
import {LIST_LIBRARY_FAILED, LIST_LIBRARY_LOADING, LIST_LIBRARY_SUCCESS} from "../Constants/Library";
import {SHOW_LIBRARY_LOADING, SHOW_LIBRARY_FAILED, SHOW_LIBRARY_SUCCESS} from "../Constants/Library";
import {
    MY_LIBRARY_LOADING,
    MY_LIBRARY_FAILED,
    MY_LIBRARY_SUCCESS
} from "../Constants/Library";
import {
    getLibraries,
    createLibrary,
    updateLibrary,
    deleteLibrary,
    showLibrary,
    getMyLibrary
} from '../../services/LibraryService'
import {handlePayloadErrors} from "../../Util/ErrorsHandler";
import {errorFormat} from "#validator";
import {isUser} from "../../Role";


const failedToPostMessageData = "failed to %s library data"

export const getLibrariesHandler = (skipRoles = false) => async (dispatch) => {
    try {
        dispatch({type: LIST_LIBRARY_LOADING})

        const {data} = await getLibraries(skipRoles)

        if (data && !data.success) {
            return dispatch({
                type: LIST_LIBRARY_FAILED,
                payload: failedToPostMessageData.replace('%s', "get")
            })
        }

        dispatch({
            type: LIST_LIBRARY_SUCCESS, payload: data?.data
        })
    } catch (e) {
        dispatch({
            type: LIST_LIBRARY_FAILED, payload: handlePayloadErrors(e)
        })
    }
}

export const getMyLibraryHandler = () => async (dispatch) => {
    try {
        dispatch({type: MY_LIBRARY_LOADING})

        if (isUser()) {
            dispatch({
                type: MY_LIBRARY_SUCCESS, payload: {totalFunds: 0}
            })
            return;
        }
        const {data} = await getMyLibrary()

        if (data && !data.success) {
            return dispatch({
                type: MY_LIBRARY_FAILED,
                payload: failedToPostMessageData.replace('%s', "get")
            })
        }

        dispatch({
            type: MY_LIBRARY_SUCCESS, payload: data?.data
        })
    } catch (e) {
        dispatch({
            type: MY_LIBRARY_FAILED, payload: handlePayloadErrors(e)
        })
    }
}


export const createLibraryHandler = (payload) => async (dispatch) => {

    const form = new FormData()

    try {
        dispatch({type: CREATE_LIBRARY_LOADING})

        Object.entries(payload).forEach(([key, value]) => form.append(key, value))

        const {data} = await createLibrary(form)

        if (data && !data.success) {
            return dispatch({
                type: CREATE_LIBRARY_FAILED,
                payload: failedToPostMessageData.replace('%s', "create")
            })
        }

        dispatch({
            type: CREATE_LIBRARY_SUCCESS, payload: data?.data
        })
    } catch (e) {
        errorFormat(e)

        dispatch({
            type: CREATE_LIBRARY_FAILED, payload: handlePayloadErrors(e)
        })
    }
}

export const updateLibraryHandler = (payload, id) => async (dispatch) => {
    const form = new FormData()

    try {
        dispatch({type: UPDATE_LIBRARY_LOADING})
        Object.entries(payload).forEach(([key, value]) => {
            form.append(key, value)
        })

        const {data} = await updateLibrary(form, id)
        if (data && !data.success) {
            return dispatch({
                type: UPDATE_LIBRARY_FAILED,
                payload: failedToPostMessageData.replace('%s', "update")
            })
        }

        dispatch({
            type: SHOW_LIBRARY_SUCCESS, payload: data?.data
        })

        dispatch({
            type: UPDATE_LIBRARY_SUCCESS, payload: data?.data
        })
    } catch (e) {
        dispatch({
            type: UPDATE_LIBRARY_FAILED, payload: handlePayloadErrors(e)
        })
    }
}


export const showLibraryHandler = (id) => async (dispatch) => {

    try {
        dispatch({type: SHOW_LIBRARY_LOADING})

        const {data} = await showLibrary(id)

        if (!data?.success) {
            return dispatch({
                type: SHOW_LIBRARY_FAILED,
                payload: failedToPostMessageData.replace('%s', "show")
            })
        }

        dispatch({
            type: SHOW_LIBRARY_SUCCESS, payload: data?.data
        })
    } catch (e) {
        dispatch({
            type: SHOW_LIBRARY_FAILED, payload: handlePayloadErrors(e)
        })

        return e
    }
}

export const deleteLibraryHandler = (id) => async (dispatch, getState) => {

    try {
        dispatch({type: DELETE_LIBRARY_LOADING})

        const {data} = await deleteLibrary(id)
        if (data && !data.success) {
            return dispatch({
                type: DELETE_LIBRARY_FAILED,
                payload: failedToPostMessageData.replace('%s', "delete")
            })
        }

        dispatch({
            type: DELETE_LIBRARY_SUCCESS, payload: data?.data
        })

        const {listLibrary: {data: libraries}} = getState()

        let index = libraries.findIndex(item => item._id === data?.data._id)

        let picked = libraries[index]

        picked.state = data?.data.state

        libraries[index] = picked

        dispatch({
            type: LIST_LIBRARY_SUCCESS, payload: libraries
        })

    } catch (e) {
        dispatch({
            type: DELETE_LIBRARY_FAILED, payload: handlePayloadErrors(e)
        })

        return e
    }
}


