import api from './AxiosInstance';

export function getLibraries(skipRoles = false) {

    if (skipRoles) return api.get(`/api/v1/libraries-list?skipRoles=1`);

    return api.get(`/api/v1/libraries-list`);
}

export function getMyLibrary() {
    return api.get(`/api/v1/library`);
}


export function createLibrary(data) {
    return api.post(`/api/v1/library-create`, data);
}

export function updateLibrary(library, id) {
    return api.post(`/api/v1/library-edit/${id}`, library);
}

export function showLibrary(id) {
    return api.get(`/api/v1/library/${id}`);
}

export function deleteLibrary(id) {
    return api.post(`/api/v1/library-delete/${id}`, {
        state: "2"
    });
}

