import {
    EMAIL_BLOCK_FAILED,
    EMAIL_BLOCK_LOADING,
    EMAIL_BLOCK_SUCCESS,
    LIST_BLOCK_FAILED,
    LIST_BLOCK_LOADING,
    LIST_BLOCK_SUCCESS
} from "../Constants/Block";
import {
    CREATE_BLOCK_FAILED,
    CREATE_BLOCK_LOADING,
    CREATE_BLOCK_SUCCESS,
    DELETE_BLOCK_FAILED,
    DELETE_BLOCK_LOADING,
    DELETE_BLOCK_SUCCESS,
    SHOW_BLOCK_FAILED,
    SHOW_BLOCK_LOADING,
    SHOW_BLOCK_SUCCESS,
    UPDATE_BLOCK_FAILED,
    UPDATE_BLOCK_LOADING,
    UPDATE_BLOCK_SUCCESS
} from "../Constants/Block";
import {GLOBAL_REFRESH} from "../Constants/Refresh";
import {refresh} from "./index";

export const BlockReducer = (state = {}, action) => {

    switch (action.type) {
        case LIST_BLOCK_FAILED :
            return {
                loading: false,
                errors: action.payload,
                data: []
            }
        case LIST_BLOCK_LOADING :
            return {
                loading: true,
                errors: null,
                data: []
            }
        case LIST_BLOCK_SUCCESS :
            return {
                loading: false,
                data:  action.payload,
                errors: null
            }

        default :
            return state

    }
}

export const createBlockReducer = (state = {}, action) => {
    switch (action.type) {
        case CREATE_BLOCK_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case CREATE_BLOCK_LOADING :
            return {
                loading: true,
                errors: null,
                data: null
            }
        case CREATE_BLOCK_FAILED :
            return {
                loading: false,
                data: null,
                errors: action.payload
            }
        case GLOBAL_REFRESH :
            return refresh()
        default :
            return state

    }
}

export const updateBlockReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_BLOCK_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case UPDATE_BLOCK_LOADING :
            return {
                loading: true,
                errors: null,
                data: action.payload
            }
        case UPDATE_BLOCK_FAILED :
            return {
                loading: false,
                data: state,
                errors: action.payload
            }
        case GLOBAL_REFRESH :
            return refresh()

        default :
            return state

    }
}


export const showBlockReducer = (state = {}, action) => {
    switch (action.type) {
        case SHOW_BLOCK_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case SHOW_BLOCK_LOADING :
            return {
                loading: true,
                errors: null,
                data: null
            }
        case SHOW_BLOCK_FAILED :
            return {
                loading: false,
                data: null,
                errors: action.payload
            }

        default :
            return state

    }
}


export const emailBlockReducer = (state = {}, action) => {
    switch (action.type) {
        case EMAIL_BLOCK_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case EMAIL_BLOCK_LOADING :
            return {
                loading: true,
                errors: null,
                data: action.payload
            }
        case EMAIL_BLOCK_FAILED :
            return {
                loading: false,
                data: state,
                errors: action.payload
            }

        default :
            return state

    }
}

export const deleteBlockReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_BLOCK_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case DELETE_BLOCK_LOADING :
            return {
                loading: true,
                errors: null,
                data: action.payload
            }
        case DELETE_BLOCK_FAILED :
            return {
                loading: false,
                data: state,
                errors: action.payload
            }

        default :
            return state

    }
}
