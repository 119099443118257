import React from 'react';
import {useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';
import {LogoutHandler} from "../../../store/actions/UserAction";
import {useTranslation} from "react-i18next";
import AppService from "../../../services/AppService";


function LogoutPage() {
    const dispatch = useDispatch();

    const {t} = useTranslation()

    function onLogout(e) {
        e?.preventDefault()
        AppService.removeRefreshLanguage()
        dispatch(LogoutHandler());
    }

    return (
        <>
            <Link to={'#'} className="dropdown-item ai-icon" onClick={onLogout}>
                <svg
                    id="icon-logout" xmlns="http://www.w3.org/2000/svg"
                    className="text-danger me-1" width={18} height={18} viewBox="0 0 24 24"
                    fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"
                >
                    <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"/>
                    <polyline points="16 17 21 12 16 7"/>
                    <line x1={21} y1={12} x2={9} y2={12}/>
                </svg>
                <span className="ms-2">{t('logout')} </span>
            </Link>
        </>
    )
}


export default LogoutPage