import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import AppService from "../../services/AppService";
import {useNavigate, useParams} from "react-router";
import IsAuthenticated from "../../Middlewares/IsAuthenticated";
import _kc from "../../Util/keycloack";

const Unavailable = () => {
    const {ips} = useParams()
    const redirect = useNavigate()
    const {t} = useTranslation()
    const isAuthenticated = IsAuthenticated();
    const lang = AppService.getLocalLanguage()
    const [ip, setIp] = useState(null)

    const getIp = async () => {
        const response = await fetch("https://ipapi.co/json/")
        const data = await response.json()
        setIp(data.ip)
    }

    useEffect(() => {
        getIp()
    }, [ips])

    useEffect(() => {
        if (ip !== null && ip !== ips) {
            return setTimeout(() => redirect('/'), 1000)
        }
    }, [ip, ips, redirect])


    useEffect(() => {
        if (!isAuthenticated) {
            AppService.removeRefreshLanguage()
            _kc.init({onLoad: 'login-required'}).then(auth => console.log(auth))
                .catch(e => console.log(e))
        }
    }, [isAuthenticated])


    const content = lang === "ar" ?
        (
            <div>
                نأمل أن تجدك هذه الرسالة في حالة جيدة. نحن نقدر اهتمامك بخدماتنا و
                الثقة التي وضعتها في شركتنا. ومع ذلك ، يجب أن نعلمك مع الأسف
                أن خدماتنا غير متاحة لك حاليًا بسبب انتهاك شروطنا و
                الشروط. <br/>
                <br/>
                في مكتاباتي ، نلتزم بأعلى معايير الاحتراف و
                السلوك الأخلاقي. نسعى جاهدين للحفاظ على علاقة عمل إيجابية ومحترمة مع
                جميع عملائنا. للأسف ، أثناء مراجعة حسابك ، اكتشفنا وجود خرق لـ
                الشروط والأحكام الخاصة بنا ، خاصة فيما يتعلق بعدد كبير جدًا من المحاولات للوصول إلى العناصر الفنية
                النقاط. <br/>
                <br/>
                بعد إجراء تحقيق شامل ودراسة متأنية ، خلصنا إلى أنه كذلك
                ضروري لتعليق خدماتنا لمؤسستك. لم يتم اتخاذ هذا القرار بسهولة ،
                لأننا نفهم التأثير المحتمل الذي قد يكون له على عملياتك. ومع ذلك ، نحن بحزم
                نؤمن بالتمسك بالمبادئ والقيم التي تقوم عليها أعمالنا.<br/>
                <br/>
                يرجى ملاحظة أننا جاهزون لمناقشة هذا الأمر بشكل أكبر وتقديم أي معلومات إضافية
                المعلومات أو الإيضاحات التي قد تحتاجها. يمكنك الاتصال بفريق دعم العملاء لدينا
                على <b> support@mktabati.com </b>.<br/>
                <br/>

                شكرا لتفهمك في هذا الأمر. <br/>
                <br/>

                مع خالص التقدير ، <br/>

            </div>
        ) : (
            <div>
                We hope this letter finds you well. We appreciate your interest in our services and the
                trust you have placed in our company. However, it is with regret that we must inform you
                that our services are currently not available to you due to a violation of our terms and
                conditions.<br/>
                <br/>
                At Mktabati, we hold ourselves to the highest standards of professionalism and
                ethical conduct. We strive to maintain a positive and respectful working relationship with
                all our clients. Unfortunately, in reviewing your account, we have identified a breach of
                our terms and conditions, specifically in relation to Too Many Tries To Access Technicals
                Points.<br/>
                <br/>

                Upon thorough investigation and careful consideration, we have concluded that it is
                necessary to suspend our services to your organization. This decision was not made lightly,
                as we understand the potential impact it may have on your operations. However, we firmly
                believe in upholding the principles and values that underpin our business.<br/>
                <br/>

                We kindly request that you address the violation in question and take the necessary steps to
                rectify the situation. Once the matter has been resolved to our satisfaction, we would be
                open to reconsidering the provision of our services to your organization.<br/>
                <br/>


                Please note that we are available to discuss this matter further and provide any additional
                information or clarification that you may require. You can contact our customer support team
                at <b>support@mktabati.com</b> .<br/>
                <br/>

                We genuinely regret any inconvenience this may cause, and we appreciate your understanding
                and cooperation in this matter. We remain committed to maintaining the highest level of
                integrity and quality in the services we provide.<br/>
                <br/>

                Thank you for your understanding in this matter.<br/>
                <br/>

                <b>Mktabati Team.</b> <br/>
            </div>
        )


    return (
        <div className="authincation " style={{
            backgroundColor: "rgba(76,143,215,0.82)"
        }}>
            <div className="row justify-content-center  align-items-center ">
                <div className="col-1"></div>
                <div className="col-5 mt-4 ">
                    <div className="authincation-content mt-4 shadow" dir={lang === "ar" ? "rtl" : 'ltr'}>
                        <div className="row no-gutters">
                            <div className="col-12">
                                <div className="auth-form">
                                    <h4 className="text-center mb-4 ">{t("Account Locked")}</h4>
                                    <h6>{t('dear')} {t("the_user")},</h6>
                                    <br/>
                                    <br/>
                                    {content}
                                    <h6 className={"text-sm mt-2"}>{t("Mktabati Team.")}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Unavailable;
