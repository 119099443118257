import {CREATE_BLOG_FAILED, CREATE_BLOG_LOADING, CREATE_BLOG_SUCCESS} from "../Constants/Blog";
import {UPDATE_BLOG_FAILED, UPDATE_BLOG_LOADING, UPDATE_BLOG_SUCCESS} from "../Constants/Blog";
import {DELETE_BLOG_FAILED, DELETE_BLOG_LOADING, DELETE_BLOG_SUCCESS} from "../Constants/Blog";
import {LIST_BLOG_FAILED, LIST_BLOG_LOADING, LIST_BLOG_SUCCESS} from "../Constants/Blog";
import {SHOW_BLOG_FAILED, SHOW_BLOG_LOADING, SHOW_BLOG_SUCCESS} from "../Constants/Blog";
import {GLOBAL_REFRESH} from "../Constants/Refresh";
import {refresh} from "./index";


export const createBlogReducer = (state = {}, action) => {
    switch (action.type) {
        case CREATE_BLOG_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case CREATE_BLOG_LOADING :
            return {
                loading: true,
                errors: null,
                data: null
            }
        case CREATE_BLOG_FAILED :
            return {
                loading: false,
                data: null,
                errors: action.payload
            }
        case GLOBAL_REFRESH :
            return refresh()
        default :
            return state

    }
}

export const updateBlogReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_BLOG_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case UPDATE_BLOG_LOADING :
            return {
                loading: true,
                errors: null,
                data: action.payload
            }
        case UPDATE_BLOG_FAILED :
            return {
                loading: false,
                data: state,
                errors: action.payload
            }
        case GLOBAL_REFRESH :
            return refresh()

        default :
            return state

    }
}


export const showBlogReducer = (state = {}, action) => {
    switch (action.type) {
        case SHOW_BLOG_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case SHOW_BLOG_LOADING :
            return {
                loading: true,
                errors: null,
                data: null
            }
        case SHOW_BLOG_FAILED :
            return {
                loading: false,
                data: null,
                errors: action.payload
            }

        default :
            return state

    }
}


export const deleteBlogReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_BLOG_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case DELETE_BLOG_LOADING :
            return {
                loading: true,
                errors: null,
                data: action.payload
            }
        case DELETE_BLOG_FAILED :
            return {
                loading: false,
                data: state,
                errors: action.payload
            }

        default :
            return state

    }
}

export const listBlogReducer = (state = {}, action) => {
    switch (action.type) {
        case LIST_BLOG_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case LIST_BLOG_LOADING :
            return {
                loading: true,
                errors: null,
                data: []
            }
        case LIST_BLOG_FAILED :
            return {
                loading: false,
                data: [],
                errors: action.payload
            }

        default :
            return state

    }
}






