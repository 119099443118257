import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import {getSymbol, tCreate, tEdit, tList} from "../../../Helper/functions";
import RightSideBar from "./RightSideBar";
import {Helmet} from "react-helmet-async";
import {useDispatch, useSelector} from "react-redux";
import {fireToastNotificationHandler} from "../../../store/actions/NotificationsActions";
import NotificationSound from "../../../Sounds/notification-sound.mp3";
import $ from 'jquery'
import {useTranslation} from "react-i18next";
import {setMenuValueHandler} from "../../../store/actions/AppActions";
import {can} from "../../../Role";
import {getMyLibraryHandler} from "../../../store/actions/LibraryActions";
import {getCountryHandler} from "../../../store/actions/CountryActions";

const singleIndexOfPath = [
    'translate',
    'profile',

]

const skipSplit = [
    'library-profile'
];


const Header = ({onNote}) => {
    const {t, i18n} = useTranslation()
    const dispatch = useDispatch()
    const {notifications: {data: {count, data: listOfNotifications}, toast}} = useSelector(s => s)
    const {myLibrary: {data: library}} = useSelector(s => s)
    const {country: {data: country}} = useSelector(s => s)

    const lang = localStorage.getItem("lang") ? localStorage.getItem("lang") : localStorage.setItem("lang", "en")
    const [rightSelect, setRightSelect] = useState(lang);
    const [headerFix, setHeaderFix] = useState(false);
    const [headerName, setHeaderName] = useState("");

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setHeaderFix(window.scrollY > 50);
        });
    }, []);


    let path = window.location.pathname.split("/");
    let name
    if (path.length === 3) {
        name = path[path.length - 2].split("-");
    } else {
        name = path[path.length - 1].split("-");
    }

    let filterName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;


    let finalName = filterName.includes("app")
        ? filterName.filter((f) => f !== "app")
        : filterName.includes("ui")
            ? filterName.filter((f) => f !== "ui")
            : filterName.includes("uc")
                ? filterName.filter((f) => f !== "uc")
                : filterName.includes("basic")
                    ? filterName.filter((f) => f !== "basic")
                    : filterName.includes("jquery")
                        ? filterName.filter((f) => f !== "jquery")
                        : filterName.includes("table")
                            ? filterName.filter((f) => f !== "table")
                            : filterName.includes("page")
                                ? filterName.filter((f) => f !== "page")
                                : filterName.includes("email")
                                    ? filterName.filter((f) => f !== "email")
                                    : filterName.includes("ecom")
                                        ? filterName.filter((f) => f !== "ecom")
                                        : filterName.includes("chart")
                                            ? filterName.filter((f) => f !== "chart")
                                            : filterName.includes("editor")
                                                ? filterName.filter((f) => f !== "editor")
                                                : filterName;

    if (skipSplit.includes(path[1])) {
        finalName = t(path[1].replace("-", "_"))
    } else if (singleIndexOfPath.includes(filterName[0])) {
        finalName = t(filterName[0]).split(" ")
    } else if (filterName[1] === 'create') {
        finalName = tCreate({t, i18n}, filterName[0].toString().toLowerCase()).split(" ")
    } else if (filterName[1] === 'edit') {
        finalName = tEdit({t, i18n}, filterName[0].toString().toLowerCase()).split(" ")
    } else if (filterName[1] === 'list') {
        finalName = tList({t, i18n}, filterName[0].toString().toLowerCase()).split(" ")
    } else {
        finalName = Array.isArray(filterName) && filterName?.length === 2 ? t(filterName[1]) : finalName
    }

    let audio = new Audio(NotificationSound);

    useEffect(() => {
        $("html").one("click", function () {
            if (toast === true) {
                audio.play().then(r => dispatch(fireToastNotificationHandler(t)))
            }
        });
    }, [toast, audio, dispatch])


    useEffect(() => {
        if (finalName && typeof finalName === 'string') {
            setHeaderName(finalName?.replace(/([A-Z])/g, ' $1')?.trim())
        } else if (Array.isArray(finalName) && finalName?.length > 1) {
            setHeaderName(finalName?.join(" "))
        } else if (Array.isArray(finalName) && finalName?.length === 1) {
            setHeaderName(t(finalName[0]))
        } else if (!finalName) {
            setHeaderName(t("dashboard"))
        }
    }, [finalName, t])


    useEffect(() => {
        dispatch(getMyLibraryHandler())
    }, [dispatch])

    useEffect(() => {
        dispatch(getCountryHandler())
    }, [dispatch])

    const setMenuValue = (e) => {
        dispatch(setMenuValueHandler(e.target.value))
        // TODO SOLVE SEARCH NAV BAR
        // const sectionWrapper = document.querySelectorAll('.metismenu ul')
        //
        // for (let sectionWrapperElement of sectionWrapper) {
        //     if (sectionWrapperElement) {
        //         sectionWrapperElement.classList.add('mm-collapse')
        //     }
        // }
    }

    return (
        <div className={`header ${headerFix ? "is-fixed" : ""}`}>
            <Helmet>
                <title>{headerName}</title>
            </Helmet>
            <span id={"clickMe"}></span>
            <div className="header-content">
                <nav className="navbar navbar-expand">
                    <div className="collapse navbar-collapse justify-content-between">
                        <div className="header-left">
                            <div
                                className="dashboard_bar"
                                style={{textTransform: "capitalize"}}
                            >
                                {headerName}
                            </div>
                        </div>

                        <div className="navbar-nav header-right">
                            <div
                                className="nav-item d-flex align-items-center text-white mx-sm-2 mx-lg-3 mx-md-0 mt-md-3 pt-md-4 d-sm-none d-md-block  mw-100">
                                {can('showTotalFunds') && library?.totalFunds &&
                                    <strong className={"text-white"}>
                                        {
                                            t('total_earnings') + ": " + getSymbol(country, library) + " " + parseFloat(library?.totalFunds).toFixed(2)
                                        }
                                    </strong>
                                }
                            </div>
                            <div className="nav-item d-flex align-items-center">
                                <div className="input-group search-area">



                                    <span className="input-group-text">
                            <Link to={"#"}><svg width="24" height="24"
                                                viewBox="0 0 32 32" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
							<path
                                d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                                fill="var(--secondary)"/>
							</svg>
							</Link>
                        </span>


                                    <input
                                        id={"search_menu"}
                                        type="text"
                                        className="form-control"
                                        placeholder={t('search_here')}
                                        onChange={setMenuValue}
                                    />
                                </div>
                            </div>
                            <RightSideBar rightSelect={rightSelect} setRightSelect={setRightSelect} lang={lang}
                                          onNote={onNote}
                                          count={count}
                                          listOfNotifications={listOfNotifications}/>

                        </div>


                    </div>
                </nav>
            </div>
        </div>
    );
};

export default React.memo(Header);
