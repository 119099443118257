import React, {Suspense, useEffect, useState} from 'react';
import {Outlet} from "react-router-dom";
import IsAuthenticated from "./IsAuthenticated";
import Nav from "../jsx";
import {Loader} from "../jsx/components/Crud/Loader";
import {deny, isUser} from "../Role";
import {useNavigate} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {AppHandler} from "../store/actions/AppActions";
import {getPathLocation} from "../Helper/functions";
import _kc from "../Util/keycloack";
import AppService from "../services/AppService";
import {getUserHandler} from "../store/actions/UserAction";


function ProtectedRoute() {
    const isAuthenticated = IsAuthenticated();
    const redirect = useNavigate()
    const dispatch = useDispatch()
    const [isBlocked, setIsBlocked] = useState(false)
    const {getUserDetails: {data: userDetails}} = useSelector(s => s)
    const role = isUser()


    useEffect(() => {
        if (role) {
            redirect('/profile')
        }
    }, [role])

    
    useEffect(() => {
        const body = document.querySelector("body");
        let resizeWindow = () => {

            dispatch(AppHandler({
                "changeWidth": window.innerWidth,
                "changeHeight": window.innerHeight,
            }))

            window.innerWidth >= 768 && window.innerWidth < 1024
                ? body.setAttribute("data-sidebar-style", "mini")
                : window.innerWidth <= 768
                    ? body.setAttribute("data-sidebar-style", "overlay")
                    : body.setAttribute("data-sidebar-style", "full");
        };
        resizeWindow();
        window.addEventListener("resize", resizeWindow);
        return () => window.removeEventListener("resize", resizeWindow);
    }, [dispatch]);

    /// Path
    let path = getPathLocation()

    const isDenied = deny(path)


    useEffect(() => {
        if (isDenied) {
            setTimeout(() => redirect("/403"), 2000)
        }
    }, [isDenied]);

    useEffect(() => {
        if (!isAuthenticated) {
            AppService.removeRefreshLanguage()
            _kc.init({onLoad: 'login-required'}).then(auth => console.log(auth))
                .catch(e => console.log(e))
        }
    }, [isAuthenticated])


    useEffect(() => {
        dispatch(getUserHandler())
    }, [dispatch]);


    useEffect(() => {
        if (userDetails?.isBlocked) {
            setIsBlocked(true)
        }
    }, [userDetails])


    if (isBlocked) {
        redirect('/account-locked')
    }

    return (

        <React.Fragment>

            {
                <>

                    <Nav>
                        <Suspense fallback={<Loader/>}>
                            <Outlet/>
                        </Suspense>
                    </Nav>

                </>
            }
        </React.Fragment>


    )


}

export default ProtectedRoute;