import api from "./AxiosInstance";

const dispatch = () => {

    return window.localStorage.setItem("notified", "true")
}

export const getAllNotifications = () => {

    return api.get('/api/v1/notifications')
}

export const markAsRead = (id) => {

    return api.post('/api/v1/notifications-mark-read/' + id)
}
export const markAllAsRead = () => {

    return api.post('/api/v1/notifications-mark-allread')
}

const retry = () => {
    return window.localStorage.setItem("notified", 'false')
};

const isDispatched = () => {

    return (window.localStorage.getItem('notified') &&
        window.localStorage.getItem('notified') === "true") ? 1 : 0
}


export default {
    dispatch,
    isDispatched,
    retry
}