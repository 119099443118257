export const PSPS_LOADING = "PSPS_LOADING",
    PSPS_SUCCESS = "PSPS_SUCCESS",
    PSPS_FAILED = "PSPS_FAILED",
    CREATE_PSP_LOADING = "CREATE_PSP_LOADING",
    CREATE_PSP_SUCCESS = "CREATE_PSP_SUCCESS",
    CREATE_PSP_FAILED = "CREATE_PSP_FAILED",
    UPDATE_PSP_LOADING = "UPDATE_PSP_LOADING",
    UPDATE_PSP_SUCCESS = "UPDATE_PSP_SUCCESS",
    UPDATE_PSP_FAILED = "UPDATE_PSP_FAILED",
    SHOW_PSP_LOADING = "SHOW_PSP_LOADING",
    SHOW_PSP_SUCCESS = "SHOW_PSP_SUCCESS",
    SHOW_PSP_FAILED = "SHOW_PSP_FAILED"