import React, {useEffect} from 'react';
import PageTitle from "../../../layouts/PageTitle";
import {useTranslation} from "react-i18next";
import {tLog} from "../../../../Helper/functions";
import Alert from "../../Crud/Alert";
import Loader from "../../Crud/Loader";
import NoResult from "../../Crud/NoResult";
import DataTable from "../../Crud/DataTable";
import {useParams} from "react-router";
import {AuditsHandler} from "../../../../store/actions/AuditAction";
import {useDispatch, useSelector} from "react-redux";


const AuditList = () => {
    const {model, id} = useParams()
    const dispatch = useDispatch()
    const {t, i18n} = useTranslation();

    const {audits: {data, loading, errors}} = useSelector(s => s)

    const headerTitle = t('logs')

    const headerSubTitle = t('list')

    const tableTitleOld = tLog({t, i18n}, 'logs', 'old')
    const tableTitleCurrent = tLog({t, i18n}, 'logs', 'current')

    useEffect(() => {
        dispatch(AuditsHandler(model, id))
    }, [model, id])


    if (!data) return <NoResult/>

    return (
        <>
            <PageTitle activeMenu={headerSubTitle} motherMenu={headerTitle}/>

            {
                loading
                    ? <Loader/>
                    : <div className="">

                        <div className=" col-12">
                            <div className="card col-12">
                                <div className="card-header">
                                    <h4 className="card-title">{tableTitleOld} </h4>
                                </div>
                                <div className="card-body">

                                    <div className="w-100 table-responsive">
                                        <div id="example_wrapper" className="dataTables_wrapper">
                                            <Alert type='error' message={errors}/>
                                            <DataTable data={data?.old} withHide={['profile', 'config']}/>
                                        </div>
                                    </div>

                                </div>
                            </div>


                            <div className="card col-12">
                                <div className="card-header">
                                    <h4 className="card-title">{tableTitleCurrent} </h4>
                                </div>
                                <div className="card-body">

                                    <div className="w-100 table-responsive">
                                        <div id="example_wrapper" className="dataTables_wrapper">
                                            <Alert type='error' message={errors}/>
                                            <DataTable data={data?.current} withHide={['profile', 'config']}/>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}
export default AuditList;























































