import React from "react";
import {useTranslation} from "react-i18next";
import {Dropdown} from "react-bootstrap";

function LanguageSwitcher({rightSelect, setRightSelect}) {
    const {i18n} = useTranslation();
    return (

        <Dropdown className='nav-item dropdown notification_dropdown '>
            <Dropdown.Toggle as='div'
                             className='nav-link text-white  ai-icon i-false c-pointer  my-3 mx-2'>{rightSelect?.toString()?.toUpperCase()}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Item
                    value="en"
                    onClick={() => {
                        localStorage.setItem("lang", "en")
                        setRightSelect("en")
                        i18n.changeLanguage("en").then(r => r).catch(e => console.log("[change Language] " + e.message))
                    }}>English
                </Dropdown.Item>

                <Dropdown.Item
                    value="ar"
                    onClick={() => {
                        localStorage.setItem("lang", "ar")
                        setRightSelect("ar")
                        i18n.changeLanguage("ar").then(r => r).catch(e => console.log("[change Language] " + e.message))
                    }}>عربي
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>

    );
}

export default LanguageSwitcher;