import _kc from './../Util/keycloack'

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback) => {
    _kc.init({
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
        pkceMethod: 'S256'
    })
        .then((authenticated) => {
            if (!authenticated) {
                console.log("user is not authenticated..!");
                _kc.init({onLoad: 'login-required'}).then(auth => console.log(auth))
                    .catch(e => console.log(e))
            }

            onAuthenticatedCallback();
        })
        .catch(e => {
            return window.location = window.location.origin + '/503.html'
        });
};

const doLogin = _kc.login;

const doLogout = _kc.logout;
const getToken = () => _kc.token;

const getParsedToken = () => _kc.tokenParsed
const clearToken = () => _kc.clearToken();

const getRefreshToken = () => _kc.refreshToken;

const isLoggedIn = () => !!_kc.token;

const updateToken = (successCallback) =>
    _kc.updateToken(5)
        .then(successCallback)
        .catch(doLogin);

const getUsername = () => _kc.tokenParsed?.preferred_username;
const getUser = () => _kc.tokenParsed;
const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

const getLoggedInUserRole = () => getParsedToken()?.group[0] ? getParsedToken()?.group[0].toString().replace("/", "") : null

const getLoggedInUserPermissions = () => getParsedToken()?.realm_access?.roles[0] ? getParsedToken()?.realm_access?.roles : null


const SsoService = {
    initKeycloak,
    doLogin,
    doLogout,
    isLoggedIn,
    getToken,
    getRefreshToken,
    updateToken,
    getUsername,
    clearToken,
    hasRole,
    getUser,
    getLoggedInUserRole,
    getLoggedInUserPermissions,
    getParsedToken
};

export default SsoService;
