export const refresh = (data = {
                            data: null,
                            loading: false,
                            errors: null,
                        }
) => {
    let defaultData = {
        loading: false,
        errors: null,
        data: null
    }


    return {...defaultData, ...data}
}








