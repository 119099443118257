import {
    CREATE_LIBRARY_FAILED,
    CREATE_LIBRARY_LOADING,
    CREATE_LIBRARY_SUCCESS, MY_LIBRARY_FAILED, MY_LIBRARY_LOADING,
    MY_LIBRARY_SUCCESS
} from "../Constants/Library";
import {UPDATE_LIBRARY_FAILED, UPDATE_LIBRARY_LOADING, UPDATE_LIBRARY_SUCCESS} from "../Constants/Library";
import {DELETE_LIBRARY_FAILED, DELETE_LIBRARY_LOADING, DELETE_LIBRARY_SUCCESS} from "../Constants/Library";
import {LIST_LIBRARY_FAILED, LIST_LIBRARY_LOADING, LIST_LIBRARY_SUCCESS} from "../Constants/Library";
import {SHOW_LIBRARY_FAILED, SHOW_LIBRARY_LOADING, SHOW_LIBRARY_SUCCESS} from "../Constants/Library";
import {GLOBAL_REFRESH} from "../Constants/Refresh";
import {refresh} from "./index";


export const createLibraryReducer = (state = {}, action) => {
    switch (action.type) {
        case CREATE_LIBRARY_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case CREATE_LIBRARY_LOADING :
            return {
                loading: true,
                errors: null,
                data: null
            }
        case CREATE_LIBRARY_FAILED :
            return {
                loading: false,
                data: null,
                errors: action.payload
            }
        case GLOBAL_REFRESH :
            return refresh()
        default :
            return state

    }
}

export const updateLibraryReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_LIBRARY_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case UPDATE_LIBRARY_LOADING :
            return {
                loading: true,
                errors: null,
                data: null
            }
        case UPDATE_LIBRARY_FAILED :
            return {
                loading: false,
                data: state,
                errors: action.payload
            }
        case GLOBAL_REFRESH :
            return refresh()

        default :
            return state

    }
}


export const showLibraryReducer = (state = {}, action) => {
    switch (action.type) {
        case SHOW_LIBRARY_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case SHOW_LIBRARY_LOADING :
            return {
                loading: true,
                errors: null,
                data: null
            }
        case SHOW_LIBRARY_FAILED :
            return {
                loading: false,
                data: null,
                errors: action.payload
            }

        default :
            return state

    }
}


export const deleteLibraryReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_LIBRARY_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case DELETE_LIBRARY_LOADING :
            return {
                loading: true,
                errors: null,
                data: action.payload
            }
        case DELETE_LIBRARY_FAILED :
            return {
                loading: false,
                data: state,
                errors: action.payload
            }

        default :
            return state

    }
}

export const listLibraryReducer = (state = {}, action) => {
    switch (action.type) {
        case LIST_LIBRARY_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case LIST_LIBRARY_LOADING :
            return {
                loading: true,
                errors: null,
                data: []
            }
        case LIST_LIBRARY_FAILED :
            return {
                loading: false,
                data: [],
                errors: action.payload
            }

        default :
            return state

    }
}

export const myLibraryReducer = (state = {}, action) => {
    switch (action.type) {
        case MY_LIBRARY_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case MY_LIBRARY_LOADING :
            return {
                loading: true,
                errors: null,
                data: []
            }
        case MY_LIBRARY_FAILED :
            return {
                loading: false,
                data: [],
                errors: action.payload
            }

        default :
            return state

    }
}







