import {CREATE_FAQ_FAILED, CREATE_FAQ_LOADING, CREATE_FAQ_SUCCESS} from "../Constants/Faq";
import {UPDATE_FAQ_FAILED, UPDATE_FAQ_LOADING, UPDATE_FAQ_SUCCESS} from "../Constants/Faq";
import {DELETE_FAQ_FAILED, DELETE_FAQ_LOADING, DELETE_FAQ_SUCCESS} from "../Constants/Faq";
import {LIST_FAQ_FAILED, LIST_FAQ_LOADING, LIST_FAQ_SUCCESS} from "../Constants/Faq";
import {SHOW_FAQ_FAILED, SHOW_FAQ_LOADING, SHOW_FAQ_SUCCESS} from "../Constants/Faq";
import {GLOBAL_REFRESH} from "../Constants/Refresh";
import {refresh} from "./index";


export const createFaqReducer = (state = {}, action) => {
    switch (action.type) {
        case CREATE_FAQ_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case CREATE_FAQ_LOADING :
            return {
                loading: true,
                errors: null,
                data: null
            }
        case CREATE_FAQ_FAILED :
            return {
                loading: false,
                data: null,
                errors: action.payload
            }
        case GLOBAL_REFRESH :
            return refresh()
        default :
            return state

    }
}

export const updateFaqReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_FAQ_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case UPDATE_FAQ_LOADING :
            return {
                loading: true,
                errors: null,
                data: action.payload
            }
        case UPDATE_FAQ_FAILED :
            return {
                loading: false,
                data: state,
                errors: action.payload
            }
        case GLOBAL_REFRESH :
            return refresh()

        default :
            return state

    }
}


export const showFaqReducer = (state = {}, action) => {
    switch (action.type) {
        case SHOW_FAQ_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case SHOW_FAQ_LOADING :
            return {
                loading: true,
                errors: null,
                data: null
            }
        case SHOW_FAQ_FAILED :
            return {
                loading: false,
                data: null,
                errors: action.payload
            }

        default :
            return state

    }
}


export const deleteFaqReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_FAQ_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case DELETE_FAQ_LOADING :
            return {
                loading: true,
                errors: null,
                data: action.payload
            }
        case DELETE_FAQ_FAILED :
            return {
                loading: false,
                data: state,
                errors: action.payload
            }

        default :
            return state

    }
}

export const listFaqReducer = (state = {}, action) => {
    switch (action.type) {
        case LIST_FAQ_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case LIST_FAQ_LOADING :
            return {
                loading: true,
                errors: null,
                data: []
            }
        case LIST_FAQ_FAILED :
            return {
                loading: false,
                data: [],
                errors: action.payload
            }

        default :
            return state

    }
}
