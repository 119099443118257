export const
    CREATE_HERO_FAILED = "CREATE_HERO_FAILED",
    CREATE_HERO_LOADING = "CREATE_HERO_LOADING",
    CREATE_HERO_SUCCESS = "CREATE_HERO_SUCCESS",
    UPDATE_HERO_FAILED = "UPDATE_HERO_FAILED",
    UPDATE_HERO_LOADING = "UPDATE_HERO_LOADING",
    UPDATE_HERO_SUCCESS = "UPDATE_HERO_SUCCESS",
    DELETE_HERO_FAILED = "DELETE_HERO_FAILED",
    DELETE_HERO_LOADING = "DELETE_HERO_LOADING",
    DELETE_HERO_SUCCESS = "DELETE_HERO_SUCCESS",
    LIST_HERO_FAILED = "LIST_HERO_FAILED",
    LIST_HERO_LOADING = "LIST_HERO_LOADING",
    LIST_HERO_SUCCESS = "LIST_HERO_SUCCESS",
    SHOW_HERO_LOADING = "SHOW_HERO_LOADING",
    SHOW_HERO_FAILED = "SHOW_HERO_FAILED",
    SHOW_HERO_SUCCESS = "SHOW_HERO_SUCCESS"