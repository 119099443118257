import {
    DELETE_IP_FAILED,
    DELETE_IP_LOADING,
    DELETE_IP_SUCCESS, LIST_IP_FAILED, LIST_IP_LOADING,
    LIST_IP_SUCCESS
} from "../Constants/IP";


export const deleteIpReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_IP_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case DELETE_IP_LOADING :
            return {
                loading: true,
                errors: null,
                data: action.payload
            }
        case DELETE_IP_FAILED :
            return {
                loading: false,
                data: state,
                errors: action.payload
            }

        default :
            return state

    }
}

export const listIpReducer = (state = {}, action) => {
    switch (action.type) {
        case LIST_IP_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case LIST_IP_LOADING :
            return {
                loading: true,
                errors: null,
                data: []
            }
        case LIST_IP_FAILED :
            return {
                loading: false,
                data: [],
                errors: action.payload
            }

        default :
            return state

    }
}
