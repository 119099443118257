import React, {Fragment, useState} from "react";
import Ellipse from "./Ellipse";
import SideBar from "./SideBar";
import NavHeader from "./NavHeader";
import Header from "./Header";
import ChatBox from "../ChatBox";
import {can} from "../../../Role";
import AppNavigator from "./Navigator";

const JobieNav = () => {
    const [toggle, setToggle] = useState("");
    const onClick = (name) => setToggle(toggle === name ? "" : name);
    return (
        <Fragment>
            <Ellipse/>
            <NavHeader/>
            {
                can("chat") ||
                can("notes") ||
                can("alerts") ? <ChatBox onClick={() => onClick("chatbox")} toggle={toggle}/> : null
            }

            <Header
                onNote={() => onClick("chatbox")}
            />
            <SideBar Navigator={AppNavigator}/>
        </Fragment>
    );
};

export default JobieNav;
