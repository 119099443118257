import dayjs from "dayjs";
import isBetween from 'dayjs/plugin/isBetween.js'

dayjs.extend(isBetween)

const DateFilter = (startDate, endDate, isEnd, rows, id, filterValues) => {

    const date = new dayjs(filterValues)

    return rows?.filter(row => (new dayjs(row.values[id[0]])).isBetween(date, (endDate), null, '[]'))
}

export default DateFilter