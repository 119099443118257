import api from './AxiosInstance'

export function getDefaultCountry() {
    return api.get('/api/v1/default-currency')
}

export function getCountries(onlyAvailable = false) {


    if (onlyAvailable) return api.get('/api/v1/countries?available=true')

    return api.get('/api/v1/countries')
}