export const LIST_BLOCK_FAILED = "LIST_BLOCK_FAILED", LIST_BLOCK_LOADING = "LIST_BLOCK_LOADING",
    LIST_BLOCK_SUCCESS = "LIST_BLOCK_SUCCESS",
    CREATE_BLOCK_FAILED = "CREATE_BLOCK_FAILED",
    CREATE_BLOCK_LOADING = "CREATE_BLOCK_LOADING",
    CREATE_BLOCK_SUCCESS = "CREATE_BLOCK_SUCCESS",
    UPDATE_BLOCK_FAILED = "UPDATE_BLOCK_FAILED",
    UPDATE_BLOCK_LOADING = "UPDATE_BLOCK_LOADING",
    UPDATE_BLOCK_SUCCESS = "UPDATE_BLOCK_SUCCESS",
    DELETE_BLOCK_FAILED = "DELETE_BLOCK_FAILED",
    DELETE_BLOCK_LOADING = "DELETE_BLOCK_LOADING",
    DELETE_BLOCK_SUCCESS = "DELETE_BLOCK_SUCCESS",
    EMAIL_BLOCK_FAILED = "EMAIL_BLOCK_FAILED",
    EMAIL_BLOCK_LOADING = "EMAIL_BLOCK_LOADING",
    EMAIL_BLOCK_SUCCESS = "EMAIL_BLOCK_SUCCESS",
    SHOW_BLOCK_LOADING = "SHOW_BLOCK_LOADING",
    SHOW_BLOCK_FAILED = "SHOW_BLOCK_FAILED",
    SHOW_BLOCK_SUCCESS = "SHOW_BLOCK_SUCCESS"