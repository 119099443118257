import {
    MY_TRANSACTION_FAILED,
    MY_TRANSACTION_SUCCESS,
    MY_TRANSACTION_STATISTICS_FAILED,
    MY_TRANSACTION_STATISTICS_LOADING,
    MY_TRANSACTION_STATISTICS_SUCCESS,
    MY_TRANSACTION_LOADING,
    LIST_TRANSACTION_SUCCESS, LIST_TRANSACTION_LOADING, LIST_TRANSACTION_FAILED
} from "../Constants/Transaction";


export const transactionsStatisticsReducer = (state = {
    data: {
        transactions: [],
        totalProfitForCurrentMonth: 0,
        currentMonthOrders: 0,
        total_orders: 0
    }
}, action) => {
    switch (action.type) {
        case MY_TRANSACTION_STATISTICS_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: {...state.data, ...action.payload}
            }
        case MY_TRANSACTION_STATISTICS_LOADING :
            return {
                loading: true,
                errors: null,
                data: state.data
            }
        case MY_TRANSACTION_STATISTICS_FAILED :
            return {
                loading: false,
                data: state.data,
                errors: action.payload
            }

        default :
            return state

    }
}

export const myTransactionsReducer = (state = {}, action) => {
    switch (action.type) {
        case MY_TRANSACTION_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case MY_TRANSACTION_LOADING :
            return {
                loading: true,
                errors: null,
                data: []
            }
        case MY_TRANSACTION_FAILED :
            return {
                loading: false,
                data: [],
                errors: action.payload
            }

        default :
            return state

    }
}


export const transactionsReducer = (state = {}, action) => {
    switch (action.type) {
        case LIST_TRANSACTION_SUCCESS :
            return {
                loading: false,
                errors: null,
                data: action.payload
            }
        case LIST_TRANSACTION_LOADING :
            return {
                loading: true,
                errors: null,
                data: []
            }
        case LIST_TRANSACTION_FAILED :
            return {
                loading: false,
                data: [],
                errors: action.payload
            }

        default :
            return state

    }
}






