import env from '#env'
import {useTranslation} from "react-i18next";
import React from "react";
import 'react-lazy-load-image-component/src/effects/blur.css';

import {LazyLoadImage} from 'react-lazy-load-image-component';

const ImageS3 = ({image, standalone = false}) => {

    const {t} = useTranslation()

    if (standalone === true) {
        return <LazyLoadImage style={
            {
                objectFit: "cover"
            }
        } src={env.s3 + image} effect="blur" height={50} width={50}/>
    }
    return <div className="row col-12 inputDir">
        <div className="col-2"> {t("old_image")} :</div>
        <div className="col-2 mb-3 pl-0 ml-0 justify-content-start">
            <LazyLoadImage style={
                {
                    objectFit: "cover"
                }
            } className="col-2 mb-3 pl-0 ml-0 justify-content-start" src={env.s3 + image} effect="blur"/>
        </div>

    </div>
}
export default React.memo(ImageS3)


export const ImageS3Custom = ({image, height, width, standalone = false}) => {

    const {t} = useTranslation()

    if (standalone === true) {
        return <LazyLoadImage style={
            {
                objectFit: "cover"
            }
        } src={env.s3 + image} effect="blur" height={height} width={width}/>
    }
    return <div className="row col-12">
        <div className="col-2"> {t("old_image")} :</div>
        <div className="col-2 mb-3 pl-0 ml-0 justify-content-start">
            <LazyLoadImage style={
                {
                    objectFit: "cover"
                }
            } className="col-2 mb-3 pl-0 ml-0 justify-content-start" src={env.s3 + image} effect="blur"/>
        </div>

    </div>
}

export const ImageS350 = ({image, standalone = false}) => {

    const {t} = useTranslation()
    let obj = {image: null}

    Object.defineProperty(obj, 'image', {
        writable: true,
        value: image?.replace(/\/(?=[^\/]*$)/, '/50*50/')
    });


    if (standalone === true) {
        return <LazyLoadImage style={
            {
                objectFit: "cover"
            }
        } src={env.s3 + obj.image} effect="blur"/>
    }
    return <div className="row inputDir">
        <div className="col-2"> {t("old_image")} :</div>
        <div className={"col-10 mb-3"}>
            <LazyLoadImage className=" mb-3" width={50} height={50} effect="blur" src={env.s3 + obj.image}
            />
        </div>


    </div>
}

const S320 = ({image, standalone = false}) => {

    const {t} = useTranslation()

    if (standalone === true) {
        return <LazyLoadImage style={
            {
                objectFit: "cover"
            }
        } src={env.s3 + image?.replace(/\/(?=[^\/]*$)/, '/20*20/')} effect="blur"/>
    }
    return <div className="row col-12">
        <div className="col-2"> {t("old_image")} :</div>
        <div className="col-2 mb-3 pl-0 ml-0">
            <LazyLoadImage width={20} height={20} src={env.s3 + image?.replace(/\/(?=[^\/]*$)/, '/20*20/')}
                           effect="blur"/>
        </div>


    </div>
}

export const ImageS320 = React.memo(S320)