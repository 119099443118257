import React, {useMemo, useState} from "react";
import {
    useAsyncDebounce,
    useGlobalFilter,
    usePagination,
    useSortBy,
    useTable,
    useFilters
} from "react-table";
import {ImageS350} from "./ImageS3";
import Badge from "./Badge";
import {Link} from "react-router-dom";
import dayjs from "dayjs";
import {useTranslation} from "react-i18next";
import {Document, Page, pdfjs} from 'react-pdf';
import env from "#env"
import {TableNoResult} from "./NoResult";
import {getPathLocation, getStringBetween} from "../../../Helper/functions";
import {mapStateToStyle, stateToTranslatedString} from "./State";
import {isAdmin} from "../../../Role";
import {Button, Dropdown, DropdownButton} from "react-bootstrap";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import {saveAs} from "file-saver";
import $ from 'jquery'
import DateFilter from "../DataTable/Filters/DateFilter";
import RoleFilter from "../DataTable/Filters/RoleFilter";
import UserFilter from "../DataTable/Filters/UserFilter";
import GeneralFilter from "../DataTable/Filters/GeneralFilter";
import SelectCreator from "./SelectCreator";
import DropdownFilter from "../DataTable/Filters/DropdownFilter";
import AppService from "../../../services/AppService";
import ArFont from './../../../Fonts/Cairo-VariableFont_slnt,wght.ttf'
import Bus from "../../../Util/Bus";
import keys from "../../../const/keys";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


export function GlobalFilter({
                                 preGlobalFilteredRows,
                                 globalFilter,
                                 setGlobalFilter,
                                 setHiddenColumns,
                                 hiddenColumns,
                                 columns,
                                 data,
                                 setAllFilters

                             }) {

    const count = preGlobalFilteredRows.length;

    const [value, setValue] = useState(globalFilter);

    const {t} = useTranslation()

    const onChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
    }, 300);

    if (count <= 0) {
        return <TableNoResult/>
    }


    const rebalancedHiddenColumns = (column) => {
        if (hiddenColumns.includes(column)) {
            setHiddenColumns(hiddenColumns.filter(c => c !== column))
        } else {
            setHiddenColumns([...hiddenColumns, column])
        }
    }

    const exportToExcel = () => {
        let fileName = $('.card-title').html()
        if (!fileName) {
            fileName = $('.dashboard_bar').html()
        }
        const workbook = XLSX.utils.book_new();
        const sheet = XLSX.utils.json_to_sheet(data);
        XLSX.utils.book_append_sheet(workbook, sheet, "Sheet1");
        const excelBuffer = XLSX.write(workbook, {bookType: "xlsx", type: "array"});
        const dataBlob = new Blob([excelBuffer], {type: "application/vnd.ms-excel"});
        saveAs(dataBlob, fileName + ".xlsx");
    };

    const exportToPDF = () => {

        const doc = new JsPDF('landscape', 'pt');
        //
        if (AppService.getLocalLanguage() === 'ar') {
            doc.addFont(ArFont, 'symbol', 'normal', '12', 'StandardEncoding')
            doc.setFont(ArFont, 'normal')
            // doc.addFileToVFS('Amiri-Regular.ttf', amiriRegularFontBase64); // Provide the base64-encoded font file
            // doc.addFont('Amiri-Regular.ttf', 'Amiri', 'normal');
            // doc.setFont('Amiri');
        }


        let fileName = $('.card-title').html()

        if (!fileName) {
            fileName = $('.dashboard_bar').html()
        }
        const content = $("table").html()

        doc.html("<table>" + content + "</table>", {
            margin: 25,
            callback: () => doc.save((
                fileName?.toString() || "table"
            ) + ".pdf")
        });

    };

    const clearFilters = () => {
        setGlobalFilter('');
        setAllFilters([])
    };


    const refreshData = () => {
        Bus.dispatch(keys.REFRESH_API, true)
    }

    return (
        <>
            <input
                value={value || ""}
                onChange={(e) => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder={(t("search_records")).toString().replace("%n", count)}
                className={"form-control rounded w-30"}
            />

            <div className=" d-flex justify-content-start mr-3 mt-3  mb-1 ">
                {!isAdmin() ? null : (<div className={"mx-2"}>

                    <DropdownButton id="dropdownMenuButton"
                                    title={t('columns')}
                                    rootCloseEvent={"click"}
                                    autoClose={false}
                    >

                        <div style={{
                            maxHeight: "400px",
                            minWidth: "350px",
                            overflowY: "auto"
                        }}>
                            {columns.map((c, i) => <Dropdown.Item
                                onClick={() => rebalancedHiddenColumns(c.accessor)}
                                key={c.accessor + "_" + i}>

                                {
                                    hiddenColumns.includes(c.accessor) ?
                                        <div className={"fa fa-close text-primary mx-3"}></div>
                                        : <i className={"fa fa-check text-primary mx-3"}> </i>
                                }

                                &nbsp;{t(c.accessor)}

                            </Dropdown.Item>)}
                        </div>
                    </DropdownButton>
                </div>)}
                <div className={""}>
                    <Button variant={"warning"} id="export_to_excel" title={t('export_to_excel')}
                            onClick={exportToExcel} inputMode={"text"}>
                        <div className="text-sm">  {t('export_to_excel')}</div>
                    </Button>

                </div>


                <div className={"mx-2"}>
                    <Button variant={"secondary"} id="export_to_pdf" title={t('export_to_pdf')}
                            onClick={exportToPDF} inputMode={"text"}>
                        <div className="text-sm">  {t('export_to_pdf')}</div>
                    </Button>
                </div>

                <div className={"mx-2"}>
                    <Button variant={"info"} id="clear_filter" title={t('clear_filter')}
                            onClick={clearFilters} inputMode={"text"}>
                        <div className="text-sm">  {t('clear_filter')}</div>
                    </Button>
                </div>

                {/*<div className={"mx-2"}>*/}
                {/*    <Button variant={"success"} id="refresh" title={t('refresh')}*/}
                {/*            onClick={refreshData} inputMode={"text"}>*/}
                {/*        <div className="text-sm">  {t('refresh')}</div>*/}
                {/*    </Button>*/}
                {/*</div>*/}
            </div>
        </>
    );
}


const images = [
    'image',
    'main_img',
    'sub_img',
    'bg_image',
];


const DataTable = ({
                       data,
                       links,
                       setItem,
                       setShowModal,
                       setInquiry,
                       btn = "danger",
                       icon = "trash",
                       actionList = undefined,
                       actionHandler,
                       showAction = true,
                       customState,
                       withHide = [],
                       extraAction = undefined
                   }) => {


    const {t} = useTranslation()

    const handleKeyContent = (key, index, data) => {

        if (key === 'user' || key === 'libraryOwner') {
            return {
                Header: (key),
                accessor: key,
                Cell: ({value}) => value ? value?.first_name + " " + value?.last_name : t("N/A"),
                filter: UserFilter
            };
        }


        if (key === 'order') {
            return {
                Header: (key),
                accessor: key,
                Cell: ({value}) => value?._id ? value?._id : value ? value : t("N/A"),
            };
        }


        if (key === 'orderId') {
            return {
                Header: (key),
                accessor: key,
                Cell: ({value}) => value !== null ? value : t("N/A")
            };
        }

        if (key === 'type') {
            return {
                Header: (key),
                accessor: key,
                Cell: ({value}) => value !== null ? t(value) : t("N/A")
            };
        }


        if (key === 'role') {
            return {
                Header: (key),
                accessor: key,
                Cell: ({value}) => value?.name || t("N/A"),
                filter: RoleFilter
            };
        }


        if (key === 'course') {

            return {
                Header: (key),
                accessor: key,
                Cell: ({value}) => {

                    const ignoreFromPages = ['transactions-list'];


                    const displayLibraryNameWithCourse = value?.library && !ignoreFromPages.includes(getPathLocation())
                        ? value.title + " - " + value?.library?.name
                        : value.title

                    return data.includes('libraryOwner') || data.includes('qty') ? displayLibraryNameWithCourse : JSON.stringify(value)
                }
            };
        }

        if (key === 'pdfDetails') {
            return {
                Header: (key),
                accessor: key,
                Cell: ({value}) => JSON.stringify(value)
            };
        }


        if (key === 'blog') {
            return {
                Header: (key),
                accessor: key,
                Cell: ({value}) => value?.title || t("N/A"),
                filter: GeneralFilter.bind(null, 'title')

            };
        }


        if (key === 'method') {
            return {
                Header: (key),
                accessor: key,
                Cell: ({value}) =>
                    value === 'updated' ? <span className={"text-warning"}> {t(value)}</span>
                        : value === 'deleted' ? <span className={"text-danger"}> {t(value)}</span>
                            : value === 'created' ? <span className={"text-success"}> {t(value)}</span>
                                : value ? t(value) : t("N/A"),
            };
        }


        if (key === 'totalFunds') {
            return {
                Header: (key),
                accessor: key,
                Cell: ({value}) => parseFloat(value)?.toFixed(2) || t("N/A"),
            };
        }

        if (key === 'color') {
            return {
                type: "DISABLED",
                Header: (key),
                accessor: key,
                Cell: ({value}) => <div
                    className="col-2 btn rounded-circle border border-light"
                    style={{
                        background: value?.hash,
                        objectFit: 'cover',
                        height: 50,
                        width: 50
                    }}/>,
            };
        }


        if (key === 'level') {
            return {
                Header: (key),
                accessor: key,
                Cell: ({value}) => value === 'high' ? <span className={"text-danger"}>{t(value)}</span> :
                    value === 'medium' ? <span className={"text-warning"}>{t(value)}</span>
                        : value === 'low' ? <span className={"text-success"}>{t(value)}</span>
                            : value ? t(value) : t("N/A"),
            };
        }

        if (key === 'payer_phone' || key === 'payer_name' || key === 'payer_email') {
            return {
                Header: (key),
                accessor: key,
                Cell: ({value}) => value ? t(value) : t("N/A"),
            };
        }

        if (key === 'invoice_url') {
            return {
                Header: (key),
                accessor: key,
                Cell: ({value}) => value ?
                    <a href={value} target={"_blank"}
                       className={"text-info"}> {t(key).toString().toUpperCase()} </a> : t("N/A"),
            };
        }

        if (key === 'submenu') {
            return {
                Header: (key),
                accessor: key,
                Cell: ({value}) => <ul>
                    {
                        value.map(
                            (m, i) => <li
                                key={i + m.link + m.title}>
                                <div className={"badge badge-info m-1"}>{m.title}</div>
                                {" :  " + m.link}
                            </li>)
                    }
                </ul>
            };
        }


        if (key === 'content') {
            return {
                Header: (key),
                accessor: key,
                Cell: ({value}) => value
            };
        }

        if (key === 'from' || key === 'to') {
            return {
                Header: (key),
                accessor: key,
                Cell: ({value}) => {
                    return t(stateToTranslatedString[value])
                }
            }
        }

        if (key === 'description') {
            return {
                Header: (key),
                accessor: key,
                Cell: ({value}) => {
                    if (Array.isArray(data) && data.includes('dto')) {

                        const string = getStringBetween(value, '%from', '%to')

                        return <div
                            dangerouslySetInnerHTML={{
                                __html: value.replaceAll(string, t(string))
                                    .replaceAll("%from", t("from") + " ")
                                    .replaceAll("%to", " " + t("to") + " ")
                                    .substring(0, 150)
                            }}></div>
                    }

                    return value
                },
            };
        }

        if (key === 'isApplied') {
            return {
                Header: (key),
                accessor: key,
                Cell: ({value}) => parseInt(value) ? <div className={"text-success"}>{t("approve")}</div> :
                    <div className={"text-danger"}>{t("reject")}</div>,
            };
        }

        if (key === 'hash') {
            return {

                type: getPathLocation() === 'colors-list' ? "DISABLED" : 'text',
                Header: (key),
                accessor: key,
                Cell: function (props) {
                    const {value} = props

                    if (value?.startsWith("#")) {
                        return <div
                            className="col-2 btn rounded-circle border border-light"
                            style={{
                                background: value,
                                objectFit: 'cover',
                                height: 50,
                                width: 50
                            }}/>
                    } else {

                        return value ? t(value) : t("N/A")
                    }
                },
            }
        }

        if (key === 'attachment') {
            return {
                type: "DISABLED",
                Header: (key),
                accessor: key,
                Cell: ({value}) => <Document
                    file={env.s3 + value}
                    renderMode={"svg"}
                >
                    <Page pageNumber={1}
                          renderMode={"svg"}
                          width={50}
                    />
                </Document>,
            };
        }

        if (key === 'category') {

            return {
                Header: (key),
                accessor: key,
                Cell: ({value}) => value?.name || t("N/A"),
                filter: DropdownFilter.bind(null, '_id'),
                minWidth: 200,
                zIndex: 100,
                type: 'dropdown',
                keyName: 'name',
            };
        }

        if (key === 'library') {
            return {
                Header: (key),
                accessor: key,
                minWidth: 200,
                Cell: ({value}) => value?.name || t("N/A"),
                filter: DropdownFilter.bind(null, '_id'),
                type: 'dropdown',
                keyName: 'name',
            };
        }

        if (key === 'model_name' || key === 'model') {
            return {
                Header: (key),
                accessor: key,
                Cell: ({value}) => t(value) || t("N/A"),
            };
        }

        if (key === 'reason') {
            return {
                Header: (key),
                accessor: key,
                Cell: ({value}) => t(value) || t("N/A"),
            };
        }

        if (images.includes(key)) {
            return {
                type: "DISABLED",
                Header: (key),
                accessor: key,
                Cell: ({value}) => <div className={"d-flex justify-content-center"}>
                    <ImageS350 image={value} standalone={true}/>
                </div>,
            };
        }

        if (key === '_id') {
            return {
                Header: "#",
                accessor: key,
            };
        }

        if (key === 'state' || key === 'status') {
            return {
                Header: (key),
                accessor: key,
                Cell: ({value}) => customState ?

                    <span
                        className={`badge light badge-` + mapStateToStyle[value]}>{t(customState[value]).toString().toUpperCase()}</span>
                    : <Badge state={value}/>,
                type: 'dropdown',
                keyName: key,
                minWidth: 200,
                filter: DropdownFilter.bind(null, false)

            };
        }

        if (key === "createdAt" || key === "updatedAt" || key === 'paidAt' || key === "start_date" || key === "end_date") {


            let obj = {
                type: 'date',
                startDate: new dayjs().year(new dayjs().year() - 1),
                endDate: new dayjs().year(new dayjs().year() + 1),
                Header: (key),
                accessor: key,

            }
            obj.isEnd = false
            obj.Cell = function ({value}) {
                if (key === "end_date") {
                    obj.endDate = new dayjs(value);
                } else {
                    obj.startDate = new dayjs(value);
                }
                return value !== null ? dayjs(value).format("YYYY-MM-DD HH:mm:ss") : t("N/A")
            }

            if (key === "end_date") {
                obj.isEnd = true
            }
            const fn = DateFilter
            obj.filter = fn.bind(null, obj.startDate, obj.endDate, obj.isEnd)
            return obj;
        }

        if (key === "total_price") {
            return {
                Header: (key),
                accessor: key,
                Cell: ({value}) => parseFloat(value).toFixed(2)
            };
        }

        if (key === "isPaid" || key === 'isBlocked' || key === 'access' || key === 'isDefault' || key === 'isPortal') {
            return {
                Header: (key),
                accessor: key,
                minWidth: 200,
                type: 'dropdown',
                keyName: key,
                filter: DropdownFilter.bind(null, false),
                Cell: ({value}) => value === true ? <span className={"text-success fa fa-check"}></span> :
                    <span className={"fa fa-close text-danger "}></span>
            };
        }

        if (key === "requestHeaders" || key === 'requestBody') {
            return {
                Header: (key),
                accessor: key,
                width: 30,
                maxWidth: 30,
                minWidth: 30,
                customStyle: {
                    whiteSpace: "pre",
                    overflowX: "auto",
                    height: 50
                }
            };
        }

        if (key === "action") {
            return {
                type: "DISABLED",
                Header: ("Action"),
                accessor: "Action",
                Cell: (value) => t(value.row.original?.action?.toLowerCase())
            };
        }
// TODO ADD/PRINT CURRENCY SYMBOL BEFORE TOTAL PRICE VALUE
        if (key === 'totalPrice') {

            return {
                Header: (key),
                accessor: key,
                Cell: (value) => <span className={"text-" + mapStateToStyle[value.row.original?.state]}>
                {[5, 6, "5", "6"].includes(value.row.original?.state) ? "-" : value.row.original?.state === "1" ? "+" : null}
                    {value.row.original?.totalPrice}
            </span>
            }
        }

        if (key === "__v" && showAction) {
            return {
                type: "DISABLED",
                maxWidth: 400,
                minWidth: 200,
                width: 200,
                originalName: '__v',
                Header: "action", accessor: ('action'),
                Cell: (value) => {
                    if (!actionList) {

                        if (extraAction) {
                            return (

                                <>
                                    <div className="d-flex">

                                        {links && links(value.row.original?._id || value.row.original?.key)}
                                        <Link
                                            className={"btn btn-" + btn + " shadow btn-xs sharp"}
                                            onClick={() => {
                                                setItem(value.row.original)
                                                setShowModal(true)
                                            }}
                                            to="#">
                                            <i className={"fa fa-" + icon}></i>
                                        </Link>

                                        <Link
                                            title={extraAction?.title}
                                            className={"mx-2 btn btn-" + extraAction.btn + " shadow btn-xs sharp"}
                                            onClick={() => {
                                                extraAction?.callback(value.row.original)
                                            }}
                                            to="#">
                                            <i className={"fa fa-" + extraAction.icon}></i>
                                        </Link>

                                    </div>

                                </>

                            )
                        }
                        return <div className="d-flex">

                            {links && links(value.row.original?._id || value.row.original?.key)}
                            <Link
                                className={"btn btn-" + btn + " shadow btn-xs sharp"}
                                onClick={() => {
                                    setItem(value.row.original)
                                    setShowModal(true)
                                }}
                                to="#">
                                <i className={"fa fa-" + icon}></i>
                            </Link>
                        </div>


                    } else {
                        return Array.isArray(actionList) && (
                            <div className="row ">
                                {
                                    actionList.map((action, i) => {

                                        const renderAction = (key) => (
                                            <div key={"action_type_" + key + i} className="d-flex col-2">
                                                <Link
                                                    title={t(action.type)}
                                                    className={"btn btn-" + action.button + " shadow btn-xs sharp"}
                                                    onClick={() => {
                                                        if (action?.isInquiry) return setInquiry(
                                                            action?.keyName ? value.row.original[action.keyName]
                                                                : value.row.original
                                                        )
                                                        actionHandler(value.row.original, action.type)
                                                    }}
                                                    to={action?.to ? action.to + `/${value.row.original[action.keyName]}` : "#"}>
                                                    <i className={"fa fa-" + action.icon}></i>
                                                </Link>

                                            </div>
                                        )
                                        return (action?.isAdminOnly === true && isAdmin() === action.isAdminOnly) ?
                                            renderAction(action?.keyName) : action.isAdminOnly === false
                                                ? renderAction(action?.keyName)
                                                : typeof action?.isAdminOnly === 'undefined' ? renderAction() : null

                                    })
                                }
                            </div>
                        )
                    }

                }

            };
        }

        return {Header: (key), accessor: key};
    }


    const [hiddenColumns, setHiddenColumns] = useState(
        (showAction ? ['model_id', 'dto'] : ['model_id', 'dto', '__v'])
            .concat(withHide))

    const dataColumns = useMemo(
        () => {

            if ((data && !data[0]) || data === undefined) return []

            const details = Object.keys(data[0])

            const __v = details.findIndex(d => d === '__v')

            if (__v > 0) details.push(details.splice(__v, 1) [0])

            return details.map((d, i) => handleKeyContent(d, i, details))
        },
        [data]
    );

    const initialState = {pageIndex: 0, pageSize: 15, hiddenColumns: hiddenColumns};

    let renderData = {
        columns: dataColumns,
        data: data,
        initialState,
    }
    const setHiddenColumnsHandler = (columns) => setHiddenColumns(columns)


    const tableInstance = useTable(
        renderData,
        useGlobalFilter,
        useFilters,
        useSortBy,
        usePagination,
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        preGlobalFilteredRows,
        setGlobalFilter,
        setFilter,
        state,
        state: {pageIndex, pageSize},
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        setAllFilters
    } = tableInstance;

    const LoadNextPages = (pageCount, current) => {
        let NextPages = []

        for (let i = current; i <= current + 4; i++) {
            if (pageCount - 1 >= i) {
                NextPages.push(i)

            }
        }

        return NextPages
    }

    function mapCells(cells) {

        return cells.map((cell) => {

            if (!hiddenColumns?.includes(cell?.column?.id)) {
                let hasCustomStyle = cell.column?.customStyle ? cell.column?.customStyle : {}

                return <td {...cell.getCellProps({
                    style: {
                        minWidth: cell.column.minWidth,
                        width: cell.column.width,
                        maxWidth: cell.column.maxWidth,
                        ...hasCustomStyle
                    },
                })}>
                    {cell.render("Cell")}
                </td>
            }
        })
    }

    function isCustomLabelStyle(value, column) {

        if (column.id === "isPaid" ||
            column.id === 'isBlocked' ||
            column.id === 'access' ||
            column.id === 'isDefault' ||
            column.id === 'isPortal') {
            return value === true ? <span className={"text-success fa fa-check"}></span> :
                <span className={"fa fa-close text-danger "}></span>;
        }

        if (column.id === 'state') {
            return customState ?
                <span
                    className={`badge light badge-` + mapStateToStyle[value]}>{t(customState[value])}</span>
                : <Badge state={value}/>
        }

        return false
    }

    function renderFilterSearchInput(column) {

        if (column?.type && column.type === 'dropdown') {
            const pushedValue = {}
            const values = [
                {
                    label: t("select") + " " + t(column.id),
                    value: ""
                }
            ];

            data.forEach(d => {

                const label = isCustomLabelStyle(d[column.id], column)

                if (label) {
                    if (typeof d[column?.id] !== 'undefined' && !pushedValue[d[column?.id]?.toString()]) {

                        pushedValue[d[column?.id]?.toString()] = true
                        values.push({
                            label: label,
                            value: d[column?.id]?.toString(),
                        })
                    }
                } else if (
                    typeof d[column?.id] !== 'undefined' &&
                    d[column?.id].hasOwnProperty('_id') &&
                    d[column?.id].hasOwnProperty(column?.keyName) &&
                    !pushedValue[d[column.id]['_id']]
                ) {
                    pushedValue[d[column.id]['_id']] = true
                    values.push({
                        label: d[column.id][column.keyName],
                        value: d[column.id]['_id'],
                    })
                }


            })


            return <SelectCreator
                options={values}
                setSelectedOption={(selectedOption) => setFilter(column.id, selectedOption?.value)}
                value={column?.filterValue || ''}
                name={column.id}
                id={column.id}
                style={{minWidth: 200}}
            />
        } else {
            return <div>
                <input
                    style={column?.type && column.type === "DISABLED" ? {visibility: 'hidden'} : null}
                    type={column?.type || 'text'}
                    value={column?.filterValue || ''}
                    onChange={(e) => setFilter(column.id, e.target.value)}
                    placeholder={`${t(column.Header)}`}
                />
            </div>
        }

    }

    function pagination() {
        return <ul className="pagination ">
            <li className="page-item" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                <a className="page-link" style={{maxHeight: 39, minHeight: 39, zIndex: 0}}>{t("first")}</a>
            </li>
            <li className="page-item" onClick={() => previousPage()} disabled={!canPreviousPage}>
                <a className="page-link" style={{maxHeight: 39, minHeight: 39, zIndex: 0}}>{'<'}</a>
            </li>
            {LoadNextPages(pageCount, pageIndex)?.map(nextPage => (
                <li key={"page_" + nextPage} className="page-item" onClick={() => gotoPage(nextPage)}
                    disabled={!canPreviousPage}>
                    <a className="page-link" style={{maxHeight: 39, minHeight: 39, zIndex: 0}}>{nextPage + 1}</a>
                </li>
            ))}

            <li className="page-item" onClick={() => nextPage()} disabled={!canNextPage}>
                <a className="page-link" style={{maxHeight: 39, minHeight: 39, zIndex: 0}}>{'>'}</a>
            </li>
            <li className="page-item" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                <a className="page-link" style={{maxHeight: 39, minHeight: 39, zIndex: 0}}>{t("last")}</a>
            </li>
            <li className="page-item">
                <a className="page-link" style={{maxHeight: 39, minHeight: 39, fontSize: 13, zIndex: 0}}>
                    {t("page")}{' '}{pageIndex + 1}
                    {t("of")}{' '}{pageOptions.length}
                </a>
            </li>
            <li className="page-item">
                <a className="page-link" style={{maxHeight: 39, minHeight: 39, zIndex: 0}}>
                    <input
                        className="form-control"
                        type="number"
                        defaultValue={pageIndex + 1}
                        onChange={e => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                            gotoPage(page)
                        }}
                        style={{width: '100px', height: '24px'}}
                    />
                </a>
            </li>
            {' '}
            <li className="page-item">
                <a className="page-link " style={{maxHeight: 39, minHeight: 39, zIndex: 0}}>
                    <select
                        value={pageSize}
                        onChange={e => {
                            setPageSize(Number(e.target.value))
                        }}
                        style={{width: '150px', height: '24px', padding: 0}}
                    >

                        {[5, 10, 15, 20, 30, 40, 50].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                {t("show")} {pageSize}
                            </option>
                        ))}
                    </select>
                </a>
            </li>
        </ul>
    }

    function mapHeaders(headers, renderFilters = false) {

        return headers.map((column, i) => {
            column.Header = t(column.id)


            if (!hiddenColumns?.includes(column?.id)) {


                if (renderFilters) {

                    return <th key={"filterRow_" + i}>
                        <div className={"mt-3"}>
                            {renderFilterSearchInput(column)}
                        </div>
                    </th>

                } else {

                    return <th{...column.getHeaderProps(column.getSortByToggleProps())}>
                        <div>
                            {column.render("Header")}
                            {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                        </div>
                    </th>
                }

            }
        })
    }


    return (<>
            <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                setGlobalFilter={setGlobalFilter}
                globalFilter={state.globalFilter}
                columns={dataColumns}
                setHiddenColumns={setHiddenColumnsHandler}
                hiddenColumns={hiddenColumns}
                data={data}
                setFilter={setFilter}
                setAllFilters={setAllFilters}
            />


            <table {...getTableProps()} className="display w-100 dataTable">
                <thead>
                {headerGroups.map((headerGroup, i) => (
                    <tr key={"normalHeader_" + i}  {...headerGroup.getHeaderGroupProps()}>
                        {mapHeaders(headerGroup.headers)}
                    </tr>

                ))}

                {headerGroups.map((headerGroup, i) => (
                    <tr key={"filterSearch_" + i} {...headerGroup.getHeaderGroupProps()}>
                        {mapHeaders(headerGroup.headers, true)}
                    </tr>

                ))}

                </thead>
                <tbody  {...getTableBodyProps()}>

                {page.map((row) => {
                    prepareRow(row);


                    return (
                        <tr
                            {...row.getRowProps()}
                            className={""}
                        >
                            {mapCells(row.cells)}


                        </tr>
                    );
                })}
                </tbody>
            </table>
            <br/>
            {pagination()}
        </>
    );
}


export default DataTable