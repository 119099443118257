import axios from 'axios';
import env from "#env";
import {TokenService} from "./TokenService";
import i18next from 'i18next'

const api = axios.create({
    baseURL: env.BACKEND_URL,
    timeout: 29000,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'lang': localStorage.getItem("lang") ||  i18next.language
    },
    withCredentials: true
});

let user = TokenService.getUser()

if (user) {
    const {accessToken, refreshToken} = user

    api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    api.defaults.headers.common['x-refresh-token'] = refreshToken
    api.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
    api.defaults.headers['x-refresh-token'] = refreshToken

}

export default api;

